import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  deletePaymentCondition,
  getPaymentConditions,
  makeDefaultPaymentCondition,
} from 'services/PaymentCondition/PaymentConditionService';
import { IPaymentCondition } from 'types/PaymentCondition.types';

export const useGetPaymentConditions = (
  page?: number,
  perPage?: number,
  searchBy?: string
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_PAYMENT_CONDITIONS, page, perPage, searchBy],
    queryFn: () => {
      return getPaymentConditions(page, perPage, searchBy);
    },
  });

export const useDeletePaymentCondition = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deletePaymentCondition(id), {
    onSuccess: () => {
      toast.success(t('Payment condition deleted'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_PAYMENT_CONDITION,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GET_PAYMENT_CONDITIONS]);
      queryClient.resetQueries([ReactQueryKeys.GET_DEFAULT_PAYMENT_CONDITION]);
      queryClient.invalidateQueries([ReactQueryKeys.GET_PAYMENT_CONDITION]);
    },
    mutationKey: ReactMutationKeys.DELETE_PAYMENT_CONDITION,
  });
};

export const useMakeDefaultPaymentCondition = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => makeDefaultPaymentCondition(id), {
    onSuccess: ({
      payment_condition,
    }: {
      payment_condition: IPaymentCondition;
    }) => {
      if (payment_condition.is_default) {
        toast.success(t('Payment condition set as default'));
      } else {
        toast.success(t('Payment condition removed as default'));
      }
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        className: ReactMutationKeys.MAKE_DEFAULT_PAYMENT_CONDITION,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GET_PAYMENT_CONDITIONS]);
      queryClient.resetQueries([ReactQueryKeys.GET_DEFAULT_PAYMENT_CONDITION]);
      queryClient.invalidateQueries([ReactQueryKeys.GET_PAYMENT_CONDITION]);
    },
    mutationKey: ReactMutationKeys.MAKE_DEFAULT_PAYMENT_CONDITION,
  });
};
