import { PriceSpan } from '../BlueprintPlanCard/BlueprintPlanCard.styled';
import {
  Container,
  CounterSpan,
  CounterWrapper,
  InputContainer,
  PriceWrapper,
  Title,
  Wrapper,
} from './UserCounter.styled';
import { Plus, Minus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Row } from '../../SignUpPage.styled';
import Input from 'components/Input/Input';
import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX } from '../../constants';

interface IUserCounter {
  title: string;
  price: string;
  emailPlaceholder: string;
  inputs: string[];
  selectedLicenseType: number;
  setInputs: Dispatch<SetStateAction<string[]>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}
export const UserCounter = ({
  title,
  price,
  emailPlaceholder,
  inputs,
  setInputs,
  setIsValid,
  selectedLicenseType,
}: IUserCounter) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState<number>(0);
  const [errors, setErrors] = useState<boolean[]>([]);

  useEffect(() => {
    setQuantity(0);
    setInputs([]);
  }, [selectedLicenseType]);

  const handleAdd = () => {
    setQuantity(quantity + 1);
    setInputs([...inputs, '']);
    setErrors([...errors, false]);
  };

  const handleRemove = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      setInputs(inputs.slice(0, -1));
      setErrors(errors.slice(0, -1));
    }
  };

  const handleInputChange = (index: number, value: string) => {
    const newInputs = (prevInputs: string[]) =>
      prevInputs.map((input, i) => (i === index ? value : input));
    setInputs(newInputs);

    validate(index, value);
  };

  const validate = (index: number, value: string) => {
    const isValidEmail = EMAIL_REGEX.test(value) || value === '';
    const newErrors = errors.map((error, i) =>
      i === index ? !isValidEmail : error
    );
    setErrors(newErrors);
    setIsValid(!newErrors[index]);
  };

  return (
    <Row>
      <Wrapper>
        <Container>
          <Title>{title}</Title>
          <PriceWrapper>
            {t('extra')} €<PriceSpan>{price}/</PriceSpan>
            {t('month')}
          </PriceWrapper>
          <CounterWrapper>
            <Icon
              disabled={quantity == 0}
              svg={Minus}
              size={20}
              weight="regular"
              color={COLORS.GREY_1000}
              onClick={handleRemove}
            />
            <CounterSpan>{quantity}</CounterSpan>
            <Icon
              svg={Plus}
              size={20}
              weight="regular"
              color={COLORS.PRIMARY}
              onClick={handleAdd}
            />
          </CounterWrapper>
        </Container>
      </Wrapper>

      <InputContainer>
        {inputs.map((input, index) => (
          <Input
            key={index}
            placeholder={emailPlaceholder}
            height="41rem"
            onChange={(e) => handleInputChange(index, e.target.value)}
            errorMessage={errors[index] ? 'Invalid e-mail' : ''}
            error={errors[index]}
          />
        ))}
      </InputContainer>
    </Row>
  );
};
