import {
  ADD_PLANNING_TAB,
  FiltersPageEnum,
  INITIALIZE_PAGE_FILTERS,
  REMOVE_PLANNING_TAB,
  SAVE_PAGE_FILTERS_ON_DISMOUNT,
  SELECT_PLANNING_TAB,
  UPDATE_PLANNING_TAB,
  UPDATE_TAB_NAME,
  WAS_PLANNING_PAGE_JUST_UPDATED,
} from '../constants';
import {
  IPageFiltersData,
  IPlanningTabData,
  IPlanningTabFilter,
} from '../types';

export const initializePageFilters = (
  initializePageFiltersData: IPageFiltersData
) => ({
  type: INITIALIZE_PAGE_FILTERS,
  payload: initializePageFiltersData,
});

export const savePageFiltersOnPageDismount = (
  pageFilters: IPageFiltersData
) => ({
  type: SAVE_PAGE_FILTERS_ON_DISMOUNT,
  payload: pageFilters,
});

export const updatePlanningTab = (tabData: IPlanningTabData) => ({
  type: UPDATE_PLANNING_TAB,
  payload: tabData,
});

export const removePlanningTab = (page: FiltersPageEnum, tabId: string) => ({
  type: REMOVE_PLANNING_TAB,
  payload: { page, tabId },
});

export const selectPlanningTab = (page: FiltersPageEnum, tabId: string) => ({
  type: SELECT_PLANNING_TAB,
  payload: { page, tabId },
});

export const addPlanningTab = (
  page: FiltersPageEnum,
  newTab: IPlanningTabFilter
) => ({
  type: ADD_PLANNING_TAB,
  payload: { page, newTab },
});

export function updateTabName(
  page: FiltersPageEnum,
  tabId: string,
  newName: string
) {
  return {
    type: UPDATE_TAB_NAME,
    payload: { page, tabId, newName },
  };
}

export function setWasPlanningPageJustUpdated(bool: boolean) {
  return {
    type: WAS_PLANNING_PAGE_JUST_UPDATED,
    payload: bool,
  };
}
