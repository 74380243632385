import { borderRadiusSm, COLORS, marginLg, marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: ${marginLg};
  margin-left: ${marginLg};
  margin-right: ${marginLg};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 750rem;
`;

export const ContactDetailsContainer = styled.div`
  margin-top: ${marginMd};
  padding: 30rem;
  width: 750rem;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
`;

export const ContactDetailsLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-bottom: ${marginMd};
`;
