import { PHONE_NUMBER_REGEX } from 'utils/validation';
import * as Yup from 'yup';

export const createAltContactSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name should be at least 2 characters')
      .required('Name is required'),
    last_name: Yup.string()
      .min(2, 'Last name should be at least 2 characters')
      .required('Last name is required'),
    title: Yup.string().required('Title is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
      .required('Phone is required'),
    email: Yup.string().email('Invalid E-mail').required('E-mail is required'),
    code: Yup.string()
      .min(3, 'Zip code should be between 3 and 10 characters')
      .max(10, 'Zip code should be between 3 and 10 characters')
      .required('Zip code is required'),
    street: Yup.string()
      .min(1, 'Street should be between 1 and 80 characters')
      .max(80, 'Street should be between 1 and 80 characters')
      .required('Street is required'),
    city: Yup.string()
      .min(1, 'City should be between 1 and 80 characters')
      .max(80, 'City should be between 1 and 80 characters')
      .required('City is required'),
    house_number: Yup.string().required('House number is required'),
  });
};
