import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation } from 'react-query';
import {
  deleteCustomerAlternativeContact,
  getCustomerAlternativeContacts,
} from 'services/Customer/CustomerService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { toast } from 'utils/toast';

export const useGetSingleCustomerAlternativeContacts = (
  customerId: number,
  perPage: number,
  searchBy: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_CUSTOMER_ALTERNATIVE_CONTACTS,
      customerId,
      searchBy,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getCustomerAlternativeContacts(customerId, pageParam, perPage, searchBy),
    getNextPageParam: (lastPage) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    enabled: !!customerId,
  });

export interface IDeleteCustomerAlternativeContactDTO {
  customerId: number;
  contactId: number;
}

export const useDeleteCustomerAlternativeContact = () => {
  const { t } = useTranslation();
  return useMutation(
    (dto: IDeleteCustomerAlternativeContactDTO) =>
      deleteCustomerAlternativeContact(dto.customerId, dto.contactId),
    {
      onSuccess: () => {
        toast.success(t('Alternative contact deleted'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_CUSTOMER_ALTERNATIVE_CONTACTS
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.DELETE_CUSTOMER_ALTERNATIVE_CONTACT,
    }
  );
};
