import { COLORS, marginSm, marginXs, paddingSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  active: boolean;
  $bold: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: red;
  width: 290rem;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: ${paddingSm};
  min-height: 350rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  ${(props: StyledProps) =>
    props.active &&
    css`
      background-color: ${COLORS.PRIMARY};
      color: ${COLORS.WHITE};
      transform: scale(1.1);
    `};
`;

export const Title = styled.span`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  margin-bottom: ${marginSm};
  color: ${COLORS.BLACK};

  ${(props: StyledProps) =>
    props.active &&
    css`
      color: ${COLORS.WHITE};
    `}
`;

export const ThesisItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${marginXs};
  width: 100%;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.active &&
    css`
      color: ${COLORS.WHITE};
    `}
`;

export const Dot = styled.span`
  width: 5rem;
  height: 5rem;
  background-color: ${COLORS.BLACK};
  border-radius: 50%;
  display: inline-block;
  margin-right: ${marginXs};

  ${(props: StyledProps) =>
    props.active &&
    css`
      background-color: ${COLORS.WHITE};
    `}
`;

export const Content = styled.span`
  flex: 1;

  ${(props: StyledProps) =>
    props.$bold &&
    css`
      font-weight: 600;
    `}
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ThesisColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PriceWrapper = styled.div`
  font-weight: 300;
  color: ${COLORS.PRIMARY};
  margin-top: ${marginSm};

  ${(props: StyledProps) =>
    props.active &&
    css`
      background-color: ${COLORS.PRIMARY};
      color: ${COLORS.WHITE};
    `}
`;

export const PriceSpan = styled.span`
  font-size: 40rem;
`;
