import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import {
  Container,
  ContainerWrapper,
  LanguageSwitcherWrapper,
  Logo,
  Subtitle,
  Title,
  Wrapper,
  Text,
  InputWrapper,
  PasswordInputWrapper,
  EyeWrapper,
  ButtonContainer,
} from './SignUpActivationPage.styled';
import bpLogo from 'assets/icons/bpLogo.svg';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { activateUserSchema } from './validation';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { ISignUpActivation } from './types';
import { useState } from 'react';
import Icon from 'components/Icon/Icon';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Button from 'components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useActivateUser } from './hooks';
import { RoutesConfig } from 'navigation/routes';

export const SignUpActivationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const { mutate: activateUser } = useActivateUser();

  const handleEnterKeyPress = (event: any, values: ISignUpActivation) => {
    if (event.key === 'Enter') {
      activateUser(
        {
          name: values.name,
          last_name: values.last_name,
          password: values.password,
          token: token || '',
        },
        { onSuccess: () => navigate(RoutesConfig.Login.fullPath) }
      );
    }
  };

  const handleEyeClick = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <ContainerWrapper>
      <Wrapper>
        <ToggleDarkMode />
        <LanguageSwitcherWrapper>
          <LanguageSwitcher />
        </LanguageSwitcherWrapper>
      </Wrapper>
      <Container>
        <Logo src={bpLogo} alt="" />
      </Container>
      <Container>
        <Subtitle>{t('Activate your account')}</Subtitle>
        <Text>{`${t('Please enter your name, last name and password')}.`}</Text>
      </Container>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        initialValues={{
          name: '',
          last_name: '',
          password: '',
        }}
        validationSchema={activateUserSchema}
      >
        {({ values, errors, touched, isValid, handleBlur, setFieldValue }) => (
          <Form>
            <InputWrapper>
              <FormikInput
                height={'55rem'}
                name="name"
                errorMessage={touched['name'] ? errors['name'] : ''}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('name', value, true);
                  handleBlur(e);
                }}
                placeholder={t('Name')}
                onKeyPress={(event) => handleEnterKeyPress(event, values)}
              />
            </InputWrapper>
            <InputWrapper>
              <FormikInput
                height={'55rem'}
                name="last_name"
                errorMessage={touched['last_name'] ? errors['last_name'] : ''}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('last_name', value, true);
                  handleBlur(e);
                }}
                placeholder={t('Last Name')}
                onKeyPress={(event) => handleEnterKeyPress(event, values)}
              />
            </InputWrapper>
            <PasswordInputWrapper>
              <FormikInput
                height={'55rem'}
                name="password"
                errorMessage={touched['password'] ? errors['password'] : ''}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('password', value, true);
                  handleBlur(e);
                }}
                placeholder={t('Password')}
                type={hidePassword ? 'password' : ''}
                onKeyPress={(event) => handleEnterKeyPress(event, values)}
              />
              <EyeWrapper
                onClick={() => handleEyeClick()}
                data-testid={'eye-icon'}
              >
                <Icon
                  svg={hidePassword ? Eye : EyeSlash}
                  color={COLORS.BLACK}
                  size={25}
                />
              </EyeWrapper>
            </PasswordInputWrapper>
            <PasswordInputWrapper>
              <FormikInput
                height={'55rem'}
                name="confirm_password"
                errorMessage={
                  touched['confirm_password'] ? errors['confirm_password'] : ''
                }
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('confirm_password', value, true);
                  handleBlur(e);
                }}
                placeholder={t('Confirm Password')}
                type={hidePassword ? 'password' : ''}
                onKeyPress={(event) => handleEnterKeyPress(event, values)}
              />
            </PasswordInputWrapper>

            <ButtonContainer>
              <Button
                label={t('Continue')}
                primary
                disabled={false}
                width="100%"
                height={'55rem'}
                onClick={() => {
                  if (isValid) {
                    activateUser(
                      {
                        name: values.name,
                        last_name: values.last_name,
                        password: values.password,
                        token: token || '',
                      },
                      { onSuccess: () => navigate(RoutesConfig.Login.fullPath) }
                    );
                  }
                }}
              />
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </ContainerWrapper>
  );
};
