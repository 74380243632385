import { useState, useEffect } from 'react';

const useVerificationHook = (
  codeLength: number,
  onCodeChange: (code: string | null) => void
) => {
  const [inputStates, setInputStates] = useState<string[]>(
    Array(codeLength).fill('')
  );
  const [code, setCode] = useState<string | null>(null);

  useEffect(() => {
    const finalCode = inputStates.join('');
    if (finalCode.length === codeLength) {
      setCode(finalCode);
      onCodeChange(finalCode);
    } else {
      setCode(null);
      onCodeChange(null);
    }
  }, [inputStates, codeLength, onCodeChange]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;

    if (/^\d*$/.test(value)) {
      const newState = [...inputStates];

      if (value.length > 1) {
        const pastedValues = value.split('');
        pastedValues.forEach((digit, i) => {
          if (i < codeLength) {
            newState[i] = digit;
          }
        });
        setInputStates(newState);
      } else {
        newState[index] = value;
        setInputStates(newState);

        if (value && index < codeLength - 1) {
          document.getElementById(`code-input-${index + 1}`)?.focus();
        }
      }

      const finalCode = newState.join('');
      setCode(finalCode);
      onCodeChange(finalCode);

      const nextEmptyInputIndex = newState.findIndex((digit) => digit === '');
      if (nextEmptyInputIndex !== -1 && value.length > 1) {
        document.getElementById(`code-input-${nextEmptyInputIndex}`)?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Backspace' && !inputStates[index] && index > 0) {
      document.getElementById(`code-input-${index - 1}`)?.focus();
    }
  };

  return { code, inputStates, handleChange, handleKeyDown };
};

export default useVerificationHook;
