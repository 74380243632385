import * as Yup from 'yup';

export const editPrefixesCompanySettingsSchema = () => {
  return Yup.object().shape({
    quotation_prefix: Yup.string().required('Quotation prefix is required'),
    sales_order_prefix: Yup.string().required('Sales order prefix is required'),
    purchase_order_prefix: Yup.string().required(
      'Purchase order prefix is required'
    ),
    invoice_prefix: Yup.string().required('Invoice prefix is required'),
    customer_prefix: Yup.string().required('Customer prefix is required'),
    stock_items_prefix: Yup.string().required('Stock items prefix is required'),
    suppliers_prefix: Yup.string().required('Suppliers prefix is required'),
  });
};
