import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { generateDealerPanelIntent } from 'services/Logyx/LogyxService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useGenerateDealerPanelIntent = () =>
  useMutation(() => generateDealerPanelIntent(), {
    onSuccess: () => {
      // onSuccess
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GENERATE_DEALER_PANEL_INTENT,
      });
    },
    mutationKey: ReactMutationKeys.GENERATE_DEALER_PANEL_INTENT,
  });
