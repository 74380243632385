import { ICustomer } from 'types/Customer.types';
import { DiscountType } from 'types/Discount.types';
import { Option } from 'components/Select/type';
import { ICompanySettings } from 'types/Company.types';
import { ICreateQuotationCustomerDetails } from '../NewQuotationPage/CustomerTab/constants';
import { ICreateQuotationOrderDetails } from '../NewQuotationPage/OrderTab/constants';

export const getInitialCustomerDetailsData = (
  initialCountryCCA2: string
): ICreateQuotationCustomerDetails => {
  return {
    existingCustomer: null,
    name: '',
    gender: '',
    email: '',
    phone: '',
    country: initialCountryCCA2,
    street: '',
    code: '',
    last_name: '',
    city: '',
    house_number: '',
    company_name: '',
    vat_number: '',
    shipping_data: {
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      street: '',
      city: '',
      zip_code: '',
      house_number: '',
      company_name: '',
      email: '',
      title: '',
    },
    title: '',
    hasAlternativeInvoiceEmail: false,
    alternative_invoice_email: '',
    alternativeContact: undefined,
  };
};

export const calculateInitialDownpayment = (
  totalSalesPrice: string,
  downpaymentThreshold?: number,
  downpaymentPercentage?: number
) => {
  if (!downpaymentThreshold && !downpaymentPercentage) {
    return 0;
  }
  if (Number(totalSalesPrice) > Number(downpaymentThreshold)) {
    return (Number(totalSalesPrice) * Number(downpaymentPercentage)) / 100;
  } else {
    return 0;
  }
};

export const getInitialOrderDetailsData = (
  companySettings: ICompanySettings
): ICreateQuotationOrderDetails => {
  return {
    paymentConditionText: '',
    paymentConditionId: '',
    downpaymentConditionText: '',
    reference: '',
    downPayment: '',
    workingHours: '0',
    workingHoursRate: companySettings.working_hours_rate.toString(),
    workingHoursDiscount: '0',
    workingHoursDiscountType: DiscountType.PERCENTAGE,
    discount: '0',
    discountType: DiscountType.PERCENTAGE,
    labels: [],
  };
};
