import { useEffect, useState } from 'react';
import {
  Container,
  Image,
  LoaderWrapper,
  TrashIcon,
} from './ReportPagePictureCard.styled';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { Trash } from '@phosphor-icons/react';
import PDFIcon from 'assets/images/pdf-icon.png';
import Tooltip from 'components/Tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

interface IReportPagePictureCardProps {
  isReadOnly: boolean;
  picture: any;
  onDelete: () => void;
}

const ReportPagePictureCard = ({
  isReadOnly,
  picture,
  onDelete,
}: IReportPagePictureCardProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const pictureUrl = isReadOnly
    ? picture.preview_url
    : URL.createObjectURL(picture);

  useEffect(() => {
    // Cleanup blob URL on component unmount
    return () => {
      !isReadOnly && URL.revokeObjectURL(pictureUrl);
    };
  }, [pictureUrl]);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    console.error('Error loading image');
    setIsLoading(false);
  };
  const { isMobileApp } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  return (
    <Container>
      {isLoading && (
        <LoaderWrapper>
          <Spinner />
        </LoaderWrapper>
      )}
      <Tooltip content={picture.name}>
        <Image
          src={picture.type.includes('pdf') ? PDFIcon : pictureUrl}
          alt={`Uploaded image: ${picture.name}`}
          onLoad={handleImageLoaded}
          onError={handleImageError}
          onClick={() => !isMobileApp && window.open(pictureUrl, '_blank')}
        />
      </Tooltip>
      {isReadOnly ? null : (
        <TrashIcon onClick={() => onDelete()}>
          <Icon svg={Trash} color={COLORS.PRIMARY} size={20} />
        </TrashIcon>
      )}
    </Container>
  );
};

export default ReportPagePictureCard;
