import { useMutation } from 'react-query';
import { getCityAndAddress } from 'services/Common/CommonService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export interface IGetCityAndAddressDTO {
  zipCode: string;
  houseNumber: string;
}

export const useGetCityAndAddress = () =>
  useMutation(
    (data: IGetCityAndAddressDTO) =>
      getCityAndAddress(data.zipCode, data.houseNumber),
    {
      mutationKey: ReactMutationKeys.GET_CITY_AND_ADDRESS,
    }
  );
