import * as Yup from 'yup';

export const bankDetailsSchema = () => {
  return Yup.object().shape({
    bank_name: Yup.string().required('Bank name is required'),
    bank_code: Yup.string().required('Bank code is required'),
    account_holder_name: Yup.string().required(
      'Account holder name is required'
    ),
    iban: Yup.string().required('IBAN is required'),
  });
};
