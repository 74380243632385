import { COLORS, H3, marginMd } from 'assets/styled';
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICreateQuotationCustomerDetails } from '../CustomerTab/constants';
import { ICreateQuotationOrderDetails } from '../OrderTab/constants';
import { ICreateQuotationProductFormDTO } from '../ProductTab/constants';
import FakeInput from '../../../../components/FakeInput/FakeInput';
import { useTranslation } from 'react-i18next';
import {
  Container,
  CustomerDetailsWrapper,
  FirstRow,
  Line,
  Margin,
  OrderDetailsWrapper,
  ProductFlexWrap,
  ProductsWrapper,
  ProductTitle,
  ProductSupplierLabel,
  RowMarginTop,
  RowSpaceBetween,
  SectionTitle,
  SingleProductWrapper,
  SubmitRow,
  GreenLine,
  TotalPurchasePriceWrapper,
  EyeWrapper,
  ProductFormDTOFieldWrapper,
  FieldWrapper,
  InputWrapper,
  RowSingleCell,
  LabelWrapper,
} from './OverviewTab.styled';
import Tooltip from 'components/Tooltip/Tooltip';
import { convertToTitleCase } from 'utils/stringUtils';
import {
  calculateSalesPriceWithDiscount,
  calculateTotalPurchasePrice,
  calculateTotalWorkingHoursPrice,
} from '../OrderTab/helpers';
import {
  excludedProductFormDTOKeys,
  pricingProductFormDTOKeys,
} from './constants';
import { IProductAttribute } from 'types/Product.types';
import { formatLogyxAttributesObjectIntoArray } from 'utils/productAttributeFormatting/formatLogyxAttributesObjectIntoArray';
import { ILogyxAttribute, QuestionType } from 'store/Logyx/types';
import { formatNumber } from 'utils/numberFormatter';
import {
  formatProductFormDTONumberFields,
  mapValueToPlaceholder,
} from './helpers';
import { RoutesConfig } from 'navigation/routes';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import Icon from 'components/Icon/Icon';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import FakeLogyxInput from 'components/FakeLogyxInput/FakeLogyxInput';
import {
  useGetRecurringIntervalOptions,
  useGetRecurringTypeOptions,
} from '../RecurringQuotationModal/hooks';
import { ILabel } from 'types/EntityLabel.types';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';

interface IOverviewTab {
  onForward: () => void;
  onBack: () => void;
  customerDetails: ICreateQuotationCustomerDetails | null;
  productFormDtos: ICreateQuotationProductFormDTO[];
  orderDetails: ICreateQuotationOrderDetails | null;
  status: string;
  newlyCreatedQuotationId: string;
  withoutPrices?: boolean;
  isEditProduct?: boolean;
  entityType?: 'quotation' | 'salesOrder';
}

const OverviewTab = ({
  onForward,
  onBack,
  customerDetails,
  productFormDtos,
  orderDetails,
  status,
  newlyCreatedQuotationId,
  withoutPrices = false,
  isEditProduct = false,
  entityType,
}: IOverviewTab) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const recurringTypeOptions = useGetRecurringTypeOptions();
  const recurringIntervalOptions = useGetRecurringIntervalOptions();

  const [isPurchasePriceHidden, setIsPurchasePriceHidden] =
    useState<boolean>(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (status === 'success' && newlyCreatedQuotationId) {
      if (entityType === 'salesOrder') {
        navigate(
          RoutesConfig.SingleSalesOrder.fullPath
            .replace(':id', newlyCreatedQuotationId)
            .replace('/*', '')
        );
      } else {
        navigate(
          RoutesConfig.SingleQuotation.fullPath
            .replace(':id', newlyCreatedQuotationId)
            .replace('/*', '')
        );
      }
    }
  }, [status]);

  const renderProductFormDtoFields = (
    productFormDto: ICreateQuotationProductFormDTO
  ) => {
    let index = 0;

    return Object.keys(productFormDto).map((key) => {
      if (excludedProductFormDTOKeys.includes(key)) {
        return <></>;
      }
      if (withoutPrices && pricingProductFormDTOKeys.includes(key)) {
        return <></>;
      }
      index++;
      const isPurchasePriceField = key === 'purchasePrice';

      const getLabelValue = () => {
        if (key === 'discountType') return t(productFormDto[key]);
        else {
          if (productFormDto[key] === 'DOWN_PAYMENT') return t('AMOUNT');
          else
            return formatProductFormDTONumberFields(key, productFormDto[key]);
        }
      };

      return (
        <ProductFormDTOFieldWrapper key={index}>
          {productFormDto[key]?.toString()?.length > 30 ? (
            <Tooltip content={productFormDto[key]}>
              <FakeInput
                secure={isPurchasePriceField && isPurchasePriceHidden}
                marginTop={marginMd}
                label={`${productFormDto[key]?.substring(0, 30)}...`}
                isDropdown={false}
                width={'100%'}
                placeholder={t(mapValueToPlaceholder(key))}
              />
            </Tooltip>
          ) : (
            <FakeInput
              secure={isPurchasePriceField && isPurchasePriceHidden}
              marginTop={marginMd}
              key={index}
              label={getLabelValue()}
              isDropdown={false}
              width={'100%'}
              placeholder={t(mapValueToPlaceholder(key))}
            />
          )}
          {isPurchasePriceField ? (
            <EyeWrapper
              onClick={() => setIsPurchasePriceHidden(!isPurchasePriceHidden)}
              data-testid={'eye-icon'}
              top={'40rem'}
            >
              <Icon
                svg={isPurchasePriceHidden ? Eye : EyeSlash}
                color={COLORS.BLACK}
                size={20}
              />
            </EyeWrapper>
          ) : null}
        </ProductFormDTOFieldWrapper>
      );
    });
  };

  const renderProductFormDtoAttributes = (
    productFormDto: ICreateQuotationProductFormDTO
  ) => {
    let index = 0;
    return productFormDto.attributes.map((attribute: IProductAttribute) => {
      index++;
      return attribute.value && attribute.value.toString()?.length > 30 ? (
        <Tooltip content={attribute.value} key={index}>
          <FakeInput
            marginTop={marginMd}
            label={`${attribute.value?.substring(0, 30)}...`}
            isDropdown={false}
            width={'48%'}
            placeholder={convertToTitleCase(attribute.fieldName)}
          />
        </Tooltip>
      ) : (
        <FakeInput
          marginTop={marginMd}
          key={index}
          label={attribute.value!}
          isDropdown={false}
          width={'48%'}
          placeholder={convertToTitleCase(attribute.fieldName)}
        />
      );
    });
  };

  const renderProductFormDtoLogyxAttributes = (
    productFormDto: ICreateQuotationProductFormDTO
  ) => {
    if (productFormDto.logyxConfigurationIntent?.data) {
      const logyxAttributes = formatLogyxAttributesObjectIntoArray(
        productFormDto.logyxConfigurationIntent.data
      );
      return (
        <>
          <GreenLine />
          {logyxAttributes.map(
            (logyxAttribute: ILogyxAttribute, index: number) => {
              index++;

              const logyxAttributeValue = Array.isArray(logyxAttribute.value)
                ? JSON.stringify(logyxAttribute.value)
                : logyxAttribute.value;

              return logyxAttributeValue?.toString()?.length > 30 ? (
                <InputWrapper>
                  <FakeLogyxInput
                    marginTop={marginMd}
                    label={logyxAttributeValue}
                    questionType={logyxAttribute.type}
                    width={'100%'}
                    placeholder={
                      logyxAttributeValue &&
                      typeof logyxAttributeValue === 'string'
                        ? convertToTitleCase(logyxAttributeValue)
                        : logyxAttributeValue
                    }
                  />
                </InputWrapper>
              ) : (
                <FakeInput
                  marginTop={marginMd}
                  key={index}
                  label={logyxAttributeValue}
                  isDropdown={false}
                  width={'48%'}
                  placeholder={convertToTitleCase(logyxAttribute.fieldName)}
                />
              );
            }
          )}
          <GreenLine />
        </>
      );
    }
  };

  return (
    <Container>
      <FirstRow>
        <H3>{t('Overview')}</H3>
        <Button
          width={'200rem'}
          onClick={() =>
            isEditProduct ? setIsConfirmModalOpen(true) : onForward()
          }
          label={t('Finish')}
          primary
          disabled={status === 'loading'}
        />
      </FirstRow>
      <LabelWrapper>
        {orderDetails?.labels?.map((label: ILabel) => {
          return (
            <EntityLabel
              key={label.id}
              label={label}
              tooltipContent={
                label.name.length > 15 ? label.name : label.description
              }
            />
          );
        })}
      </LabelWrapper>
      {customerDetails && (
        <CustomerDetailsWrapper>
          <SectionTitle>{t('Customer details')}</SectionTitle>
          <RowMarginTop>
            <FakeInput
              label={customerDetails.name}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Name')}
            />
            <FakeInput
              label={customerDetails.last_name}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Last name')}
            />
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={customerDetails.phone}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Phone number')}
            />
            <FakeInput
              label={customerDetails.title}
              isDropdown={true}
              width={'50%'}
              placeholder={t('Title')}
            />
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={customerDetails.email}
              isDropdown={false}
              width={'50%'}
              placeholder={t('E-mail')}
            />
            <FakeInput
              label={customerDetails.country}
              isDropdown={true}
              width={'50%'}
              placeholder={t('Country')}
            />
          </RowMarginTop>
          {!!customerDetails.alternative_invoice_email?.length && (
            <RowSingleCell>
              <FakeInput
                label={customerDetails.alternative_invoice_email}
                isDropdown={false}
                width={'50%'}
                placeholder={t('Alternative invoice E-mail')}
              />
            </RowSingleCell>
          )}
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={customerDetails.company_name}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Company Name')}
            />
            <FakeInput
              label={customerDetails.vat_number}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Company VAT')}
            />
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={customerDetails.city}
              isDropdown={false}
              width={'50%'}
              placeholder={t('City')}
            />
            <FakeInput
              label={customerDetails.code}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Zip code')}
            />
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={customerDetails.street}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Street')}
            />
            <FakeInput
              label={customerDetails.house_number}
              isDropdown={false}
              width={'50%'}
              placeholder={t('House number')}
            />
          </RowMarginTop>

          {customerDetails.shipping_data && (
            <>
              <RowMarginTop marginTop={marginMd}>
                <ProductTitle>{t('Ship-to address')}</ProductTitle>
              </RowMarginTop>

              <RowMarginTop>
                <FakeInput
                  label={customerDetails.shipping_data!.first_name!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('Name')}
                />
                <FakeInput
                  label={customerDetails.shipping_data!.last_name!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('Last name')}
                />
              </RowMarginTop>
              <RowMarginTop marginTop={marginMd}>
                <FakeInput
                  label={customerDetails.shipping_data!.phone!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('Phone number')}
                />
                <FakeInput
                  label={customerDetails.shipping_data!.title}
                  isDropdown={true}
                  width={'50%'}
                  placeholder={t('Title')}
                />
              </RowMarginTop>

              <RowMarginTop marginTop={marginMd}>
                <FakeInput
                  label={customerDetails.shipping_data!.email!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('Email')}
                />
                <FakeInput
                  label={customerDetails.shipping_data!.country!}
                  isDropdown={true}
                  width={'50%'}
                  placeholder={t('Country')}
                />
              </RowMarginTop>
              <RowMarginTop marginTop={marginMd}>
                <FakeInput
                  label={customerDetails.shipping_data!.city!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('City')}
                />
                <FakeInput
                  label={customerDetails.shipping_data!.zip_code!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('Zip code')}
                />
              </RowMarginTop>
              <RowMarginTop marginTop={marginMd}>
                <FakeInput
                  label={customerDetails.shipping_data!.street!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('Street')}
                />
                <FakeInput
                  label={customerDetails.shipping_data!.house_number!}
                  isDropdown={false}
                  width={'50%'}
                  placeholder={t('House number')}
                />
              </RowMarginTop>
              <RowMarginTop marginTop={marginMd}>
                <FieldWrapper>
                  <FakeInput
                    label={customerDetails.shipping_data!.company_name!}
                    isDropdown={false}
                    width={'100%'}
                    placeholder={t('Company name')}
                  />
                </FieldWrapper>
              </RowMarginTop>
            </>
          )}
          <Line marginTop={marginMd} />
        </CustomerDetailsWrapper>
      )}
      <ProductsWrapper>
        <SectionTitle>{t('Products')}</SectionTitle>
        {productFormDtos.map((productFormDto, index) => (
          <SingleProductWrapper key={index}>
            <RowSpaceBetween>
              <ProductTitle>
                {t('Product')} {productFormDto.product.name}
              </ProductTitle>
              <ProductSupplierLabel>
                {productFormDto.supplier.company_name}
              </ProductSupplierLabel>
            </RowSpaceBetween>
            <ProductFlexWrap>
              {renderProductFormDtoFields(productFormDto)}
            </ProductFlexWrap>
            <ProductFlexWrap>
              {renderProductFormDtoLogyxAttributes(productFormDto)}
            </ProductFlexWrap>

            <ProductFlexWrap>
              {renderProductFormDtoAttributes(productFormDto)}
            </ProductFlexWrap>
          </SingleProductWrapper>
        ))}
        <Line />
      </ProductsWrapper>
      {orderDetails && (
        <OrderDetailsWrapper>
          <SectionTitle>{t('Order details')}</SectionTitle>
          <RowMarginTop>
            <FieldWrapper>
              <Tooltip
                content={
                  orderDetails.reference.length > 30
                    ? orderDetails.reference
                    : undefined
                }
              >
                <FakeInput
                  label={
                    orderDetails.reference.length > 30
                      ? `${orderDetails.reference.substring(0, 30)}...`
                      : orderDetails.reference
                  }
                  isDropdown={false}
                  width={'100%'}
                  placeholder={t('Reference')}
                />
              </Tooltip>
            </FieldWrapper>
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={
                orderDetails.downPayment
                  ? `${currencySymbol}${formatNumber(orderDetails.downPayment)}`
                  : ''
              }
              isDropdown={false}
              width={'50%'}
              placeholder={t('Down payment')}
            />
            <FakeInput
              label={orderDetails?.daysValid}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Days valid')}
            />
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FieldWrapper>
              <FakeInput
                label={formatNumber(orderDetails.workingHours)}
                isDropdown={false}
                width={'100%'}
                placeholder={t('Working hours')}
              />
            </FieldWrapper>
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={`${currencySymbol}${formatNumber(
                orderDetails.workingHoursRate
              )}`}
              isDropdown={false}
              width={'30%'}
              placeholder={t('Working hour rate')}
            />
            <FakeInput
              label={formatNumber(orderDetails.workingHoursDiscount.toString())}
              isDropdown={false}
              width={'30%'}
              placeholder={t('Working hours discount')}
            />
            <FakeInput
              label={
                orderDetails.workingHoursDiscountType.toString() ===
                'DOWN_PAYMENT'
                  ? t('AMOUNT')
                  : t(orderDetails.workingHoursDiscountType.toString())
              }
              isDropdown={false}
              width={'30%'}
              placeholder={t('Working hours discount type')}
            />
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FieldWrapper>
              <FakeInput
                label={`${currencySymbol}${formatNumber(
                  calculateTotalWorkingHoursPrice(orderDetails)
                )}`}
                placeholder="Total working hours price"
                isDropdown={false}
                width="100%"
              />
            </FieldWrapper>
          </RowMarginTop>
          <RowMarginTop marginTop={marginMd}>
            <FakeInput
              label={`${currencySymbol}${formatNumber(
                calculateSalesPriceWithDiscount(orderDetails, productFormDtos)
              )}`}
              isDropdown={false}
              width={'50%'}
              placeholder={t('Total sales price')}
            />
            <TotalPurchasePriceWrapper>
              <FakeInput
                secure={isPurchasePriceHidden}
                label={`${currencySymbol}${formatNumber(
                  calculateTotalPurchasePrice(productFormDtos)
                )}`}
                isDropdown={false}
                width={'100%'}
                placeholder={t('Expected total purchase price exc. VAT')}
              />
              <EyeWrapper
                onClick={() => setIsPurchasePriceHidden(!isPurchasePriceHidden)}
                data-testid={'eye-icon'}
                top={'10rem'}
              >
                <Icon
                  svg={isPurchasePriceHidden ? Eye : EyeSlash}
                  color={COLORS.BLACK}
                  size={20}
                />
              </EyeWrapper>
            </TotalPurchasePriceWrapper>
          </RowMarginTop>
          {orderDetails?.recurringPeriodId && orderDetails?.recurringTypeId && (
            <RowMarginTop marginTop={marginMd}>
              <FakeInput
                label={t(
                  recurringIntervalOptions.find(
                    (recurringInterval) =>
                      recurringInterval.value ===
                      orderDetails?.recurringPeriodId
                  )?.label
                )}
                isDropdown={false}
                width={'50%'}
                placeholder={t('Recurring period')}
              />
              <FakeInput
                label={t(
                  recurringTypeOptions.find(
                    (recurringType) =>
                      recurringType.value === orderDetails?.recurringTypeId
                  )?.label
                )}
                isDropdown={false}
                width={'50%'}
                placeholder={t('Recurring type')}
              />
            </RowMarginTop>
          )}
        </OrderDetailsWrapper>
      )}
      <SubmitRow>
        <Button
          width={'200rem'}
          onClick={() => onBack()}
          label={t('Back')}
          secondary
          disabled={status === 'loading'}
        />
        <Margin>
          <Button
            width={'200rem'}
            onClick={() =>
              isEditProduct ? setIsConfirmModalOpen(true) : onForward()
            }
            label={t('Finish')}
            primary
            disabled={status === 'loading'}
          />
        </Margin>
      </SubmitRow>
      <ConfirmModal
        title={t('Edit Product')}
        description={`${t('Are you sure you want to edit this product')}?`}
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={() => {
          onForward();
          setIsConfirmModalOpen(false);
        }}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
    </Container>
  );
};

export default OverviewTab;
