import { useParams } from 'react-router-dom';
import ContactCard from './ContactCard/ContactCard';
import { Container, NoContentLabel } from './SingleCustomerAltContacts.styled';
import {
  IDeleteCustomerAlternativeContactDTO,
  useDeleteCustomerAlternativeContact,
  useGetSingleCustomerAlternativeContacts,
} from './hooks';
import { useMemo, useRef, useState } from 'react';
import { ICustomerAlternativeContact } from 'types/Customer.types';
import Spinner from 'components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import AddEditCustomerAlternativeContactModal from './AddEditCustomerAlternativeContactModal/AddEditCustomerAlternativeContactModal';

export const CUSTOMER_ALTERNATIVE_CONTACTS_PER_PAGE = 10;

const SingleCustomerAltContacts = () => {
  const { id } = useParams();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [contactIdForDeletion, setContactIdForDeletion] = useState<
    number | null
  >(null);
  const [isEditContactModalOpen, setIsEditContactModalOpen] =
    useState<boolean>(false);
  const [contactForEditing, setContactForEditing] =
    useState<ICustomerAlternativeContact | null>(null);
  const {
    data: customerAlternativeContactsData,
    isLoading: isLoadingContacts,
    hasNextPage: hasNextPageAltContacts,
    fetchNextPage: fetchNextPageAltContacts,
    isLoading: isLoadingAltContacts,
  } = useGetSingleCustomerAlternativeContacts(
    Number(id),
    CUSTOMER_ALTERNATIVE_CONTACTS_PER_PAGE,
    ''
  );

  const customerAlternativeContacts: ICustomerAlternativeContact[] =
    useMemo(() => {
      if (customerAlternativeContactsData?.pages?.length) {
        return customerAlternativeContactsData.pages
          .map((page) => page.alternative_contacts)
          .flat();
      }
      return [];
    }, [customerAlternativeContactsData]);

  const {
    mutate: deleteCustomerAlternativeContact,
    isLoading: isLoadingDeleteContact,
  } = useDeleteCustomerAlternativeContact();

  useLoadNextPage(
    loadMoreRef,
    hasNextPageAltContacts,
    fetchNextPageAltContacts,
    isLoadingAltContacts
  );

  return (
    <Container>
      {customerAlternativeContacts.map(
        (contact: ICustomerAlternativeContact, index: number) => (
          <ContactCard
            key={index}
            contact={contact}
            index={index + 1}
            onEdit={(contact: ICustomerAlternativeContact) => {
              setIsEditContactModalOpen(true);
              setContactForEditing(contact);
            }}
            onDelete={(contactId: number) => {
              setContactIdForDeletion(contactId);
              setIsDeleteModalOpen(true);
            }}
          />
        )
      )}
      {isLoadingContacts ? (
        <Spinner />
      ) : (
        !customerAlternativeContacts?.length && (
          <NoContentLabel>{t('No alternative contacts found')}</NoContentLabel>
        )
      )}
      <AddEditCustomerAlternativeContactModal
        isOpen={isEditContactModalOpen}
        setIsOpen={setIsEditContactModalOpen}
        customerId={Number(id)}
        contact={contactForEditing || undefined}
      />
      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (contactIdForDeletion) {
                const dto: IDeleteCustomerAlternativeContactDTO = {
                  customerId: Number(id),
                  contactId: Number(contactIdForDeletion),
                };
                deleteCustomerAlternativeContact(dto, {
                  onSuccess: () => {
                    setIsDeleteModalOpen(false);
                    setContactIdForDeletion(null);
                  },
                });
              }
            },
            text: t('Delete'),
            disabled: isLoadingDeleteContact,
          }}
          cancel={{
            onClick: () => {
              setIsDeleteModalOpen(false);
              setContactIdForDeletion(null);
            },
          }}
          title={t('Delete alternative contact')}
          description={`${t(
            'Are you sure you want to delete this alternative contact'
          )}?`}
        />
      </Modal>
      <div
        ref={loadMoreRef}
        style={{
          height: '1px',
        }}
      />
    </Container>
  );
};

export default SingleCustomerAltContacts;
