import { ISignUpCompanyDetails, ISignUpVerificationDetails } from './types';

export const sortCountries = (countries: any) => {
  return countries?.sort((a: any, b: any) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const getInitialCompanyDetails = (
  companyDetails: ISignUpCompanyDetails,
  verificationDetails: ISignUpVerificationDetails
) => {
  return {
    company_name: companyDetails.company_name,
    street: companyDetails.street,
    house_number: companyDetails.house_number,
    company_vat: companyDetails.company_vat || verificationDetails.vat,
    company_coc: companyDetails.company_coc,
    country_code: companyDetails.country_code,
    phone: companyDetails.phone,
    website: companyDetails.website,
    zip_code: companyDetails.zip_code,
    city: companyDetails.city,
  };
};

export const getLicenseName = (name: string) => {
  switch (name) {
    case 'REGULAR':
      return 'Blueprint';
    case 'PRO':
      return 'Blueprint Pro';
    case 'PLUS':
      return 'Blueprint Plus';
    default:
      return;
  }
};
