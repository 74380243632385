import { COLORS, H3 } from 'assets/styled';
import Input from 'components/Input/Input';
import { Option } from 'components/Select/type';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table/Table';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import {
  IInvoiceResponseDTO,
  IPurchaseInvoiceTableDTO,
  ISalesInvoiceTableDTO,
} from 'types/Invoice.types';
import {
  InvoicesFilterType,
  INVOICES_PER_PAGE,
  saleTableConfig,
  purchaseTableConfig,
} from './constants';
import {
  useExportSalesInvoicesExcel,
  useGetInvoicesPageFilters,
  useGetPurchaseInvoices,
  useGetSalesInvoices,
  useHandleAnalyticsNavigationState,
  useManageAndSaveFilters,
} from './hooks';
import {
  FiltersWrapper,
  ButtonWrapper,
  Container,
  ExcelExportButton,
  FlexAlignCenter,
  InvoiceFilterTypeLabel,
  InvoiceFilterTypesWrapper,
  Row,
  RowRightPart,
  RowWrapper,
  TitleContainer,
} from './InvoicesPage.styled';
import {
  formatPurchaseInvoicesTableData,
  formatSalesInvoicesTableData,
} from 'components/Table/tableDataFormatter';
import { useDebounce } from 'utils/hooks/useDebounce';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Button from 'components/Button/Button';
import { AddPaymentsModal } from './AddPaymentsModal/AddPaymentsModal';
import { useState } from 'react';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { MicrosoftExcelLogo, MagnifyingGlass } from '@phosphor-icons/react';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useGetInvoicesAdvancedFilters } from './advancedFilters';
import LabelsFilter from 'components/LabelsFilter/LabelsFilter';

const InvoicesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [advancedFilters, setAdvancedFilters] = useState<string>('');
  const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);

  const [isAddPaymentsModalOpen, setIsAddPaymentsModalOpen] =
    useState<boolean>(false);

  const {
    invoicesPageFilters,
    salesInvoiceStatusOptions,
    purchaseInvoiceStatusOptions,
    dateIntervalOptions,
  } = useGetInvoicesPageFilters();

  const {
    page,
    setPage,
    selectedInvoiceFilterType,
    setSelectedInvoiceFilterType,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedDateIntervalOption,
    setSelectedDateIntervalOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useManageAndSaveFilters(invoicesPageFilters);

  useHandleAnalyticsNavigationState(setSelectedDateIntervalOption);

  const debouncedSearchBy = useDebounce(searchBy);

  const statusOptions: Option[] =
    selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES
      ? salesInvoiceStatusOptions
      : purchaseInvoiceStatusOptions;

  const { mutate: exportSalesInvoicesExcel } = useExportSalesInvoicesExcel();

  const { data: salesInvoicesData, isLoading: salesInvoicesIsLoading } =
    useGetSalesInvoices(
      page,
      INVOICES_PER_PAGE,
      selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES,
      debouncedSearchBy,
      false,
      selectedStatusOptions?.map((option: Option) => option.value).join(','),
      selectedDateIntervalOption?.value,
      sortBy,
      sortDirection,
      advancedFilters,
      selectedLabelIds
    );

  const { data: purchaseInvoicesData, isLoading: purchaseInvoicesIsLoading } =
    useGetPurchaseInvoices(
      page,
      INVOICES_PER_PAGE,
      selectedInvoiceFilterType === InvoicesFilterType.PURCHASE_INVOICES,
      debouncedSearchBy,
      false,
      selectedStatusOptions?.map((option: Option) => option.value).join(','),
      selectedDateIntervalOption?.value,
      sortBy,
      sortDirection,
      advancedFilters,
      selectedLabelIds
    );

  let tableData: ISalesInvoiceTableDTO[] | IPurchaseInvoiceTableDTO[] = [];

  let invoiceData = [];
  if (selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES) {
    invoiceData = salesInvoicesData?.sales_invoices || [];
    tableData = formatSalesInvoicesTableData(
      invoiceData
        .map((quotation: IInvoiceResponseDTO[]) => {
          return quotation;
        })
        .flat(),
      currencySymbol
    );
  } else {
    invoiceData = purchaseInvoicesData?.purchase_invoices || [];
    tableData = formatPurchaseInvoicesTableData(
      invoiceData
        .map((quotation: IInvoiceResponseDTO[]) => {
          return quotation;
        })
        .flat(),
      currencySymbol
    );
  }

  const handleViewButtonClick = (
    rowData: ISalesInvoiceTableDTO | IPurchaseInvoiceTableDTO
  ) => {
    if (selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES) {
      rowData.isCreditInvoice === 'Yes'
        ? navigate(
            `${RoutesConfig.SingleSalesCreditInvoice.fullPath.replace(
              ':id/*',
              rowData.navigationID
            )}`
          )
        : navigate(
            `${RoutesConfig.SingleSalesInvoice.fullPath.replace(
              ':id/*',
              rowData.navigationID
            )}`
          );
    } else {
      rowData.isCreditInvoice === 'Yes'
        ? navigate(
            `${RoutesConfig.SinglePurchaseCreditInvoice.fullPath.replace(
              ':id/*',
              rowData.navigationID
            )}`
          )
        : navigate(
            `${RoutesConfig.SinglePurchaseInvoice.fullPath.replace(
              ':id/*',
              rowData.navigationID
            )}`
          );
    }
  };

  const { filterTypes } = useGetInvoicesAdvancedFilters(statusOptions);

  const handleSelectedLabelsChange = (selectedIds: number[]) => {
    setSelectedLabelIds(selectedIds);
  };

  return (
    <Container>
      <TitleContainer>
        <H3>{t('Overview')}</H3>
        <ButtonWrapper>
          <Button
            width={'200rem'}
            onClick={() => setIsAddPaymentsModalOpen(true)}
            label={t('Add payments')}
            secondary
          />
          {selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES && (
            <ExcelExportButton
              width={'200rem'}
              icon={MicrosoftExcelLogo}
              onClick={exportSalesInvoicesExcel}
              label={t('Export excel')}
              secondary
              colorIcon={COLORS.BLACK}
              sizeIcon={20}
            />
          )}
        </ButtonWrapper>
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'400rem'}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </FlexAlignCenter>
        <RowRightPart>
          <CheckableSelect
            placeholder={t('Status')}
            isMulti
            options={statusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedStatusOptions(e)}
            defaultValues={selectedStatusOptions}
          />
          <CheckableSelect
            placeholder={t('Date')}
            options={dateIntervalOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedDateIntervalOption(options[0])
            }
            value={selectedDateIntervalOption}
            defaultValues={
              selectedDateIntervalOption
                ? [selectedDateIntervalOption]
                : [dateIntervalOptions[0]]
            }
          />
        </RowRightPart>
      </Row>
      <RowWrapper>
        <InvoiceFilterTypesWrapper>
          <InvoiceFilterTypeLabel
            data-testid="sales-invoices-label"
            onClick={() => {
              setSelectedStatusOptions(
                selectedStatusOptions?.filter(
                  (option) => option.value !== 'RECEIVED'
                )
              );

              setSelectedInvoiceFilterType(InvoicesFilterType.SALES_INVOICES);
              setPage(1);
            }}
            isSelected={
              selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES
            }
          >
            {t(InvoicesFilterType.SALES_INVOICES)}
          </InvoiceFilterTypeLabel>
          <InvoiceFilterTypeLabel
            data-testid="purchase-invoices-label"
            onClick={() => {
              setSelectedStatusOptions(
                selectedStatusOptions?.filter(
                  (option) => option.value !== 'SENT'
                )
              );
              if (sortBy === 'accounting') {
                setSortBy(undefined);
              }
              setSelectedInvoiceFilterType(
                InvoicesFilterType.PURCHASE_INVOICES
              );
              setPage(1);
            }}
            isSelected={
              selectedInvoiceFilterType === InvoicesFilterType.PURCHASE_INVOICES
            }
          >
            {t(InvoicesFilterType.PURCHASE_INVOICES)}
          </InvoiceFilterTypeLabel>
        </InvoiceFilterTypesWrapper>
        <FiltersWrapper>
          <LabelsFilter onSelectedLabelsChange={handleSelectedLabelsChange} />
          <Button
            onClick={() => setIsAdvancedFilterModalOpen(true)}
            label={t('Advanced filter')}
            width="145rem"
            height="31rem"
            secondary={!advancedFilters}
            primary={!!advancedFilters}
          />
        </FiltersWrapper>
      </RowWrapper>
      <Table
        page={page}
        isLoading={
          selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES
            ? salesInvoicesIsLoading
            : purchaseInvoicesIsLoading
        }
        perPage={INVOICES_PER_PAGE}
        total={
          selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES
            ? salesInvoicesData?.total
            : purchaseInvoicesData?.total
        }
        tableData={tableData}
        tableConfig={
          selectedInvoiceFilterType === InvoicesFilterType.SALES_INVOICES
            ? saleTableConfig
            : purchaseTableConfig
        }
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
        labelsRow
      />

      <AddPaymentsModal
        isOpen={isAddPaymentsModalOpen}
        setIsOpen={setIsAddPaymentsModalOpen}
        onCancel={() => setIsAddPaymentsModalOpen(false)}
      />

      <AdvancedFilterModal
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
      />
    </Container>
  );
};

export default InvoicesPage;
