import {
  Container,
  ContainerWrapper,
  LanguageSwitcherWrapper,
  Logo,
  Tab,
  TabContainer,
  Title,
  Wrapper,
} from './SignUpPage.styled';
import bpLogo from 'assets/icons/bpLogo.svg';
import bpLogoWhite from 'assets/images/blueprintLogoBig.png';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  INITIAL_BANK_DETAILS,
  INITIAL_COMPANY_DETAILS,
  INITIAL_VERIFICATION_DETAILS,
} from './constants';
import { VerificationTab } from './VerificationTab/VerificationTab';
import { CompanyDetailsTab } from './CompanyDetailsTab/CompanyDetailsTab';
import { BankDetailsTab } from './BankDetailsTab/BankDetailsTab';
import { PaymentPlanTab } from './PaymentPlanTab/PaymentPlanTab';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useSignUp } from './hooks';
import {
  ISignUpBankDetails,
  ISignUpCompanyDetails,
  ISignUpPlanDetails,
  ISignUpVerificationDetails,
  SignUpTab,
} from './types';
import Loader from 'components/Loader/Loader';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export const SignUpPage = () => {
  const { t } = useTranslation();
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  const [activeTab, setActiveTab] = useState<string>(SignUpTab.VERIFICATION);
  const [verificationDetails, setVerificationDetails] =
    useState<ISignUpVerificationDetails>(INITIAL_VERIFICATION_DETAILS);
  const [companyDetails, setCompanyDetails] = useState<ISignUpCompanyDetails>(
    INITIAL_COMPANY_DETAILS
  );
  const [bankDetails, setBankDetails] =
    useState<ISignUpBankDetails>(INITIAL_BANK_DETAILS);

  const {
    mutate: signUp,
    isLoading: isLoadingSignUp,
    isSuccess: isSuccessSignUp,
  } = useSignUp();

  const handleActiveTab = () => {
    if (activeTab === SignUpTab.VERIFICATION) {
      return (
        <TabContainer>
          <Tab active>1. {t('Verification')}</Tab>
          <Tab>2. {t('Company details')}</Tab>
          <Tab>3. {t('Bank details')}</Tab>
          <Tab>4. {t('Payment')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === SignUpTab.COMPANY_DETAILS) {
      return (
        <TabContainer>
          <Tab>1. {t('Verification')}</Tab>
          <Tab active>2. {t('Company details')}</Tab>
          <Tab>3. {t('Bank details')}</Tab>
          <Tab>4. {t('Payment Plan')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === SignUpTab.BANK_DETAILS) {
      return (
        <TabContainer>
          <Tab>1. {t('Verification')}</Tab>
          <Tab>2. {t('Company details')}</Tab>
          <Tab active>3. {t('Bank details')}</Tab>
          <Tab>4. {t('Payment Plan')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === SignUpTab.PAYMENT_PLAN) {
      return (
        <TabContainer>
          <Tab>1. {t('Verification')}</Tab>
          <Tab>2. {t('Company details')}</Tab>
          <Tab>3. {t('Bank details')}</Tab>
          <Tab active>4. {t('Payment Plan')}</Tab>
        </TabContainer>
      );
    } else {
      return (
        <TabContainer>
          <Tab>1. {t('Verification')}</Tab>
          <Tab>2. {t('Company details')}</Tab>
          <Tab>3. {t('Bank details')}</Tab>
          <Tab>4. {t('Payment Plan')}</Tab>
        </TabContainer>
      );
    }
  };
  return (
    <ContainerWrapper>
      <Wrapper>
        <ToggleDarkMode />
        <LanguageSwitcherWrapper>
          <LanguageSwitcher />
        </LanguageSwitcherWrapper>
      </Wrapper>
      {isSuccessSignUp ? (
        <Container>
          <Logo src={darkMode ? bpLogoWhite : bpLogo} alt="" />
          <Title>{`${t('Sign up successful')}!`}</Title>
        </Container>
      ) : (
        <>
          {handleActiveTab()}
          <Container>
            <Logo src={darkMode ? bpLogoWhite : bpLogo} alt="" />
            <Title>{t('Sign-Up')}</Title>
          </Container>
          {activeTab === SignUpTab.VERIFICATION && (
            <VerificationTab
              verificationDetails={verificationDetails}
              setVerificationDetails={setVerificationDetails}
              onForward={() => setActiveTab(SignUpTab.COMPANY_DETAILS)}
            />
          )}
          {activeTab === SignUpTab.COMPANY_DETAILS && (
            <CompanyDetailsTab
              companyDetails={companyDetails}
              verificationDetails={verificationDetails}
              setCompanyDetails={setCompanyDetails}
              onForward={() => setActiveTab(SignUpTab.BANK_DETAILS)}
              onBack={() => setActiveTab(SignUpTab.VERIFICATION)}
            />
          )}
          {activeTab === SignUpTab.BANK_DETAILS && (
            <BankDetailsTab
              bankDetails={bankDetails}
              setBankDetails={setBankDetails}
              onForward={() => setActiveTab(SignUpTab.PAYMENT_PLAN)}
              onBack={() => setActiveTab(SignUpTab.COMPANY_DETAILS)}
            />
          )}
          {activeTab === SignUpTab.PAYMENT_PLAN && (
            <PaymentPlanTab
              onBack={() => setActiveTab(SignUpTab.BANK_DETAILS)}
              onForward={(details: ISignUpPlanDetails) => {
                signUp({
                  company_email: verificationDetails.email,
                  company_data: companyDetails,
                  bank_data: bankDetails,
                  plan_data: details,
                });
              }}
              isLoading={isLoadingSignUp}
            />
          )}
        </>
      )}
      {isLoadingSignUp && <Loader />}
    </ContainerWrapper>
  );
};
