import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  addPaymentCondition,
  editPaymentCondition,
} from 'services/PaymentCondition/PaymentConditionService';
import { IAddEditPaymentConditionData } from 'types/PaymentCondition.types';

export const useAddPaymentCondition = () => {
  const { t } = useTranslation();
  return useMutation(
    (addPaymentConditionData: IAddEditPaymentConditionData) =>
      addPaymentCondition(addPaymentConditionData),
    {
      onSuccess: () => {
        toast.success(t('Payment condition added'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_PAYMENT_CONDITIONS]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_PAYMENT_CONDITION,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.ADD_PAYMENT_CONDITION,
    }
  );
};

export const useEditPaymentCondition = (paymentConditionId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editPaymentConditionData: IAddEditPaymentConditionData) =>
      editPaymentCondition(paymentConditionId, editPaymentConditionData),
    {
      onSuccess: () => {
        toast.success(t('Payment condition edited'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_PAYMENT_CONDITIONS]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_PAYMENT_CONDITION]);
        queryClient.resetQueries([
          ReactQueryKeys.GET_DEFAULT_PAYMENT_CONDITION,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_PAYMENT_CONDITION,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_PAYMENT_CONDITION,
    }
  );
};
