import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  deleteWatermarkPicture,
  getWatermarkPicture,
  updateWatermarkPicture,
} from 'services/Settings/SettingsService';
import { toast } from 'utils/toast';

interface IUseUpdateWatermarkPictureParams {
  file: any;
  opacity: string;
}

export const useUpdateWatermarkPicture = () => {
  const { t } = useTranslation();

  return useMutation(
    (params: IUseUpdateWatermarkPictureParams) => {
      const fileFormData = new FormData();
      if (params.file) fileFormData.append('file', params.file);
      fileFormData.append('opacity', params.opacity);
      return updateWatermarkPicture(fileFormData);
    },
    {
      onSuccess: () => {
        toast.success(t('Successfully updated watermark'), {
          className: ReactMutationKeys.UPDATE_WATERMARK,
        });
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPDATE_WATERMARK,
        });
      },
      mutationKey: ReactMutationKeys.UPDATE_WATERMARK,
    }
  );
};

export const useGetWatermarkPicture = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_WATERMARK_PICTURE],
    queryFn: () => {
      return getWatermarkPicture();
    },
  });

export const useDeleteWatermarkPicture = () => {
  const { t } = useTranslation();
  return useMutation(() => deleteWatermarkPicture(), {
    onSuccess: () => {
      toast.success(t('Successfully deleted watermark'));
      queryClient.invalidateQueries(ReactQueryKeys.GET_WATERMARK_PICTURE);
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_WATERMARK_PICTURE,
      });
    },
    mutationKey: ReactMutationKeys.DELETE_WATERMARK_PICTURE,
  });
};
