import { useEffect, useState } from 'react';
import {
  ButtonWrapper,
  Container,
  Headline,
  LeftSide,
  LeftWrapper,
  RightWrapper,
  RightSide,
  SelectWrapper,
  Text,
  WatermarkImage,
  SaveWrapper,
  TopContainer,
  TextWrapper,
  Row,
  SpinnerWrapper,
} from './WatermarkPage.styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { FileUploadModal } from 'components/Modal/FileUploadModal/FileUploadModal';
import { Select } from 'components/Select/Select';
import { opacityOptions, WatermarkFileType } from './constants';
import { Option } from 'components/Select/type';
import {
  useDeleteWatermarkPicture,
  useGetWatermarkPicture,
  useUpdateWatermarkPicture,
} from './hooks';
import { Danger } from 'components/Modal/Danger/Danger';
import { Modal } from 'components/Modal/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import Spinner from 'components/Spinner/Spinner';

const WatermarkPage = () => {
  const { t } = useTranslation();
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string | undefined>();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [opacity, setOpacity] = useState<Option>(opacityOptions[1]);

  const { mutate: updateWatermarkPicture, isSuccess } =
    useUpdateWatermarkPicture();

  const { data: watermarkData, isLoading } = useGetWatermarkPicture();
  const { mutate: deleteWatermarkPicture } = useDeleteWatermarkPicture();

  const handleImageUpload = async (uploadedFile: any) => {
    if (uploadedFile) {
      const image = URL.createObjectURL(uploadedFile);
      setFileName(uploadedFile.name);
      setFile(uploadedFile);
      setImageUrl(image);
    }
  };

  const saveWatermark = () => {
    updateWatermarkPicture({ file: file, opacity: opacity.value });
    setFile(null);
  };

  useEffect(() => {
    if (watermarkData) {
      setFileName(watermarkData.company_watermark);
      setImageUrl(watermarkData.company_watermark_url);
      const initialOpacity =
        opacityOptions.find(
          (option) =>
            option.value ===
            watermarkData?.company_watermark_opacity?.toString()
        ) || opacityOptions[1];
      setOpacity(initialOpacity);
    }
  }, [watermarkData]);

  useEffect(() => {
    if (isSuccess) {
      setIsUploadFileModalOpen(false);
    }
  }, [isSuccess]);

  return (
    <Container previewVisible={previewVisible}>
      <LeftSide previewVisible={previewVisible}>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <>
            <Headline>{t('PDF Watermark')}</Headline>
            <LeftWrapper>
              <TopContainer>
                <TextWrapper>
                  <Text>{t('Upload watermark picture')}</Text>
                  <Text>
                    {t('Optimal size: 2000 x 2000, optimal ratio: A4 (1:1.41)')}
                  </Text>
                </TextWrapper>
                {!previewVisible && imageUrl && (
                  <Button
                    label={t('Preview')}
                    secondary
                    width={'200rem'}
                    onClick={() => setPreviewVisible(true)}
                  />
                )}
              </TopContainer>
              <ButtonWrapper>
                <Row>
                  <Button
                    label={t('Upload Picture')}
                    secondary
                    width={'200rem'}
                    onClick={() => setIsUploadFileModalOpen(true)}
                  />
                  {fileName && (
                    <Tooltip
                      content={fileName.length > 15 ? fileName : undefined}
                    >
                      <Text>
                        {fileName.length > 15
                          ? `${fileName.substring(0, 15)}...`
                          : fileName}
                      </Text>
                    </Tooltip>
                  )}
                </Row>
                <Button
                  label={t('Delete Picture')}
                  red
                  width={'200rem'}
                  disabled={!imageUrl}
                  onClick={() => setIsDeleteModalOpen(true)}
                />
              </ButtonWrapper>
              <Text>{t('Change opacity')}</Text>
              <SelectWrapper>
                <Select
                  key={`${opacity.value}`}
                  name="opacitySelect"
                  placeholder=""
                  isMulti={false}
                  isDisabled={false}
                  defaultValue={opacity}
                  isSearchable={true}
                  onChange={(option: Option) => setOpacity(option)}
                  options={opacityOptions}
                />
              </SelectWrapper>
              <SaveWrapper>
                <Button
                  label={t('Save')}
                  disabled={!imageUrl}
                  primary
                  width={'200rem'}
                  onClick={() => saveWatermark()}
                />
              </SaveWrapper>
            </LeftWrapper>
          </>
        )}
      </LeftSide>
      {previewVisible && (
        <RightSide>
          <Headline>{t('Preview')}</Headline>
          <RightWrapper>
            {imageUrl && (
              <WatermarkImage
                alt="Watermark"
                src={imageUrl}
                opacity={opacity.value}
              />
            )}
          </RightWrapper>
        </RightSide>
      )}
      <FileUploadModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        onSubmit={(files: any) => handleImageUpload(files[0])}
        customAllowedTypes={WatermarkFileType}
        multiple={false}
      />

      <Modal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen}>
        <Danger
          submit={{
            onClick: () => {
              deleteWatermarkPicture();
              setImageUrl(null);
              setPreviewVisible(false);
              setIsDeleteModalOpen(false);
              setFileName('');
            },
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{ onClick: () => setIsDeleteModalOpen(false) }}
          title={t('Delete Watermark')}
          description={
            t(`Are you sure you want to delete this watermark`) + '?'
          }
        />
      </Modal>
    </Container>
  );
};

export default WatermarkPage;
