import * as Yup from 'yup';

export const activateUserSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Name is required'),
    last_name: Yup.string().required('Last name is required'),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });
};
