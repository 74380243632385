import * as Yup from 'yup';

export const editEmailCompanySettingsSchema = () => {
  return Yup.object().shape({
    sender_email: Yup.string()
      .email('Must be a valid email')
      .required('Sender email is required'),
    invoice_bcc_email_address: Yup.string()
      .email('Must be a valid email')
      .nullable(),
    sales_order_products_definitive_email_note: Yup.string().nullable(),
    bcc_email_address: Yup.string().email('Must be a valid email').nullable(),
    field_work_notification_email: Yup.string()
      .email('Must be a valid email')
      .nullable(),
    quotation_notification_email: Yup.string()
      .email('Must be a valid email')
      .nullable(),
    email_footer_email: Yup.string().nullable(),
  });
};
