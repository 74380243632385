import FullCalendar from '@fullcalendar/react'; // Must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarDateIntervalHeader from 'components/Calendar/CalendarDateIntervalHeader';
import Loader from 'components/Loader/Loader';
import DatePicker from 'components/DatePicker/DatePicker';
import Event from '../CustomEvent/Event';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import {
  CaretDown,
  Factory,
  Plus,
  User,
  UsersThree,
} from '@phosphor-icons/react';
import {
  CalendarWrapper,
  ButtonsWrapper,
  Header,
  WorkersButton,
  TodayButton,
  SelectInputWrapper,
  ResourcesButton,
  LoaderWrapper,
  RelativeDiv,
  ArrowWrapper,
  LeftSide,
  RightSide,
  Row,
  IconWrapper,
  ButtonWrapper,
  DatePickerWrap,
  AddAppointmentButton,
  AddButton,
  Text,
  TabWrapper,
  ClearText,
  ClearWrapper,
} from './PlanningTab.styled';
import moment, { Moment } from 'moment';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import {
  useEditDragAndDropAppointment,
  useFetchRequiredEntities,
  useGetAppointments,
  useUnselectTemporarySelectionOnEscapeClick,
  useUpdateCalendarShortDayNamesToResolvedLocale,
} from './hooks';
import { EventContentArg } from '@fullcalendar/core';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { formatAppointmentEventData } from '../helpers';
import { IAppointment } from 'types/Appointment.types';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import UsersModal from '../UsersModal/UsersModal';
import WorkersModal from '../WorkersModal/WorkersModal';
import ResourcesModal from '../ResourcesModal/ResourcesModal';
import NewAppointmentModal from '../NewAppointmentModal/NewAppointmentModal';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import ViewAppointmentModal from '../ViewAppointmentModal/ViewAppointmentModal';
import { EditAppointmentModal } from '../EditAppointmentModal/EditAppointmentModal';
import { PreviewDayAppointmentsModal } from 'components/Modal/PreviewDayAppointmentsModal/PreviewDayAppointmentsModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useLocation } from 'react-router-dom';
import LegendList from '../LegendList/LegendList';
import { useManageAndSaveFilters } from './hooks';
import { IPlanningTabFilter } from 'store/Filters/types';

interface IEditAppointmentDragAndDropDTO {
  appointmentId: string;
  date_from: string;
  date_to: string;
  is_drag_and_drop: boolean;
}

interface IProps {
  isCreatingAppointmentFromNavigationState: boolean;
  setIsCreatingAppointmentFromNavigationState: Dispatch<
    SetStateAction<boolean>
  >;
  appointmentTypeOptions: Option[];
  appointmentViewOptions: Option[];
  activeTab: IPlanningTabFilter;
}

const PlanningTab = ({
  isCreatingAppointmentFromNavigationState,
  setIsCreatingAppointmentFromNavigationState,
  appointmentTypeOptions,
  appointmentViewOptions,
  activeTab,
}: IProps) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const eventDropInfoRef = useRef<any>(null);
  const calendarRef = useRef<FullCalendar>(null);
  const [resetCounter, setResetCounter] = useState<number>(0);
  const [isUsersModalOpen, setIsUsersModalOpen] = useState<boolean>(false);
  const [isWorkersModalOpen, setIsWorkersModalOpen] = useState<boolean>(false);
  const [isResourcesModalOpen, setIsResourcesModalOpen] =
    useState<boolean>(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState<boolean>(false);
  const [isApplyFiltersModalOpen, setIsApplyFiltersModalOpen] =
    useState<boolean>(false);
  const [appointmentId, setAppointmentId] = useState<string>('');
  const [selectedStartDate, setSelectedStartDate] = useState<string>('');
  const [selectedEndDate, setSelectedEndDate] = useState<string>('');
  const [selectedTimeFrom, setSelectedTimeFrom] = useState<string>('');
  const [selectedTimeTo, setSelectedTimeTo] = useState<string>('');
  const [isNewAppointmentModalOpen, setIsNewAppointmentModalOpen] =
    useState<boolean>(false);
  const [isEditAppointmentModalOpen, setIsEditisNewAppointmentModalOpenOpen] =
    useState<boolean>(false);
  const [applyFilters, setApplyFilters] = useState<boolean>(false);
  const [dateForDaySummary, setDateForDaySummary] = useState<Moment | null>(
    null
  );
  const [isDragAndDropInProgress, setIsDragAndDropInProgress] =
    useState<boolean>(false);
  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterDate,
    setFilterDate,
    selectedWorkers,
    setSelectedWorkers,
    selectedResources,
    setSelectedResources,
    selectedUsers,
    setSelectedUsers,
    selectedAppointmentTypeOptions,
    setSelectedAppointmentTypeOptions,
    selectedAppointmentViewOption,
    setSelectedAppointmentViewOption,
  } = useManageAndSaveFilters(
    activeTab,
    appointmentTypeOptions,
    appointmentViewOptions
  );

  const { data, isFetching: isFetchingAppointments } = useGetAppointments(
    startDate,
    endDate,
    selectedUsers,
    selectedWorkers,
    selectedResources,
    selectedAppointmentTypeOptions
  );

  const {
    mutate: editAppointment,
    isSuccess: editAppointmentSuccess,
    isError: editAppointmentError,
  } = useEditDragAndDropAppointment();

  const [editedAppointment, setEditedAppointment] =
    useState<IEditAppointmentDragAndDropDTO>();

  const canCreateAppointment = useCan(Actions.CREATE_APPOINTMENT);

  useEffect(() => {
    if (editAppointmentSuccess) setIsDragAndDropInProgress(false);
  }, [editAppointmentSuccess]);

  useEffect(() => {
    if (editAppointmentError) {
      eventDropInfoRef?.current?.revert();
      setIsDragAndDropInProgress(false);
    }
  }, [editAppointmentError]);

  const handleAppointmentEventCardClick = (id: string) => {
    setAppointmentId(id);
    setIsEditisNewAppointmentModalOpenOpen(true);
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    return <Event content={eventInfo} />;
  };

  const appointmentEvents = formatAppointmentEventData(
    isDragAndDropInProgress
      ? data?.appointments.filter((appointment: IAppointment) => {
          if (
            editedAppointment &&
            appointment.id === +editedAppointment!.appointmentId
          ) {
            appointment.date_from = editedAppointment.date_from;
            appointment.date_to = editedAppointment.date_to;
          }
          return appointment;
        })
      : data?.appointments
  );

  useUnselectTemporarySelectionOnEscapeClick(calendarRef);

  const handleViewChange = (event: Option) => {
    const newView = event;
    setSelectedAppointmentViewOption(newView);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(newView.value);
    }
  };

  useUpdateCalendarShortDayNamesToResolvedLocale(
    startDate,
    endDate,
    setDateForDaySummary,
    handleViewChange,
    selectedAppointmentViewOption.value,
    setFilterDate
  );

  const { steps } = useRunTour(GuidePages.PLANNING);

  useEffect(() => {
    if (!filterDate || !calendarRef.current) return;
    calendarRef.current.getApi().gotoDate(filterDate);
  }, [filterDate]);

  const applyFilterUser = (userIds: string[]) => {
    setSelectedUsers(userIds);
  };

  const applyFilterWorker = (workersIds: string[]) => {
    setSelectedWorkers(workersIds);
  };

  const applyFilterResources = (resourcesIds: string[]) => {
    setSelectedResources(resourcesIds);
  };

  const handleSelectCalendarInterval = useCallback(
    (e: any) => {
      setSelectedStartDate(e.startStr.split('T')[0]);
      setSelectedTimeFrom(e.startStr.split('T')[1]);
      setSelectedEndDate(e.endStr.split('T')[0]);
      setSelectedTimeTo(e.endStr.split('T')[1]);
      if (
        !!selectedUsers.length ||
        !!selectedWorkers.length ||
        !!selectedResources.length
      ) {
        setIsApplyFiltersModalOpen(true);
      } else {
        setIsNewAppointmentModalOpen(true);
      }
    },
    [selectedUsers, selectedWorkers, selectedResources]
  );

  const handleModalConfirmation = async () => {
    const { isSuccess } = await executeFetch();

    if (isSuccess) {
      setIsNewAppointmentModalOpen(true);
    }
  };

  const {
    executeFetch,
    selectedUsersOptions,
    selectedWorkersOptions,
    selectedResourcesOptions,
  } = useFetchRequiredEntities({
    selectedUsers,
    selectedWorkers,
    selectedResources,
  });

  const clearFilters = () => {
    setSelectedUsers([]);
    setSelectedWorkers([]);
    setSelectedResources([]);
    setFilterDate('');
    handleViewChange(appointmentViewOptions[1]); // Week view
    setSelectedAppointmentTypeOptions(appointmentTypeOptions);
    setResetCounter((prev) => prev + 1);
  };

  return (
    <TabWrapper>
      <CalendarWrapper className="planning-step-5">
        <Header>
          <LeftSide>
            <Row>
              <TodayButton
                disabled={
                  moment().isSame(
                    moment(calendarRef.current?.getApi()?.view.activeStart),
                    'day'
                  ) ||
                  moment().isSame(
                    moment(
                      calendarRef.current?.getApi()?.view.activeEnd
                    ).subtract(1, 'day'),
                    'day'
                  )
                }
                onClick={() => {
                  setFilterDate('');
                  calendarRef.current?.getApi()?.today();
                }}
                label={t('Today')}
                secondary
              />
              <CalendarDateIntervalHeader
                startDate={startDate}
                endDate={endDate}
                next={() => {
                  setFilterDate('');
                  calendarRef.current?.getApi()?.next();
                }}
                prev={() => {
                  setFilterDate('');
                  calendarRef.current?.getApi()?.prev();
                }}
                selectedAppointmentViewOption={
                  selectedAppointmentViewOption.value
                }
              />
            </Row>
            <Row>
              <ButtonsWrapper className="planning-step-4">
                <ButtonWrapper>
                  <IconWrapper>
                    <Icon svg={User} size={20} color={COLORS.BLACK} />
                  </IconWrapper>
                  <WorkersButton
                    label={t('Users')}
                    width="200rem"
                    secondary
                    onClick={() => setIsUsersModalOpen(true)}
                    active={selectedUsers?.length}
                  />
                  <ArrowWrapper>
                    <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
                  </ArrowWrapper>
                </ButtonWrapper>
                <ButtonWrapper>
                  <IconWrapper>
                    <Icon svg={UsersThree} size={20} color={COLORS.BLACK} />
                  </IconWrapper>
                  <WorkersButton
                    label={t('Workers')}
                    width="200rem"
                    secondary
                    onClick={() => setIsWorkersModalOpen(true)}
                    active={selectedWorkers?.length}
                  />
                  <ArrowWrapper>
                    <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
                  </ArrowWrapper>
                </ButtonWrapper>
                <ButtonWrapper>
                  <IconWrapper>
                    <Icon svg={Factory} size={20} color={COLORS.BLACK} />
                  </IconWrapper>
                  <ResourcesButton
                    onClick={() => setIsResourcesModalOpen(true)}
                    label={t('Resources')}
                    width="200rem"
                    secondary
                    active={selectedResources?.length}
                  />
                  <ArrowWrapper>
                    <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
                  </ArrowWrapper>
                </ButtonWrapper>
              </ButtonsWrapper>
            </Row>
            <LegendList />
          </LeftSide>
          <RightSide className="planning-step-3">
            <Row>
              <DatePickerWrap>
                <DatePicker
                  pwId="date-field"
                  width="203rem"
                  date={moment(filterDate)}
                  setDate={(newValue: any) => {
                    setFilterDate(newValue?.format('YYYY-MM-DD'));
                    handleViewChange(appointmentViewOptions[0]); // Day view
                  }}
                  onAccept={(newDate: any) => {
                    if (newDate === null) {
                      setFilterDate('');
                    }
                  }}
                />
              </DatePickerWrap>
              <SelectInputWrapper>
                <CheckableSelect
                  placeholder={t('View')}
                  options={appointmentViewOptions}
                  translate
                  labelTransform={(string: string) =>
                    capitalizeFirstLetter(string)
                  }
                  onChange={(options: Option[]) => handleViewChange(options[0])}
                  value={
                    selectedAppointmentViewOption
                      ? selectedAppointmentViewOption
                      : appointmentViewOptions[1]
                  }
                />
              </SelectInputWrapper>
            </Row>
            <Row>
              <SelectInputWrapper>
                <CheckableSelect
                  key={`${resetCounter}`}
                  placeholder={t('Type')}
                  options={appointmentTypeOptions}
                  translate
                  isMulti
                  labelTransform={(string: string) =>
                    capitalizeFirstLetter(string)
                  }
                  onChange={(options: Option[]) =>
                    setSelectedAppointmentTypeOptions(options)
                  }
                  defaultValues={
                    selectedAppointmentTypeOptions
                      ? selectedAppointmentTypeOptions
                      : [appointmentTypeOptions[0]]
                  }
                />
              </SelectInputWrapper>
            </Row>
            <ClearWrapper>
              <ClearText onClick={() => clearFilters()}>
                {t('Clear filters')}
              </ClearText>
            </ClearWrapper>
          </RightSide>
        </Header>
        <RelativeDiv>
          {isFetchingAppointments ? (
            <LoaderWrapper>
              <Loader positionType="relative" />
            </LoaderWrapper>
          ) : null}
          <FullCalendar
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            eventClick={(e) => {
              handleAppointmentEventCardClick(e.event.id);
            }}
            select={(e) => {
              if (canCreateAppointment) {
                handleSelectCalendarInterval(e);
              }
            }}
            ref={calendarRef}
            firstDay={1} // Start from Monday
            allDaySlot={false} // Hide all day row
            slotMinTime="06:00:00"
            slotMaxTime="22:00:00"
            nowIndicator={true}
            droppable={true}
            eventDisplay="block"
            editable={canCreateAppointment}
            eventDurationEditable={false}
            selectable={true}
            selectMirror={true}
            events={appointmentEvents}
            slotEventOverlap={false}
            eventContent={(content: EventContentArg) =>
              renderEventContent(content)
            }
            plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
            initialView={selectedAppointmentViewOption.value}
            headerToolbar={false} // Hide built-in header
            dayHeaderFormat={(param) => {
              // Day header values
              return param.date.marker.toString().split(' ')[0];
            }}
            datesSet={(arg) => {
              // Used for custom header date interval
              setStartDate(moment(arg.view.activeStart));
              setEndDate(moment(arg.view.activeEnd));
            }}
            eventDrop={(info) => {
              eventDropInfoRef.current = info;
              setIsDragAndDropInProgress(true);
              const { start, end } = info.event._instance!.range;
              const dropedAppointment: IEditAppointmentDragAndDropDTO = {
                appointmentId: info.event._def.publicId,
                date_from: moment(start).utc().format('YYYY-MM-DDTHH:mm:ss'),
                date_to: moment(end).utc().format('YYYY-MM-DDTHH:mm:ss'),
                is_drag_and_drop: true,
              };
              editAppointment(dropedAppointment);
              setEditedAppointment(dropedAppointment);
            }}
            height="auto"
          />
        </RelativeDiv>
      </CalendarWrapper>
      <IntroductionGuide steps={steps} />

      {isUsersModalOpen && (
        <UsersModal
          isOpen={isUsersModalOpen}
          setIsOpen={setIsUsersModalOpen}
          onCancel={() => setIsUsersModalOpen(false)}
          setSelectedUsers={(usersIds) => applyFilterUser(usersIds)}
          selectedUsers={selectedUsers}
        />
      )}
      {isWorkersModalOpen && (
        <WorkersModal
          isOpen={isWorkersModalOpen}
          setIsOpen={setIsWorkersModalOpen}
          onCancel={() => setIsWorkersModalOpen(false)}
          setSelectedWorkers={(workersIds) => applyFilterWorker(workersIds)}
          selectedWorkers={selectedWorkers}
        />
      )}
      {isResourcesModalOpen && (
        <ResourcesModal
          isOpen={isResourcesModalOpen}
          setIsOpen={setIsResourcesModalOpen}
          onCancel={() => setIsResourcesModalOpen(false)}
          setSelectedResources={(resourcesIds) =>
            applyFilterResources(resourcesIds)
          }
          selectedResources={selectedResources}
        />
      )}
      {isNewAppointmentModalOpen && (
        <NewAppointmentModal
          isCreatingAppointmentFromNavigationState={
            isCreatingAppointmentFromNavigationState
          }
          setIsCreatingAppointmentFromNavigationState={
            setIsCreatingAppointmentFromNavigationState
          }
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          time_from={selectedTimeFrom}
          time_to={selectedTimeTo}
          isOpen={isNewAppointmentModalOpen}
          setIsOpen={setIsNewAppointmentModalOpen}
          type={state?.type}
          selectedUsersOptions={applyFilters ? selectedUsersOptions : []}
          selectedWorkersOptions={applyFilters ? selectedWorkersOptions : []}
          selectedResourcesOptions={
            applyFilters ? selectedResourcesOptions : []
          }
        />
      )}
      {isApplyFiltersModalOpen && (
        <YesOrNoModal
          setIsOpen={setIsApplyFiltersModalOpen}
          isOpen={isApplyFiltersModalOpen}
          onNo={() => {
            setApplyFilters(false);
            setIsApplyFiltersModalOpen(false);
            setIsNewAppointmentModalOpen(true);
          }}
          onYes={() => {
            handleModalConfirmation();
            setApplyFilters(true);
            setIsApplyFiltersModalOpen(false);
          }}
          title=""
          description={`${t('Apply current planning filters')}?`}
        />
      )}
      {isEditAppointmentModalOpen && (
        <ViewAppointmentModal
          isOpen={isEditAppointmentModalOpen}
          setIsOpen={setIsEditisNewAppointmentModalOpenOpen}
          id={appointmentId}
          onEdit={() => setIsEditingModalOpen(true)}
        />
      )}
      {isEditingModalOpen && (
        <EditAppointmentModal
          isOpen={isEditingModalOpen}
          setIsOpen={setIsEditingModalOpen}
          onCancel={() => setIsEditingModalOpen(false)}
          appointmentId={appointmentId}
        />
      )}
      {canCreateAppointment && (
        <AddAppointmentButton>
          <AddButton
            data-testid="new-appointment-button"
            isSideMenuCollapsed={isSideMenuCollapsed}
            onClick={(e: any) =>
              handleSelectCalendarInterval({
                startStr: moment().startOf('hour').toISOString().split('.')[0],
                endStr: moment()
                  .startOf('hour')
                  .add(1, 'hour')
                  .toISOString()
                  .split('.')[0],
              })
            }
          >
            <Icon
              svg={Plus}
              color={COLORS.PRIMARY}
              size={20}
              weight="regular"
            />
            <Text>{t('Add appointment')}</Text>
          </AddButton>
        </AddAppointmentButton>
      )}
      <PreviewDayAppointmentsModal
        dateForDaySummary={dateForDaySummary}
        setDateForDaySummary={setDateForDaySummary}
      />
    </TabWrapper>
  );
};

export default PlanningTab;
