import { Pagination } from 'components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { excludedTableDataKeys, translatableColumnFields } from './constants';
import {
  ActionCell,
  ActionHeaderCell,
  Body,
  BodyCell,
  BodyLabel,
  Footer,
  Header,
  HeaderCell,
  HeaderLabel,
  Row,
  Table,
} from './PaymentConditionsTable.styled';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import { ISimpleActionsTableConfig } from 'components/SimpleActionTable/SimpleActionsTable';

interface IPaymentConditionsTable {
  page: number;
  perPage: number;
  total: number;
  loadPage: (page: number) => void;
  tableData: any[];
  tableConfig: ISimpleActionsTableConfig;
  onEdit?: (param: any) => void;
  onDelete?: (param: any) => void;
  onMakeDefault?: (param: any) => void;
  animatedRows?: boolean;
  removedRows?: string[];
}

export const PaymentConditionsTable = ({
  page,
  perPage,
  total,
  loadPage,
  tableConfig,
  tableData,
  onEdit,
  onDelete,
  onMakeDefault,
  animatedRows,
  removedRows,
}: IPaymentConditionsTable) => {
  const { t } = useTranslation();

  return (
    <Table>
      <Header>
        {tableConfig?.rowConfigs.map((rowData, index) => (
          <HeaderCell key={index} $flexValue={rowData.flexValue}>
            <HeaderLabel>{t(rowData.header)}</HeaderLabel>
          </HeaderCell>
        ))}
        {onEdit && <ActionHeaderCell></ActionHeaderCell>}
        {onDelete && <ActionHeaderCell></ActionHeaderCell>}
        {onMakeDefault && (
          <ActionHeaderCell width={'170rem'}></ActionHeaderCell>
        )}
      </Header>
      <Body>
        {tableData?.map((rowData, rowIndex) => {
          return (
            <Row
              key={rowIndex}
              className={
                animatedRows && removedRows?.includes(rowData.entityId)
                  ? 'exit-row'
                  : ''
              }
            >
              {Object.keys(rowData)
                .filter((key) => !excludedTableDataKeys.includes(key))
                .map((cellData: any, cellIndex: any) => (
                  <BodyCell
                    key={cellIndex}
                    $flexValue={tableConfig?.rowConfigs[cellIndex]?.flexValue}
                  >
                    <BodyLabel
                      $isBlueColor={tableConfig?.rowConfigs[cellIndex]?.isBlue}
                    >
                      {translatableColumnFields.includes(cellData)
                        ? t(rowData[cellData])
                        : rowData[cellData]}
                    </BodyLabel>
                  </BodyCell>
                ))}

              {onEdit && (
                <ActionCell>
                  <Icon
                    svg={PencilSimple}
                    size={20}
                    color={COLORS.PRIMARY}
                    onClick={() => onEdit(rowData)}
                  />
                </ActionCell>
              )}
              {onDelete && (
                <ActionCell>
                  <Icon
                    svg={Trash}
                    size={20}
                    color={COLORS.RED_TRASH}
                    onClick={() => onDelete(rowData)}
                  />
                </ActionCell>
              )}
              {onMakeDefault && (
                <ActionCell width="170rem">
                  <Button
                    onClick={() => onMakeDefault(rowData)}
                    tableAlt={!rowData['excludedIsDefault']}
                    table={rowData['excludedIsDefault']}
                    width="150rem"
                    label={
                      rowData['excludedIsDefault']
                        ? t('Default')
                        : t('Set default')
                    }
                  />
                </ActionCell>
              )}
            </Row>
          );
        })}
      </Body>
      <Footer>
        {total > perPage && (
          <Pagination
            page={page}
            perPage={perPage}
            total={total}
            loadPage={loadPage}
          />
        )}
      </Footer>
    </Table>
  );
};
