import { COLORS, gapSm, marginSm } from 'assets/styled';
import { hoverLineIn } from 'assets/styled/commonStyles';
import styled, { css } from 'styled-components';

interface StyledProps {
  isExpanded: boolean;
  width: string;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;

export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const Label = styled.label`
  color: ${COLORS.BLACK};
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
`;

export const ShowMoreLabel = styled.label`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
  text-decoration-thickness: 1rem;
  text-underline-position: under;
  cursor: pointer;
  user-select: none;
`;

export const ValueWrapperRelative = styled.div`
  position: relative;
  margin-right: ${marginSm};
  height: min-content;
`;

export const EditLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  ${hoverLineIn}
`;

export const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 12rem;
  height: 12rem;
  right: -20rem;
  top: 1rem;
`;
export const ContentWrapper = styled.div`
  display: flex;
  margin-top: ${(props: StyledProps) => (props.isExpanded ? '20rem' : 0)};
  max-height: ${(props: StyledProps) => (props.isExpanded ? '250rem' : 0)};
  opacity: ${(props: StyledProps) => (props.isExpanded ? 1 : 0)};
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    margin-top 0.3s ease-in-out;
  overflow: hidden;
  font-size: 14rem;
  color: ${COLORS.BLACK};
`;

export const EditModeWrapper = styled.div`
  width: 100%;
  color: ${COLORS.BLACK};
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${marginSm};
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${gapSm};
`;
