import {
  borderRadiusSm,
  COLORS,
  marginLg,
  marginMd,
  marginXs,
} from 'assets/styled';
import Button from 'components/Button/Button';
import CustomSelect from 'components/Select/FormikSelect';
import { Field } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: ${marginLg};
  margin-left: ${marginLg};
  margin-right: ${marginLg};
`;

export const AddCustomerWrapper = styled.div`
  display: flex;
  width: 750rem;
`;

export const CustomerDetailsContainer = styled.div`
  margin-top: ${marginMd};
  padding: 30rem;
  width: 750rem;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
`;

export const CustomerDetailsLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-bottom: ${marginMd};
`;

export const Row = styled.div`
  margin-top: ${marginXs};
  display: flex;
  justify-content: space-between;
  gap: 30rem;
`;

export const StyledCustomSelect = styled(CustomSelect)`
  height: 100%; // Prevent error message from overflowing
  width: 100%;
`;

export const StyledSelect = styled(Field)`
  width: 100%;
  padding-right: 15rem;
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonCreate = styled(Button)`
  margin-left: ${marginMd};
`;

export const SelectWrapper = styled.div`
  width: 50%;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: calc(50% - 15rem);
  width: 100%;
  margin-bottom: 14rem;
`;
