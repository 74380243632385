import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { activateUser } from 'services/SignUp/SignUpService';
import { ISignUpActivationDTO } from './types';

export const useActivateUser = () => {
  const { t } = useTranslation();
  return useMutation((data: ISignUpActivationDTO) => activateUser(data), {
    onSuccess: () => {
      toast.success(t('Account activated'), {
        toastId: ReactMutationKeys.SIGN_UP_ACTIVATE_USER,
      });
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.SIGN_UP_ACTIVATE_USER,
      });
    },
    mutationKey: ReactMutationKeys.SIGN_UP_ACTIVATE_USER,
  });
};
