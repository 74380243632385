import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
  isSideMenuCollapsed: boolean;
  isTablet: boolean;
  routerWithoutPaddingBottom: boolean;
  isOnAlternativeContactsRoute?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 50rem;
  padding-right: 50rem;
  padding-bottom: 0;
  margin-left: 265rem;

  ${respondTo.bigTablet`
    margin-left: 0rem;
    padding: 0 30rem;
  `}
`;

export const HeaderWrapper = styled.div`
  width: calc(100vw - 265rem - 265rem);
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  padding-right: 100rem;
  height: 185rem;
  background-color: ${COLORS.BACKGROUND_COLOR};
  justify-content: center;

  ${respondTo.bigTablet`
    padding-right: 65rem;
  `}

  ${(props: StyledProps) => {
    if (props.isOnAlternativeContactsRoute) {
      return css`
        height: 230rem;
      `;
    }
  }}

  ${(props: StyledProps) =>
    props.isSideMenuCollapsed
      ? props.isTablet
        ? css`
            width: calc(100vw - 115rem);
          `
        : css`
            width: calc(100vw - 115rem - 265rem);
          `
      : props.isTablet
      ? css`
          width: calc(100vw - 265rem);
        `
      : css`
          width: calc(100vw - 265rem - 265rem);
        `}
`;

export const RouterWrapper = styled.div`
  margin-top: 185rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50rem;
  flex-direction: column;

  ${(props: StyledProps) => {
    if (props.isOnAlternativeContactsRoute) {
      return css`
        margin-top: 230rem;
      `;
    }
  }}

  ${(props: StyledProps) => {
    if (props.routerWithoutPaddingBottom) {
      return css`
        padding-bottom: 0rem;
      `;
    }
  }}
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10rem;
  justify-content: space-between;
`;

export const H3 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24rem;
  padding-right: 30rem;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  gap: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonContainerAlt = styled(ButtonContainer)`
  margin-top: 20rem;
`;

export const ButtonWrapper = styled.div`
  gap: 20rem;
  display: flex;
`;

export const MollieCustomerSpan = styled.span`
  margin-left: ${marginSm};
  color: ${COLORS.PRIMARY};
  font-size: 18rem;
`;
