import {
  COLORS,
  borderRadiusSm,
  gapXs,
  marginMd,
  paddingMd,
} from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isRed: string;
}

export const Card = styled.div`
  height: min-content;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardTitle = styled.div`
  padding-bottom: ${paddingMd};
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const Label = styled.label`
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
  word-break: break-all;
`;

export const LabelValue = styled.label`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  word-break: break-all;
  ${(props: StyledProps) =>
    props.isRed &&
    css`
      color: ${COLORS.RED_100};
    `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gapXs};
`;

export const MarginTop30 = styled.div`
  margin-top: ${marginMd};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 50%;
`;

export const TrashIcon = styled.img`
  cursor: pointer;
  filter: hue-rotate(135deg);
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
`;
