import { FwSingleAppointmentRoutes } from 'navigation/FwSingleAppointment/FwSingleAppointment.routes';
import { ISubMenuItem } from '../../../components/Layout/SubSideMenu/type';

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentGeneral.path,
  },
  {
    text: 'Products & Services',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentProducts.path,
  },
  {
    text: 'Files',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentFiles.path,
  },
  {
    text: 'Comments',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentComments.path,
  },
  {
    text: 'Reports',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentReports.path,
  },
  {
    text: 'Location',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentLocation.path,
  },
  {
    text: 'Timesheet',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentTimesheet.path,
  },
];

export const generalAppointmentSubMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentGeneral.path,
  },
  {
    text: 'Files',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentFiles.path,
  },
  {
    text: 'Comments',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentComments.path,
  },
  {
    text: 'Location',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentLocation.path,
  },
  {
    text: 'Timesheet',
    route: FwSingleAppointmentRoutes.FwSingleAppointmentTimesheet.path,
  },
];

export enum IntervalEntityEnum {
  User = 'User',
  Worker = 'Worker',
  Resource = 'Resource',
}
