import * as Yup from 'yup';

export const editGeneralCompanySettingsSchema = () => {
  return Yup.object().shape({
    tax_rate: Yup.number()
      .typeError('Tax rate must be a number')
      .max(50, 'Tax rate should have maximum 100 characters'),
    working_hours_rate: Yup.number().typeError(
      'Working hours rate must be a number'
    ),
    days_valid_quotation: Yup.number().typeError(
      'Days valid quotation must be a number'
    ),
    days_valid_invoice: Yup.number().typeError(
      'Days valid invoice must be a number'
    ),
    default_new_quotation_type_id: Yup.number().typeError(
      'Days valid invoice must be a number'
    ),
  });
};
