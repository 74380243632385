import Table from 'components/Table/Table';
import { formatPurchaseInvoiceTableData } from 'components/Table/tableDataFormatter';
import { RoutesConfig } from 'navigation/routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IPurchaseInvoice,
  ISecondaryPurchaseInvoiceTableDTO,
} from 'types/Invoice.types';
import { PURCHASE_INVOICES_PER_PAGE, tableConfig } from './constants';
import {
  useGetPurchaseOrderId,
  useGetSinglePurchaseOrderInvoices,
} from './hooks';
import {
  Container,
  LoaderWrapper,
  NoContentLabel,
} from './SinglePurchaseOrderInvoicesPage.styled';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SinglePurchaseOrderInvoicesPage = () => {
  const navigate = useNavigate();
  const purchaseOrderId = useGetPurchaseOrderId();
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const { isLoading, data } = useGetSinglePurchaseOrderInvoices(
    typeof purchaseOrderId === 'string' ? purchaseOrderId : '',
    page,
    PURCHASE_INVOICES_PER_PAGE
  );

  const handleViewButtonClick = (
    rowData: ISecondaryPurchaseInvoiceTableDTO
  ) => {
    rowData.isCreditInvoice === 'Yes'
      ? RoutesConfig.SinglePurchaseCreditInvoice.fullPath.replace(
          ':id/*',
          rowData.navigationID
        )
      : navigate(
          RoutesConfig.SinglePurchaseInvoice.fullPath.replace(
            ':id/*',
            rowData.navigationID
          )
        );
  };

  let tableData: ISecondaryPurchaseInvoiceTableDTO[] = [];

  if (data?.purchase_invoices) {
    tableData = formatPurchaseInvoiceTableData(
      data.purchase_invoices
        .map((purchase_invoice: IPurchaseInvoice[]) => {
          return purchase_invoice;
        })
        .flat(),
      currencySymbol
    );
  }

  return (
    <Container>
      {tableData.length ? (
        <Table
          isLoading={isLoading}
          page={page}
          perPage={PURCHASE_INVOICES_PER_PAGE}
          total={data?.total}
          tableData={tableData}
          tableConfig={tableConfig}
          hasExtraAction={true}
          loadPage={(newPage) => setPage(newPage)}
          onViewButtonClick={handleViewButtonClick}
        />
      ) : (
        <>
          {isLoading && (
            <LoaderWrapper>
              <Spinner size={50} color={COLORS.PRIMARY} />
            </LoaderWrapper>
          )}
          {!isLoading && (
            <NoContentLabel>
              {t('There are currently no Invoices on this Purchase order')}
            </NoContentLabel>
          )}
        </>
      )}
    </Container>
  );
};

export default SinglePurchaseOrderInvoicesPage;
