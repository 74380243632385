import { IFrontendSettings } from 'types/Common.types';
import {
  SET_IS_SESSION_EXPIRED,
  SET_IS_UPDATE_AVAILABLE,
  TOGGLE_SIDE_MENU,
  SET_IS_FIELD_WORKER_VIEW,
  SET_IS_DARK_MODE,
  SET_IS_MOBILE_APP,
  SET_IS_SOCKET_CONNECTED,
  SET_RUN_TOUR,
  SET_COMMON_DATA,
  SET_IS_LOADING_SCREEN_SHOWN,
  SET_MARK_AS_CONNECTED_INVOICE_ID,
  SET_FRONTEND_SETTINGS,
  SET_IS_SAVING_PLANNING_PAGE_FILTERS_AND_LOGGING_OUT,
} from '../constants';

export const setIsFieldWorkerView = (isFieldWorkerView: boolean) => ({
  type: SET_IS_FIELD_WORKER_VIEW,
  payload: isFieldWorkerView,
});

export const setDarkMode = (darkMode: boolean) => ({
  type: SET_IS_DARK_MODE,
  payload: darkMode,
});

export const setIsSessionExpired = (isSessionExpired: boolean) => ({
  type: SET_IS_SESSION_EXPIRED,
  payload: isSessionExpired,
});

export const setIsUpdateAvailable = (isUpdateAvailable: boolean) => ({
  type: SET_IS_UPDATE_AVAILABLE,
  payload: isUpdateAvailable,
});

export const toggleSideMenu = (boolean?: boolean) => ({
  type: TOGGLE_SIDE_MENU,
  payload: boolean,
});

export const setIsMobileApp = (isMobileApp: boolean) => ({
  type: SET_IS_MOBILE_APP,
  payload: isMobileApp,
});

export const setIsSocketConnected = (boolean: boolean) => ({
  type: SET_IS_SOCKET_CONNECTED,
  payload: boolean,
});

export const setRunTour = (boolean: boolean) => ({
  type: SET_RUN_TOUR,
  payload: boolean,
});
export interface ISetCommonDataPayload {
  countries: any;
  notificationTypes: any;
  quotationStatuses: any;
  purchaseOrderStatuses: any;
  salesOrderStatuses: any;
  salesInvoiceStatuses: any;
  purchaseInvoiceStatuses: any;
  quotationActions: any;
  customerTitles: any;
  twinfieldTransactionStatuses: any;
  currencies: any;
}

export const setCommonData = (commonData: ISetCommonDataPayload) => ({
  type: SET_COMMON_DATA,
  payload: commonData,
});

export const setIsLoadingScreenShown = (boolean: boolean) => ({
  type: SET_IS_LOADING_SCREEN_SHOWN,
  payload: boolean,
});

/*
 * Setting the invoice ID triggers the "Mark as connected" modal to open for that sales invoice
 */
export const setMarkAsConnectedInvoiceId = (
  invoiceId: number | string | null
) => ({
  type: SET_MARK_AS_CONNECTED_INVOICE_ID,
  payload: invoiceId,
});

/*
 * After updating the frontend settings on the backend, also update them locally, so requests dont trigger until there is an actual local change in frontendSettings.planning_page_filters (handled in UploadPlanningPageFiltersProvider)
 */
export const setFrontendSettings = (frontendSettings: IFrontendSettings) => ({
  type: SET_FRONTEND_SETTINGS,
  payload: frontendSettings,
});

/*
 * Save planning page filters before logout, showing loading screen during the process
 */
export const setIsSavingPlanningPageFiltersAndLoggingOut = (bool: boolean) => ({
  type: SET_IS_SAVING_PLANNING_PAGE_FILTERS_AND_LOGGING_OUT,
  payload: bool,
});
