import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import {
  Column,
  ContentContainer,
  ContentWrapper,
  EditIcon,
  EditLabel,
  FormWrapper,
  Heading,
  LabelKey,
  LabelKeyWrapper,
  LabelValue,
  LeftSide,
  RightSide,
  RowSpaceBetween,
  Subtitle,
  ValueWrapperRelative,
} from './CompanyConfigurationCard.styled';
import { PencilSimple } from '@phosphor-icons/react';
import { tooltipContent } from './constants';
import Tooltip from 'components/Tooltip/Tooltip';

interface ICompanyConfigurationCardProps {
  cardTitle: string;
  leftSubtitle?: string;
  rightSubtitle?: string;
  leftData: object;
  rightData: object;
  onEditClick?: () => void;
  isLoading?: boolean;
  extraLeftData?: object;
  extraRightData?: object;
  extraLeftSubtitle?: string;
  extraRightSubtitle?: string;
  companyId?: number;
}

export const CompanyConfigurationCard = ({
  cardTitle,
  leftSubtitle,
  rightSubtitle,
  leftData,
  rightData,
  extraLeftData,
  extraLeftSubtitle,
  onEditClick,
}: ICompanyConfigurationCardProps) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <RowSpaceBetween>
        <Heading>{cardTitle}</Heading>
        <ValueWrapperRelative>
          <EditLabel onClick={onEditClick}>{t('Edit')}</EditLabel>
          <EditIcon>
            <Icon
              onClick={onEditClick}
              svg={PencilSimple}
              color={COLORS.PRIMARY}
              size={15}
            />
          </EditIcon>
        </ValueWrapperRelative>
      </RowSpaceBetween>

      <ContentWrapper>
        <LeftSide>
          <div>
            {leftSubtitle && <Subtitle>{leftSubtitle.toUpperCase()}</Subtitle>}
            <FormWrapper>
              {Object.keys(leftData).map((dataKey: string, index: number) => (
                <Column key={index}>
                  <LabelKeyWrapper>
                    <Tooltip content={t(tooltipContent[dataKey])}>
                      <LabelKey>{t(dataKey)}</LabelKey>
                    </Tooltip>
                  </LabelKeyWrapper>

                  {leftData[dataKey] && (
                    <div>
                      <LabelValue>{leftData[dataKey]}</LabelValue>
                    </div>
                  )}
                </Column>
              ))}
            </FormWrapper>
          </div>
          {extraLeftData && (
            <div>
              <Subtitle>{extraLeftSubtitle?.toUpperCase()}</Subtitle>
              <FormWrapper>
                {Object.keys(extraLeftData).map(
                  (dataKey: string, index: number) => (
                    <Column key={index}>
                      <LabelKey>{t(dataKey)}</LabelKey>
                      {extraLeftData[dataKey] && (
                        <div>
                          <LabelValue>{extraLeftData[dataKey]}</LabelValue>
                        </div>
                      )}
                    </Column>
                  )
                )}
              </FormWrapper>
            </div>
          )}
        </LeftSide>

        <RightSide>
          <div>
            {rightSubtitle && (
              <Subtitle>{rightSubtitle.toUpperCase()}</Subtitle>
            )}
            <FormWrapper>
              {Object.keys(rightData).map((dataKey: string, index: number) => (
                <Column key={index}>
                  <LabelKeyWrapper>
                    <Tooltip content={t(tooltipContent[dataKey])}>
                      <LabelKey>{t(dataKey)}</LabelKey>
                    </Tooltip>
                  </LabelKeyWrapper>

                  {rightData[dataKey] && (
                    <div>
                      <LabelValue>{rightData[dataKey]}</LabelValue>
                    </div>
                  )}
                </Column>
              ))}
            </FormWrapper>
          </div>
        </RightSide>
      </ContentWrapper>
    </ContentContainer>
  );
};
