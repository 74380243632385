import { useEffect, useRef, useState } from 'react';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface ITimePickerProps {
  pwId?: string;
  width?: string;
  time: any;
  setTime: any;
  disabled?: boolean;
}

const TimePicker = ({
  pwId,
  width,
  time,
  setTime,
  disabled,
}: ITimePickerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);

  const minTime = moment().hours(6).minutes(0).seconds(0);
  const maxTime = moment().hours(22).minutes(0).seconds(0);

  const { isDesktop } = useBreakpointFlag();

  const adjustTimeIfNeeded = () => {
    if (!time || isOptionsOpen) return;

    if (time.isBefore(minTime)) {
      setTime(minTime);
    } else if (time.isAfter(maxTime)) {
      setTime(maxTime);
    }
  };

  useEffect(() => {
    const handleFocusOut = () => {
      setTimeout(() => {
        if (
          containerRef.current &&
          !containerRef.current.contains(document.activeElement)
        ) {
          adjustTimeIfNeeded();
        }
      }, 0);
    };

    document.addEventListener('focusout', handleFocusOut);

    return () => {
      document.removeEventListener('focusout', handleFocusOut);
    };
  }, [time, minTime, maxTime, setTime]);

  return (
    <div ref={containerRef} style={{ width: width }}>
      <MuiTimePicker
        disabled={disabled}
        data-testid={pwId}
        value={time || null}
        onChange={(newValue) => setTime(newValue)}
        openTo="hours"
        views={['hours', 'minutes']}
        minTime={minTime}
        maxTime={maxTime}
        open={isOptionsOpen}
        onOpen={() => setIsOptionsOpen(true)}
        onClose={() => setIsOptionsOpen(false)}
        format="HH:mm"
        ampm={false}
        sx={{
          maxWidth: width ? width : '200rem',
          width: '100%',
          '& .MuiInputBase-input': {
            height: isDesktop ? '20rem' : '15rem',
            fontSize: isDesktop ? '14px' : '13px',
          },
          '& svg': {
            fontSize: isDesktop ? '25rem' : '20rem',
          },
        }}
        slotProps={{
          textField: {
            size: 'small',
          },
        }}
      />
    </div>
  );
};

export default TimePicker;
