import { marginMd } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: ${marginMd};
`;
