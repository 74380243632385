import { ICreateQuotationCustomerDetails } from './CustomerTab/constants';
import { ICreateQuotationOrderDetails } from './OrderTab/constants';
import { ICreateQuotationProductFormDTO } from './ProductTab/constants';
import {
  IQuotation,
  IQuotationLine,
  QuotationTypeIdsEnum,
} from 'types/Quotations.types';
import { DiscountType } from 'types/Discount.types';
import { ICompanySettings } from 'types/Company.types';
import { IPaymentCondition } from 'types/PaymentCondition.types';

// Triggered on page load and when switching between "New customer" and "Existing customer"
export const getInitialCustomerDetailsData = (
  prepopulateQuotationData: any,
  initialCountryCCA2: string,
  showEmptyCustomerValues = false
): ICreateQuotationCustomerDetails => {
  if (prepopulateQuotationData && !showEmptyCustomerValues) {
    return {
      existingCustomer: prepopulateQuotationData.customer,
      name: prepopulateQuotationData.customer?.name,
      last_name: prepopulateQuotationData.customer?.last_name,
      gender: prepopulateQuotationData.customer?.gender,
      email: prepopulateQuotationData.customer?.email,
      phone: prepopulateQuotationData.customer?.phone,
      country: prepopulateQuotationData.customer?.address.country.cca2,
      street: prepopulateQuotationData.customer?.address.street,
      code: prepopulateQuotationData.customer?.address.zip_code,
      city: prepopulateQuotationData.customer?.address.city,
      house_number: prepopulateQuotationData.customer?.address?.house_number,
      company_name: prepopulateQuotationData.customer?.company_name,
      vat_number: prepopulateQuotationData.customer?.vat_number,
      different_shipping_address:
        prepopulateQuotationData.different_shipping_address,
      shipping_data: {
        first_name: prepopulateQuotationData.shipping_data?.first_name,
        last_name: prepopulateQuotationData.shipping_data?.last_name,
        phone: prepopulateQuotationData.shipping_data?.phone,
        country: prepopulateQuotationData.shipping_data?.address.country.cca2,
        street: prepopulateQuotationData.shipping_data?.address.street,
        city: prepopulateQuotationData.shipping_data?.address.city,
        zip_code: prepopulateQuotationData.shipping_data?.address.zip_code,
        house_number:
          prepopulateQuotationData.shipping_data?.address.house_number,
        company_name: prepopulateQuotationData.shipping_data?.company_name,
        email: prepopulateQuotationData.shipping_data?.email,
        title: prepopulateQuotationData.shipping_data?.title,
      },
      isShippingDataFormShown:
        prepopulateQuotationData.different_shipping_address,
      title: prepopulateQuotationData.customer?.title,
      hasAlternativeInvoiceEmail:
        !!prepopulateQuotationData.customer_snapshot?.alternative_invoice_email,
      alternative_invoice_email:
        prepopulateQuotationData.customer_snapshot?.alternative_invoice_email,
      alternativeContact:
        prepopulateQuotationData.customer_snapshot?.alternative_contact,
    };
  } else {
    return {
      existingCustomer: null,
      name: '',
      gender: '',
      email: '',
      phone: '',
      country: initialCountryCCA2,
      street: '',
      code: '',
      last_name: '',
      city: '',
      house_number: '',
      company_name: '',
      vat_number: '',
      shipping_data: {
        first_name: '',
        last_name: '',
        phone: '',
        country: '',
        street: '',
        city: '',
        zip_code: '',
        house_number: '',
        company_name: '',
        email: '',
        title: '',
      },
      title: '',
      hasAlternativeInvoiceEmail: false,
      alternative_invoice_email: '',
      alternativeContact: undefined,
    };
  }
};

const mapObjectFieldsIntoArray = (attributesObject: object) => {
  return Object.keys(attributesObject).map((attributeKey) =>
    attributesObject ? attributesObject[attributeKey] : null
  );
};

export const getInitialProductsData = (prepopulateQuotationData: any) => {
  if (prepopulateQuotationData) {
    return prepopulateQuotationData.quotation_lines
      .filter(
        (quotation_line: IQuotationLine) => !quotation_line.working_hours_line
      )
      .map((quotation_line: IQuotationLine) => {
        const productFormDTOObj: ICreateQuotationProductFormDTO = {
          supplier: quotation_line.supplier,
          product: quotation_line.stock_item_line
            ? {
                name: quotation_line.stock_item?.name,
              }
            : {
                name: quotation_line.product.name,
                id: quotation_line.product.default_product_id,
              },
          placement: quotation_line.placement,
          description: quotation_line.description,
          salesPrice: quotation_line.stock_item_line
            ? quotation_line.product_sales_price.toString()
            : quotation_line.product?.price,
          purchasePrice: quotation_line.stock_item_line
            ? quotation_line.product_purchase_price?.toString()
            : quotation_line.product?.purchase_price,
          quantity: quotation_line.quantity,
          discount: quotation_line.discount.amount.toString(),
          discountType: quotation_line.discount.type,
          attributes: quotation_line.product?.attributes
            ? mapObjectFieldsIntoArray(quotation_line.product.attributes)
            : [],
          logyxConfigurationIntent: quotation_line.product
            ?.logyx_configuration_intent
            ? quotation_line.product?.logyx_configuration_intent
            : null,
          isStockItem: !!quotation_line.stock_item_line,
          stockItemId:
            quotation_line.stock_item_line && quotation_line.stock_item?.id
              ? Number(quotation_line.stock_item.id)
              : undefined,
          ...(quotation_line.stock_item_line && {
            stockItemType: quotation_line.stock_item?.type.name,
          }),
        };

        if (quotation_line.product?.attributes) {
          for (const addedAttributeKey of Object.keys(
            quotation_line.product.attributes
          )) {
            productFormDTOObj.product[addedAttributeKey] =
              quotation_line.product.attributes[addedAttributeKey];
          }
        }

        return productFormDTOObj;
      });
  } else {
    return [];
  }
};

export const calculateInitialDownpayment = (
  totalSalesPrice: string,
  downpaymentThreshold?: number,
  downpaymentPercentage?: number
) => {
  if (!downpaymentThreshold && !downpaymentPercentage) {
    return 0;
  }
  if (Number(totalSalesPrice) > Number(downpaymentThreshold)) {
    return (Number(totalSalesPrice) * Number(downpaymentPercentage)) / 100;
  } else {
    return 0;
  }
};

export const getInitialOrderDetailsData = (
  prepopulateQuotationData: any,
  companySettings: ICompanySettings
): ICreateQuotationOrderDetails => {
  if (prepopulateQuotationData) {
    const quotationWorkingHourLine: IQuotationLine =
      prepopulateQuotationData.quotation_lines.find(
        (line: IQuotationLine) => line.working_hours_line
      );

    return {
      isDraft: false,
      reference: prepopulateQuotationData.reference,
      downPayment: prepopulateQuotationData.downpayment,
      daysValid: companySettings.days_valid_quotation.toString(),
      workingHours: quotationWorkingHourLine
        ? quotationWorkingHourLine.quantity
        : '0',
      workingHoursRate:
        quotationWorkingHourLine && quotationWorkingHourLine.working_hours_rate
          ? quotationWorkingHourLine.working_hours_rate.toString()
          : '0',
      workingHoursDiscount: quotationWorkingHourLine
        ? quotationWorkingHourLine.discount.amount.toString()
        : '0',
      workingHoursDiscountType:
        quotationWorkingHourLine && quotationWorkingHourLine.discount
          ? quotationWorkingHourLine.discount.type
          : DiscountType.PERCENTAGE,
      discount: prepopulateQuotationData.discount.amount,
      discountType: prepopulateQuotationData.discount.type,
      labels: prepopulateQuotationData.labels,
      paymentConditionText: prepopulateQuotationData.payment_condition,
      downpaymentConditionText: prepopulateQuotationData.downpayment_condition,
      paymentConditionId: prepopulateQuotationData.payment_condition_id,
    };
  } else {
    return {
      isDraft: companySettings.default_new_quotation_type_id
        ? Number(companySettings.default_new_quotation_type_id) ===
          QuotationTypeIdsEnum.DRAFT
        : true,
      reference: '',
      downPayment: '',
      daysValid: companySettings.days_valid_quotation.toString(),
      workingHours: '0',
      workingHoursRate: companySettings.working_hours_rate.toString(),
      workingHoursDiscount: '0',
      workingHoursDiscountType: DiscountType.PERCENTAGE,
      discount: '0',
      discountType: DiscountType.PERCENTAGE,
      labels: [],
      paymentConditionText: '',
      downpaymentConditionText: '',
      paymentConditionId: '',
    };
  }
};

export const getPaymentConditionText = (
  prepopulateQuotationData: IQuotation | undefined,
  paymentCondition: IPaymentCondition | undefined
) => {
  if (
    prepopulateQuotationData &&
    prepopulateQuotationData.payment_condition_id
  ) {
    return prepopulateQuotationData.payment_condition;
  }
  return paymentCondition?.text || '';
};

export const getDownPaymentConditionText = (
  prepopulateQuotationData: IQuotation | undefined,
  paymentCondition: IPaymentCondition | undefined
) => {
  if (
    prepopulateQuotationData &&
    prepopulateQuotationData.payment_condition_id
  ) {
    return prepopulateQuotationData.payment_condition;
  }
  return paymentCondition?.alt_text || paymentCondition?.text || '';
};
