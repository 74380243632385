import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Label,
  RowSpaceBetween,
  Title,
  TitleContainer,
} from './EditPrefixesCompanySettingsModal.styled';
import { Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { editPrefixesCompanySettingsSchema } from './validation';
import { toast } from 'react-toastify';
import { transformEmptyValues } from 'utils/transformEmptyValues';
import { useEditCompanySettings } from '../hooks';
import { IEditCompanySettingsDTO } from 'types/Admin.types';

interface IEditPrefixesCompanySettingsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  settingsData: any;
  companyId: string;
}
export const EditPrefixesCompanySettingsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  settingsData,
  companyId,
}: IEditPrefixesCompanySettingsModalProps) => {
  const { t } = useTranslation();
  const {
    mutate: editCompanySettings,
    isSuccess,
    isLoading,
  } = useEditCompanySettings(Number(companyId));

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Company settings updated'));
      onCancel();
    }
  }, [isSuccess]);

  const handleEditCompanySettings = (values: any) => {
    const editCompanySettingsDTO: IEditCompanySettingsDTO = {
      quotation_prefix: values.quotation_prefix,
      sales_order_prefix: values.sales_order_prefix,
      purchase_order_prefix: values.purchase_order_prefix,
      invoice_prefix: values.invoice_prefix,
      purchase_invoice_prefix: values.purchase_invoice_prefix,
      customer_prefix: values.customer_prefix,
      stock_items_prefix: values.stock_items_prefix,
      suppliers_prefix: values.suppliers_prefix,
    };
    editCompanySettings(editCompanySettingsDTO);
  };

  const getInitialValues = () => {
    return {
      quotation_prefix: settingsData?.quotation_prefix as string,
      sales_order_prefix: settingsData?.sales_order_prefix as string,
      purchase_order_prefix: settingsData?.purchase_order_prefix as string,
      invoice_prefix: settingsData?.invoice_prefix as string,
      purchase_invoice_prefix: (settingsData?.purchase_invoice_prefix ||
        settingsData?.invoice_prefix) as string,
      customer_prefix: settingsData?.customer_prefix as string,
      stock_items_prefix: settingsData?.stock_items_prefix as string,
      suppliers_prefix: settingsData?.suppliers_prefix as string,
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        overflow: 'visible',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Company prefixes settings')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={getInitialValues()}
          validationSchema={editPrefixesCompanySettingsSchema}
        >
          {({ values, setFieldValue, handleBlur, errors, isValid }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };
            return (
              <Container>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Quotation prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['quotation_prefix']
                          ? errors['quotation_prefix']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'quotation_prefix');
                      }}
                      height={'43rem'}
                      name="quotation_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Sales order prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['sales_order_prefix']
                          ? errors['sales_order_prefix']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'sales_order_prefix');
                      }}
                      height={'43rem'}
                      name="sales_order_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Purchase order prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['purchase_order_prefix']
                          ? errors['purchase_order_prefix']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'purchase_order_prefix');
                      }}
                      height={'43rem'}
                      name="purchase_order_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Sales invoice prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['invoice_prefix'] ? errors['invoice_prefix'] : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'invoice_prefix');
                      }}
                      height={'43rem'}
                      name="invoice_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Purchase invoice prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['purchase_invoice_prefix']
                          ? errors['purchase_invoice_prefix']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'purchase_invoice_prefix');
                      }}
                      height={'43rem'}
                      name="purchase_invoice_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Customer prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['customer_prefix']
                          ? errors['customer_prefix']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'customer_prefix');
                      }}
                      height={'43rem'}
                      name="customer_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Stock items prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['stock_items_prefix']
                          ? errors['stock_items_prefix']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'stock_items_prefix');
                      }}
                      height={'43rem'}
                      name="stock_items_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Suppliers prefix').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['suppliers_prefix']
                          ? errors['suppliers_prefix']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'suppliers_prefix');
                      }}
                      height={'43rem'}
                      name="suppliers_prefix"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!isValid || isLoading}
                    onClick={() => {
                      if (isValid) {
                        handleEditCompanySettings(transformEmptyValues(values));
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
