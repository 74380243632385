import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginSm } from 'assets/styled';

export const CloseContainer = styled.div`
  position: absolute;
  top: 20rem;
  right: 20rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 30rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30rem;
`;

export const LoadingPDFWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;
`;
export const ButtonText = styled.span`
  display: inline-block;
  color: ${COLORS.PRIMARY};
`;

export const Label = styled.span`
  font-weight: 300;
  color: ${COLORS.BLACK};
  font-size: 14rem;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 5rem;
`;

export const Title = styled.div`
  font-size: 18rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
`;

export const TopContainer = styled.div``;
