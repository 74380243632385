import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const MenuListWrapper = styled.div`
  overflow-y: auto,
  max-height: 200rem;
`;

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
`;

export const Menu = styled.div`
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_1000};
  border-radius: 10rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  padding: 10rem 0;
`;
