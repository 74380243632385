// Actions are Permissions used for components
export enum Actions {
  CREATE_QUOTATION = 1,
  READ_QUOTATION = 2,
  EDIT_QUOTATION = 3,
  DELETE_QUOTATION = 4,
  CREATE_SALES_ORDER = 5,
  READ_SALES_ORDER = 6,
  EDIT_SALES_ORDER = 7,
  DELETE_SALES_ORDER = 8,
  CREATE_SALES_INVOICE = 9,
  READ_SALES_INVOICE = 10,
  EDIT_SALES_INVOICE = 11,
  DELETE_SALES_INVOICE = 12,
  CREATE_PURCHASE_ORDER = 13,
  READ_PURCHASE_ORDER = 14,
  EDIT_PURCHASE_ORDER = 15,
  DELETE_PURCHASE_ORDER = 16,
  CREATE_STOCK_ITEM = 17,
  READ_STOCK_ITEM = 18,
  EDIT_STOCK_ITEM = 19,
  DELETE_STOCK_ITEM = 20,
  CREATE_APPOINTMENT = 21,
  READ_APPOINTMENT = 22,
  EDIT_APPOINTMENT = 23,
  DELETE_APPOINTMENT = 24,
  READ_FIELD_WORKER = 25,
  EDIT_FIELD_WORKER = 26,
  DELETE_FIELD_WORKER = 27,
  READ_USER_PERMISSIONS = 28,
  EDIT_USER_PERMISSION = 29,
  CREATE_PURCHASE_INVOICE = 30,
  READ_PURCHASE_INVOICE = 31,
  EDIT_PURCHASE_INVOICE = 32,
  DELETE_PURCHASE_INVOICE = 33,
  READ_INVOICE_PAYMENT = 34,
  EDIT_INVOICE_PAYMENT = 35,
  READ_CUSTOMER = 36,
  EDIT_CUSTOMER = 37,
  READ_SUPPLIER = 38,
  EDIT_SUPPLIER = 39,
  READ_TODO = 40,
  EDIT_TODO = 41,
  DELETE_TODO = 42,
}

// Roles are Permissions Groups used for navigation
export enum Roles {
  PUBLIC = 0, // Pages like Home, Analytics
  READ_SALES = 1,
  EDIT_SALES = 2,
  READ_INVOICE = 3,
  EDIT_INVOICE = 4,
  READ_PURCHASE = 5,
  EDIT_PURCHASE = 6,
  READ_STOCK = 7,
  EDIT_STOCK = 8,
  READ_PLANNING = 9,
  EDIT_PLANNING = 10,
  READ_FIELD_WORKER = 11,
  EDIT_FIELD_WORKER = 12,
  READ_COMPANY_ADMIN = 13,
  EDIT_COMPANY_ADMIN = 14,
}

// permissions_mapping = {
//     PermissionGroupId.READ_SALES: [
//         PermissionId.READ_QUOTATION,
//         PermissionId.READ_SALES_ORDER,
//         PermissionId.READ_CUSTOMER,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.EDIT_SALES: [
//         PermissionId.CREATE_QUOTATION,
//         PermissionId.CREATE_SALES_ORDER,
//         PermissionId.EDIT_QUOTATION,
//         PermissionId.EDIT_SALES_ORDER,
//         PermissionId.DELETE_QUOTATION,
//         PermissionId.DELETE_SALES_ORDER,
//         PermissionId.EDIT_CUSTOMER,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.READ_INVOICE: [
//         PermissionId.READ_SALES_INVOICE,
//         PermissionId.READ_PURCHASE_INVOICE,
//         PermissionId.READ_INVOICE_PAYMENT,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.EDIT_INVOICE: [
//         PermissionId.CREATE_SALES_INVOICE,
//         PermissionId.EDIT_SALES_INVOICE,
//         PermissionId.DELETE_SALES_INVOICE,
//         PermissionId.CREATE_PURCHASE_INVOICE,
//         PermissionId.EDIT_PURCHASE_INVOICE,
//         PermissionId.DELETE_PURCHASE_INVOICE,
//         PermissionId.EDIT_INVOICE_PAYMENT,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.READ_PURCHASE: [
//         PermissionId.READ_PURCHASE_ORDER,
//         PermissionId.READ_SUPPLIER,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.EDIT_PURCHASE: [
//         PermissionId.CREATE_PURCHASE_ORDER,
//         PermissionId.EDIT_PURCHASE_ORDER,
//         PermissionId.DELETE_PURCHASE_ORDER,
//         PermissionId.EDIT_SUPPLIER,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.READ_STOCK: [
//         PermissionId.READ_STOCK_ITEM,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.EDIT_STOCK: [
//         PermissionId.CREATE_STOCK_ITEM,
//         PermissionId.EDIT_STOCK_ITEM,
//         PermissionId.DELETE_STOCK_ITEM,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.READ_PLANNING: [
//         PermissionId.READ_APPOINTMENT,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.EDIT_PLANNING: [
//         PermissionId.CREATE_APPOINTMENT,
//         PermissionId.EDIT_APPOINTMENT,
//         PermissionId.DELETE_APPOINTMENT,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.READ_FIELD_WORKER: [
//         PermissionId.READ_FIELD_WORKER,
//     ],
//     PermissionGroupId.EDIT_FIELD_WORKER: [
//         PermissionId.EDIT_FIELD_WORKER,
//         PermissionId.DELETE_FIELD_WORKER,
//     ],
//     PermissionGroupId.READ_COMPANY_ADMIN: [
//         PermissionId.READ_USER_PERMISSIONS,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
//     PermissionGroupId.EDIT_COMPANY_ADMIN: [
//         PermissionId.EDIT_USER_PERMISSION,
//         PermissionId.READ_TODO,
//         PermissionId.EDIT_TODO,
//         PermissionId.DELETE_TODO,
//     ],
// }
