import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getCountries } from 'services/Public/PublicService';
import { signUp } from 'services/SignUp/SignUpService';
import { ICountry } from 'store/Common/types';
import { ISignUpDetailsDTO } from './types';

export const useGetCountries = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COUNTRIES],
    queryFn: () => {
      return getCountries();
    },
  });

export const useGetCountryOptions = (countries: ICountry[]) => {
  const { t } = useTranslation();

  return countries?.map((country: ICountry) => {
    return {
      value: country.cca2,
      label: `${country.cca2} - ${t(country.name)}`,
      key: country.name,
    };
  });
};

export const useSignUp = () => {
  const { t } = useTranslation();
  return useMutation((data: ISignUpDetailsDTO) => signUp(data), {
    onSuccess: () => {
      toast.success(t('Successfully signed up'), {
        toastId: ReactMutationKeys.SIGN_UP,
      });
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.SIGN_UP,
      });
    },
    mutationKey: ReactMutationKeys.SIGN_UP,
  });
};
