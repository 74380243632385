import { ICompanyData } from 'types/Admin.types';

export const getInitialDetailsData = (data: ICompanyData) => {
  if (data)
    return {
      name: data?.company?.name,
      vat_number: data?.company?.vat_number,
      coc_number: data?.company?.kvk_number,
      phone_number: data?.company?.phone_number,
      website: data?.company?.website,
      email: data?.company?.email,
    };
  else
    return {
      name: '',
      vat_number: '',
      coc_number: '',
      phone_number: '',
      website: '',
      email: '',
    };
};

export const getInitialAddressData = (data: ICompanyData) => {
  if (data)
    return {
      street: data?.company?.address?.street || '',
      house_number: data?.company?.address?.house_number || '',
      zip_code: data?.company?.address?.zip_code || '',
      city: data?.company?.address?.city || '',
      state: data?.company?.address?.state || '',
      cca2: data?.company?.address?.country?.cca2 || '',
    };
  else
    return {
      street: '',
      house_number: '',
      zip_code: '',
      city: '',
      state: '',
      cca2: '',
    };
};

export const getInitialBankData = (data: ICompanyData) => {
  if (data)
    return {
      bank_account_holder: data?.bank_info?.bank_account_holder,
      bank_code_number: data?.bank_info?.bank_code_number,
      bank_iban: data?.bank_info?.bank_iban,
      bank_name: data?.bank_info?.bank_name,
    };
  else
    return {
      bank_account_holder: '',
      bank_code_number: '',
      bank_iban: '',
      bank_name: '',
    };
};
