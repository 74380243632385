import { createGlobalStyle } from 'styled-components';
import { COLORS } from './colors';
import { borderRadiusSm, paddingSm } from './variables';
import { selectStyles } from 'components/Select/Select.styled';
import { breakpoints } from './breakpoints';

const GlobalStyle = createGlobalStyle`

  :root {
    --color-white: #ffffff;
    --color-black: #000000;
    --color-primary: #1A7FBF;
    --color-primary-disabled: #4d61f080;
    --color-black-200: #1f2937;
    --color-black-200-opacity: rgba(31, 41, 55, 0.6);
    --color-black-300-opacity: rgba(31, 41, 55, 0.7);
    --color-black-400-opacity: rgba(31, 41, 55, 0.8);
    --color-ripple-effect-opacity: rgba(0, 0, 0, 0.08);
    --color-ripple-child-effect-opacity: rgba(0, 0, 0, 0.2);
    --color-grey-100: #f9fafb;
    --color-grey-200: #F0F0F0;
    --color-grey-300: #dfdfdf;
    --color-grey-400: #C8CFDA;
    --color-grey-500: #BDBDBD;
    --color-grey-700: #808a9e;
    --color-grey-800: #6b7280;
    --color-grey-900: #757575;
    --color-grey-1000: #82868C;
    --color-red-100: #ff7b7b;
    --color-red-200: #eb4444;
    --color-red-trash: #E51616;
    --color-erp-black: #1A1A27;
    --color-background: #EDF4FA;
    --color-logyx-green: #3FA34D;
    --color-logyx-light-green: #A1DAA9;
    --color-strict-white: #ffffff;
    --color-strict-black: #000000;
    --color-canvas: transparent;

    /* Appointments */
    --color-ap-white: #ffffff;
    --color-green: #3BA338;
    --color-light-green: #A0DA96;
    --color-orange: #D58D22;
    --color-light-orange: #F2C480;
    --color-ship-grey: #3A3C40;
    --color-light-primary: #a0c6e5;
    --color-purple: #A47AB0;
    --color-light-purple: #DECFE3;
  }

  [data-theme='dark'] {
    --color-white: #18212f;
    --color-black: #ffffff;
    --color-primary: #1A7FBF;
    --color-primary-disabled: #4d61f080;
    --color-black-200: #f9fafb;
    --color-black-200-opacity: rgba(55, 65, 81, 0.6);
    --color-black-300-opacity: rgba(55, 65, 81, 0.7);
    --color-black-400-opacity: rgba(55, 65, 81, 0.8);
    --color-ripple-effect-opacity: rgba(255, 255, 255, 0.08);
    --color-ripple-child-effect-opacity: rgba(255, 255, 255, 0.2);
    --color-grey-100: #1f2937;
    --color-grey-200: #374151;
    --color-grey-300: #4b5563;
    --color-grey-400: #6b7280;
    --color-grey-500: #9ca3af;
    --color-grey-700: #e5e7eb;
    --color-grey-800: #f3f4f6;
    --color-grey-900: #f9fafb;
    --color-grey-1000: #f9fafb;
    --color-red-100: #ff7b7b;
    --color-red-200: #eb4444;
    --color-erp-black: #1A1A27;
    --color-background: #1f2937;
    --color-logyx-green: #3FA34D;
    --color-logyx-light-green: #A1DAA9;
    --color-strict-white: #ffffff;
    --color-strict-black: #000000;
    --color-canvas: #4b5563;
    
    /* Appointments */
    --color-ap-white: #D7D7D7;
    --color-green: #3BA338;
    --color-light-green: #A0DA96;
    --color-orange: #D58D22;
    --color-light-orange: #F2C480;
    --color-ship-grey: #3A3C40;
    --color-light-primary: #a0c6e5;
    --color-purple: #A47AB0;
    --color-light-purple: #DECFE3;
  }

  html, body {
    background-color: ${COLORS.BACKGROUND_COLOR};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
    * {
      box-sizing: inherit;
      margin: 0;
    }
    font-size:0.7px;
    @media screen and (min-width: 1181px) and (max-width: 1300px)  {
      font-size:0.8px;
    }
    @media screen and (min-width: 1300px) and (max-width: 1900px)  {
      font-size:0.9px;
    }
    @media screen and (min-width: 1900px)  {
      font-size:1px;
    }
  }

  body {
    font-size: 14px;

    @media screen and (min-width: 1300px) and (max-width: 1900px)  {
      font-size: 13px;
    }

    @media screen and (min-width: 1900px)  {
      font-size: 16px;
    }
  }

  label {
    font-weight: 600;
    display: inline-block;
    color: ${COLORS.BLACK};
  }

  a {
    user-select: none;
    text-decoration: none;
  }

  

  input:disabled {
    cursor: auto;

    ~ label {
      cursor: auto;
    }
  }

  button {
    cursor: pointer;
    font-family: inherit;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  #root {
    height: 100%;
  }

  .fullcalendar_homepage_wrapper .fc {
    cursor: default;
  }

  .fullcalendar_fieldworker_wrapper .fc {
    cursor: default;
  }

  .homepage .fc {
    cursor: default;
  }
  
  // FullCalendar custom styling
  .fc {
    label {
      color: inherit;
    }
    --fc-page-bg-color: transparent;
    cursor: cell;
    .fc-scrollgrid{
      border: none !important;
    }
    thead{
       cursor: default;
      tr{
        th{
          border: none !important;
        }
      }
    }
    .fc-col-header-cell {
     
    }
    td:nth-child(1){
      border: none !important;
    }
    td:nth-child(2){
      border-left: none;
    }
    tr:nth-child(2n) {
      td {
        border: none !important;
      }
    }
    .fc-timegrid-slot {
      position: relative;
      
    }
    
    .fc-timegrid-slot-label-cushion {
      color: #82868C;
      position: absolute;
      left: 0;
      top: -10rem;
      cursor: default;
    }
    .fc-timegrid-slot-label {
      cursor: default;
    }
    .fc-timegrid-body {
      padding-top: 5px;
    }
    .fc-day {
      div{
        a{
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;
          color: ${COLORS.BLACK}
        }
      }
    }
    .fc-day-today {
      background-color: inherit !important;
       div{
          a{
            color: ${COLORS.PRIMARY}
          }
        }
    }
    .fc-timegrid-now-indicator-arrow {
      /* display: none; */
    }
    --fc-now-indicator-color: ${COLORS.PRIMARY};
    .fc-event-title-container {
      text-align: center;
    }
    .fc-timegrid-event-harness { // Event container
      margin-left: 1rem;
      margin-top: 3rem;
      
    }
    .fc-highlight {
      border-radius: 10rem;
      margin-bottom: -3rem;
      margin-left: -3rem;
    }
    .fc-daygrid-dot-event:hover {
      background-color: transparent;
    }

    .fc-timegrid-event .fc-event-main {
      padding: 0;
    }
  }

  /* FullCalendar borders for Month View */
  .fc-dayGridMonth-view {
     td:nth-child(1){
      border: 1px solid var(--fc-border-color) !important;
    }
    td:nth-child(2){
      border-left: 1px solid var(--fc-border-color) !important;
    }
    tr:nth-child(2n) {
      td {
        border: 1px solid var(--fc-border-color) !important;
      }
    }
  }

  // Used for positioning custom short day names depending on locale
  .fc-col-header-cell-cushion {
    position: relative;
  }

  .fc-event {
    border-radius: 10rem;
  }

  /* Mui TimePicker hidden disabled values for hours */
  .MuiPickersLayout-contentWrapper {

    .Mui-disabled {
      display: none;
    }
  }

  .MuiSwitch-root, .MuiSwitch-thumb {
    color: ${COLORS.PRIMARY};
  }

  img,
  svg {
    vertical-align: middle;
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  .toast-dark {

    .Toastify__toast-theme--dark {
      background-color: #1f2937;
      border: 1px solid #1A1A27;
    }
  }

  /* Prevent mui disabled class to hide button when is disabled */
  .Mui-disabled {
    display: flex;
  }
  
  .MuiOutlinedInput-root {
    outline: none;
  }

  // Icon
  .icon-ripple-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .icon-pointer-events-none {
    pointer-events: none;
  }

  .userRoles__page .table-responsive {
    width: 100%;
  }
  // Mui date picker border radius
  .MuiInputBase-root {
    border-radius: ${borderRadiusSm};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.GREY_1000} !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
  }

  /* More space for scroll */
  .MuiMultiSectionDigitalClock-root {
    ul {
      width: 65px;
    }
  }

  /* Mui Switch */
  .MuiSwitch-switchBase.Mui-disabled .MuiSwitch-thumb {
    background-color: ${COLORS.GREY_500}; 
  }

  .MuiSwitch-track {
    height: 5rem;
    background-color: ${COLORS.GREY_1000}; 
  }
   .MuiSwitch-root {
    display: flex;
    align-items: center;
  }
  .swiper-slide {
    justify-content: flex-start !important;
  }

  /* React Spectrum DateRangePicker */
  .cH0MeW_spectrum-Popover {
    padding: 0;
  }
   .EuwYBq_spectrum-Calendar-monthHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .EuwYBq_spectrum-Calendar-monthHeader .buttons-container {
    display: flex;
  }

  .EuwYBq_spectrum-Calendar-monthHeader h2 {
    order: -1; 
    font-weight: normal;
    font-family: "Poppins";
    margin-right: auto;
  }

  .h_OVWW_spectrum-Dialog-grid {
    display: flex !important;
    justify-content: center !important;
    padding: 16rem;
  } 

  .h_OVWW_spectrum-Dialog-content {
    padding: 0;
  }

  .yxBNXG_spectrum-Icon{
    fill: ${COLORS.GREY_1000} !important;
  }

  .gP7gvq_spectrum--darkest .cH0MeW_spectrum-Popover {
    background-color: #2F2F2F !important;
  }

  .gP7gvq_spectrum--darkest {
    background-color: transparent !important;
  }

  .PFjRbG_spectrum--light {
    background-color: ${COLORS.WHITE} !important;
    border-radius: 10rem;
  }

  .YO3Nla_spectrum-Textfield,
  .A-HlBa_spectrum-Field,
  .ZTA2ya_spectrum-InputGroup,
  .YO3Nla_spectrum-Textfield-input,
  .Dniwja_spectrum-FieldButton {
    background-color: transparent !important;
    border-bottom-left-radius: 10rem !important;
    border-top-left-radius: 10rem !important;
    font-family: "Poppins";
  }

  ._2n04KW_react-spectrum-Datepicker-inputContents {
    font-size: 16rem;
  }

  .EuwYBq_spectrum-Calendar-title {
    font-size: 20rem;
  }

  .ZTA2ya_spectrum-InputGroup .ZTA2ya_spectrum-FieldButton:before {
    border-top-right-radius: 10rem !important; 
    border-bottom-right-radius: 10rem !important; 
  }

  .YO3Nla_spectrum-Textfield-input {
    padding: 0 10rem !important;
    @media screen and (max-width: ${breakpoints.bigTablet}) {
      height: 45rem;
    }
  }

  .Dniwja_spectrum-FieldButton {
    cursor: pointer;
    @media screen and (max-width: ${breakpoints.bigTablet}) {
      height: 45rem;

      svg {
        transform: scale(.8);
      }
    }
  }

  .Dniwja_spectrum-ActionButton {
    cursor: pointer;
  }

  .EuwYBq_spectrum-Calendar-tableCell span {
    cursor: pointer;
  }

  ._2n04KW_react-spectrum-DatePicker-cell {
    min-width: 0 !important;
  }

  ._2n04KW_react-spectrum-DatePicker-cell > span {
    display: none;
  }

 ._2n04KW_is-placeholder > span {
    display: inline !important;
  }

  .YO3Nla_spectrum-Textfield-validationIcon, 
  .a1Qy2q_spectrum-HelpText-text, 
  .a1Qy2q_spectrum-HelpText-validationIcon {
    display: none;
  }

  .EuwYBq_spectrum-Calendar-tableCell .EuwYBq_spectrum-Calendar-date.EuwYBq_is-range-selection.EuwYBq_is-selection-start .EuwYBq_spectrum-Calendar-dateText,
  .EuwYBq_spectrum-Calendar-tableCell .EuwYBq_spectrum-Calendar-date.EuwYBq_is-range-selection.EuwYBq_is-selection-end .EuwYBq_spectrum-Calendar-dateText
  {
    background-color: ${COLORS.PRIMARY} !important;
    border-radius: 15px !important;
  }

  .EuwYBq_spectrum-Calendar-date {
    font-size: 16rem;
  }

  /* Mui SelectWithCheckmarks */
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${COLORS.PRIMARY}; 
  }
  .MuiCheckbox-colorPrimary {
    padding: 2 0 0 ${paddingSm};
  }

  #multiple-checkbox-label.Mui-focused,
  #multiple-checkbox-label[data-shrink="true"] {
    color: ${COLORS.GREY_1000}; 
  }

  .MuiFormLabel-root[data-shrink="false"] {
    top: 50%;
    transform: translateY(-50%);
    padding-left: 8rem;
    font-size: 16rem;
  }

  .MuiTypography-root-hjKMqV {
    font-size: 16rem;
  }

  .MuiInputBase-root {
    background-color: ${COLORS.WHITE};
  }

  .MuiInputBase-root-hxnqRp.Mui-disabled {
    background-color: ${COLORS.GREY_200};
    color: ${COLORS.GREY_1000}
  }

  .MuiInputLabel-animated {
    transition: all 0.3s;
    @media screen and (max-width: ${breakpoints.bigTablet}) {
      font-size: 14px;

      /* When placeholder is up */
      &[data-shrink="true"] {
        left: -3px;
      }
    }
  }

  /* Action animation */
  .slide-exit {
    transform: translateX(0);
  }
  .slide-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 500ms ease-in-out;
  }

  .exit-row {
    transform: translateX(-150%);
    transition: transform 300ms ease-out;
  }

  /* Introduction guide */
  .react-joyride__overlay {
    min-height: 100vh
  }

  /* Select global styles */
  ${selectStyles}

  /* MuiTooltip */
  .MuiTooltip-popper {
  z-index: 10000;
} 
`;

export default GlobalStyle;
