import {
  COLORS,
  borderRadiusSm,
  marginLg,
  marginMd,
  marginSm,
  paddingMd,
  paddingLg,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 1075rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    width: 100%;
    padding-left: ${paddingLg};
    padding-right: ${paddingLg};
  `}
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-top: ${marginLg};
  margin-bottom: ${marginLg};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
`;

export const Label = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
