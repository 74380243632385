import {
  borderRadiusSm,
  COLORS,
  marginMd,
  marginSm,
  paddingMd,
} from 'assets/styled';
import { hoverLineIn } from 'assets/styled/commonStyles';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: 850rem;
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
  margin-bottom: ${marginMd};
`;

export const EditLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  ${hoverLineIn}
`;

export const ValueWrapperRelative = styled.div`
  position: relative;
  margin-right: ${marginSm};
  height: min-content;
`;

export const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 12rem;
  height: 12rem;
  right: -20rem;
  top: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20rem;
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 14rem;
  line-height: 21rem;
`;

export const LabelKeyWrapper = styled.div`
  width: fit-content;
`;

export const LabelValue = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;

export const Subtitle = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 16rem;
  padding-bottom: 20rem;
`;

export const CompanyLogoImg = styled.img`
  margin-top: ${marginMd};
  border-radius: ${borderRadiusSm};
  height: fit-content;
  max-width: 340rem;
`;
