export const SET_IS_SESSION_EXPIRED = 'SET_IS_SESSION_EXPIRED';
export const SET_IS_UPDATE_AVAILABLE = 'SET_IS_UPDATE_AVAILABLE';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const SET_IS_FIELD_WORKER_VIEW = 'SET_IS_FIELD_WORKER_VIEW';
export const SET_IS_DARK_MODE = 'SET_IS_DARK_MODE';
export const SET_IS_MOBILE_APP = 'SET_IS_MOBILE_APP';
export const SET_IS_SOCKET_CONNECTED = 'SET_IS_SOCKET_CONNECTED';
export const SET_RUN_TOUR = 'SET_RUN_TOUR';
export const SET_COMMON_DATA = 'SET_COMMON_DATA';
export const SET_IS_LOADING_SCREEN_SHOWN = 'SET_IS_LOADING_SCREEN_SHOWN';
export const SET_MARK_AS_CONNECTED_INVOICE_ID =
  'SET_MARK_AS_CONNECTED_INVOICE_ID';
export const SET_FRONTEND_SETTINGS = 'SET_FRONTEND_SETTINGS';
export const SET_IS_SAVING_PLANNING_PAGE_FILTERS_AND_LOGGING_OUT =
  'SET_IS_SAVING_PLANNING_PAGE_FILTERS_AND_LOGGING_OUT';
