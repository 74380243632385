import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetQuotationTypeOptions } from './EditGeneralCompanySettingsModal/hooks';

export const useGetCompanyCardData = (data: any) => {
  const companyConfigurationLeftData = {
    Name: data?.company?.name,
    'VAT number': data?.company?.vat_number,
    'COC number': data?.company?.kvk_number,
    'Phone number': data?.company?.phone_number,
    Website: data?.company?.website,
    Email: data?.company?.email,
  };

  const companyConfigurationRightData = {
    Street: data?.company?.address?.street,
    'House number': data?.company?.address?.house_number,
    'Zip code': data?.company?.address?.zip_code,
    City: data?.company?.address?.city,
    State: data?.company?.address?.state,
    'Country code': data?.company?.address?.country.cca2,
  };

  const companyConfigurationExtraLeftData = {
    'Bank name': data?.bank_info?.bank_name,
    'Bank IBAN': data?.bank_info?.bank_iban,
    'Bank code number': data?.bank_info?.bank_code_number,
    'Bank account holder': data?.bank_info?.bank_account_holder,
  };

  return {
    companyConfigurationLeftData,
    companyConfigurationRightData,
    companyConfigurationExtraLeftData,
  };
};

export const useGetSettingsCardData = (data: any) => {
  const { t } = useTranslation();
  const quotationTypeOptions = useGetQuotationTypeOptions();

  const generalSettingsLeftData = {
    Currency: data?.settings?.currency?.code,
    'Tax rate': data?.settings?.tax_rate,
    'Working hours rate': data?.settings?.working_hours_rate,
  };
  const generalSettingsRightData = {
    'Days valid quotation': data?.settings?.days_valid_quotation,
    'Payment due invoice': data?.settings?.days_valid_invoice,
    'Default new quotation type': t(
      quotationTypeOptions?.find(
        (option) =>
          Number(option.value) === data?.settings?.default_new_quotation_type_id
      )?.label
    ),
  };

  const emaillSettingsLeftData = {
    'Sender email': data?.settings?.sender_email,
    'Email copy': data?.settings?.bcc_email_address,
    'Invoice copy': data?.settings?.invoice_bcc_email_address,
    'Order definitive default note':
      data?.settings?.sales_order_products_definitive_email_note,
  };

  const emaillSettingsRightData = {
    'Field worker notification email':
      data?.settings?.field_work_notification_email,
    'Quotation notification email':
      data?.settings?.quotation_notification_email,
    'Email content footer address': data?.settings?.email_footer_email,
  };

  const paymentReminderSettingsLeftData = {
    'Automatic payment reminder': data?.settings?.due_date_start_reminder
      ? t('On')
      : t('Off'),
    'Due date first reminder': data?.settings?.due_date_start_reminder,
    'Due date second reminder': data?.settings?.due_date_second_reminder,
  };

  const paymentReminderSettingsRightData = {
    'Due date third reminder': data?.settings?.due_date_third_reminder,
    'Reminder sent time': data?.settings?.due_date_reminder_sent_time
      ? moment(data?.settings?.due_date_reminder_sent_time, 'HH:mm:ss').format(
          'HH:mm'
        )
      : '',
  };

  const appointmentSettingsLeftData = {
    'Automatic appointment reminder':
      data?.settings?.appointment_days_before_reminder &&
      data?.settings?.appointment_reminder_hour
        ? t('On')
        : t('Off'),
    'Appointment days before reminder':
      data?.settings?.appointment_days_before_reminder,
    'Appointment reminder hour': data?.settings?.appointment_reminder_hour
      ? moment(data?.settings?.appointment_reminder_hour, 'HH:mm:ss').format(
          'HH:mm'
        )
      : '',
  };

  const appointmentSettingsRightData = {
    'Appointment lower interval': data?.settings?.appointment_lower_interval,
    'Appointment upper interval': data?.settings?.appointment_upper_interval,
  };

  const prefixesLeftData = {
    'Quotation prefix': data?.settings?.quotation_prefix,
    'Sales order prefix': data?.settings?.sales_order_prefix,
    'Purchase order prefix': data?.settings?.purchase_order_prefix,
    'Sales invoice prefix': data?.settings?.invoice_prefix,
    'Purchase invoice prefix':
      data?.settings?.purchase_invoice_prefix || data?.settings?.invoice_prefix,
  };

  const prefixesRightData = {
    'Customer prefix': data?.settings?.customer_prefix,
    'Stock items prefix': data?.settings?.stock_items_prefix,
    'Suppliers prefix': data?.settings?.suppliers_prefix,
  };

  return {
    generalSettingsLeftData,
    generalSettingsRightData,
    emaillSettingsLeftData,
    emaillSettingsRightData,
    paymentReminderSettingsLeftData,
    paymentReminderSettingsRightData,
    appointmentSettingsLeftData,
    appointmentSettingsRightData,
    prefixesLeftData,
    prefixesRightData,
  };
};
