import { H4 } from 'assets/styled';
import { Formik } from 'formik';
import {
  ColumnContainer,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
} from '../EditCompanyDataModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { editDetailsTabSchema } from '../validation';
import { IEditDetails } from '../constants';

interface IDetailsTabProps {
  details: IEditDetails;
  setDetails: (details: IEditDetails) => void;
  setIsValid: (isValid: boolean) => void;
}
export const DetailsTab = ({
  details,
  setDetails,
  setIsValid,
}: IDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        validationSchema={editDetailsTabSchema}
        initialValues={details}
      >
        {({ values, setFieldValue, handleBlur, touched, errors, isValid }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            setDetails(values);
            handleBlur(e);
            setIsValid(isValid);
          };
          return (
            <Container>
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Name').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="name-field"
                    errorMessage={
                      errors['name'] ? (errors['name'] as string) : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'name');
                    }}
                    height={'43rem'}
                    name="name"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('VAT number').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['vat_number']
                        ? (errors['vat_number'] as string)
                        : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'vat_number');
                    }}
                    height={'43rem'}
                    name="vat_number"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <HorizontalLine />
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('COC number').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['coc_number']
                        ? (errors['coc_number'] as string)
                        : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'coc_number');
                    }}
                    height={'43rem'}
                    name="coc_number"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>

                <ColumnContainer>
                  <Heading>
                    <H4>{t('Phone number').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['phone_number']
                        ? (errors['phone_number'] as string)
                        : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'phone_number');
                    }}
                    height={'43rem'}
                    name="phone_number"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Website').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['website'] ? (errors['website'] as string) : ''
                    }
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      handleBlurFunction(e, 'website');
                    }}
                    height={'43rem'}
                    name="website"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Email').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['email'] ? (errors['email'] as string) : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'email');
                    }}
                    height={'43rem'}
                    name="email"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
};
