import { borderRadiusSm, COLORS, H3 } from 'assets/styled';
import { hoverLineIn, hoverLineOut } from 'assets/styled/commonStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30rem;
  background-color: ${COLORS.WHITE};
  margin-bottom: 30rem;
  border-radius: ${borderRadiusSm};
`;

export const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Headline = styled(H3)``;

export const ActionButtons = styled.div`
  display: flex;
  gap: 20rem;
  align-items: center;
`;

export const ActionButton = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const DeleteLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.RED_TRASH};
  cursor: pointer;
  ${hoverLineOut}
`;

export const EditLabel = styled(DeleteLabel)`
  color: ${COLORS.PRIMARY};
`;

export const ActionIcon = styled.div`
  padding-left: 5rem;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding-top: 20rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  width: 100%;
  gap: 5rem;
`;

export const NameLabel = styled.span`
  font-weight: 600;
  font-size: 14rem;
  color: ${COLORS.BLACK};
`;

export const ValueLabel = styled.span`
  font-weight: 300;
  font-size: 14rem;
  color: ${COLORS.BLACK};
`;

export const LinkValue = styled.a`
  font-weight: 300;
  font-size: 14rem;
  text-decoration: underline;
  cursor: pointer;
  color: ${COLORS.BLACK};
`;

export const DoubleRow = styled.div`
  display: flex;
  gap: 10rem;
`;

export const ShowMoreLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  margin-top: 20rem;
  ${hoverLineIn}
`;
