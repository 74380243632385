// Single Customer
export const SingleCustomerRoutes = {
  SingleCustomerGeneral: {
    path: '',
    fullPath: '/customers/:id',
  },
  SingleCustomerQuotations: {
    path: 'quotations',
    fullPath: '/customers/:id/quotations',
  },
  SingleCustomerSales: {
    path: 'sales',
    fullPath: '/customers/:id/sales',
  },
  SingleCustomerMollieMandates: {
    path: 'mandates',
    fullPath: '/customers/:id/mandates',
  },
  SingleCustomerAppointments: {
    path: 'appointments',
    fullPath: '/customers/:id/appointments',
  },
  SingleCustomerReports: {
    path: 'reports',
    fullPath: '/customers/:id/reports',
  },
  SingleCustomerAltContacts: {
    path: 'alternative-contacts',
    fullPath: '/customers/:id/alternative-contacts',
  },
};
