import { useLocation } from 'react-router-dom';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { INewAltContactNavigationState } from '../SingleCustomerPage/types';
import AddEditContactForm from './AddEditAltContactForm/AddEditAltContactForm';
import { useTranslation } from 'react-i18next';
import {
  ContactDetailsContainer,
  ContactDetailsLabel,
  Container,
  HeaderWrapper,
} from './NewAltContactPage.styled';
import { H3 } from 'assets/styled';

const NewAltContactPage = () => {
  const { state }: { state: INewAltContactNavigationState } = useLocation();
  const { t } = useTranslation();

  if (!state?.customerId) {
    return <PageNotFound />;
  }
  return (
    <Container>
      <HeaderWrapper>
        <H3>{t('Create alternative contact')}</H3>
      </HeaderWrapper>
      <ContactDetailsContainer>
        <ContactDetailsLabel>{t('Contact details')}</ContactDetailsLabel>
        <AddEditContactForm customerId={state?.customerId} />
      </ContactDetailsContainer>
    </Container>
  );
};

export default NewAltContactPage;
