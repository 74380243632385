import {
  ICreateEmailTemplateData,
  ICreateEmailTemplateGeneralThemeData,
} from 'pages/Manager/EmailTemplatesPage/types';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/settings/';

export const getEmailTemplates = async (): Promise<any> => {
  const url = `${BASE_URL}email-templates`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const createEmailTemplate = async (
  createEmailTemplateData: ICreateEmailTemplateData
): Promise<any> => {
  const url = `${BASE_URL}email-templates`;
  const { data } = await apiClient.post<any>(url, createEmailTemplateData);

  return data.data;
};

export const getEmailTemplateVariables = async (): Promise<any> => {
  const url = `${BASE_URL}email-template-variables`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const createEmailTemplateGeneralTheme = async (
  createEmailTemplateGeneralThemeData: ICreateEmailTemplateGeneralThemeData
): Promise<any> => {
  const url = `${BASE_URL}email-templates-general`;
  const { data } = await apiClient.post<any>(
    url,
    createEmailTemplateGeneralThemeData
  );

  return data.data;
};

export const testEmailTemplate = async (templateId: number): Promise<any> => {
  const url = `${BASE_URL}test-email-templates/${templateId}`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const deactivateEmailTemplate = async (
  templateId: number
): Promise<any> => {
  const queryParamsObj = {
    active: 'false',
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}email-templates/${templateId}/toggle-active`,
    queryParamsObj
  );
  const { data } = await apiClient.patch<any>(fullUrl);

  return data.data;
};

export const updateWatermarkPicture = async (pictureFormData: FormData) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = `${BASE_URL}watermark`;
  const { data } = await apiClient.patch(url, pictureFormData, { headers });

  return data.data;
};

export const getWatermarkPicture = async (): Promise<any> => {
  const url = `${BASE_URL}watermark`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const deleteWatermarkPicture = async (): Promise<any> => {
  const url = `${BASE_URL}watermark`;
  const { data } = await apiClient.delete(url);

  return data.data;
};
