import { getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

import { IEditCompanyDataDTO } from './constants';
import { editCompany } from 'services/Admin/CompanyService';
import { AxiosError } from 'axios';

export const useEditCompany = (companyId: number) => {
  const { t } = useTranslation();
  return useMutation(
    (editCompanyDataDTO: IEditCompanyDataDTO) =>
      editCompany(companyId, editCompanyDataDTO),
    {
      onSuccess: () => {
        toast.success(t('Company data edited'), {
          className: ReactMutationKeys.EDIT_COMPANY_ADMIN,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_COMPANY_ADMIN,
        ]);
      },
      onError: (error: AxiosError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_COMPANY_ADMIN,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_COMPANY_ADMIN,
    }
  );
};
