import openApiClient from 'services/api/openApiService';

const BASE_URL = '/v1/public';

export const getCountries = async (): Promise<any> => {
  const url = `${BASE_URL}/countries`;
  const { data } = await openApiClient.get<any>(url);

  return data.data;
};

export const getCompanyLicenses = async (): Promise<any> => {
  const url = `${BASE_URL}/company-licenses`;
  const { data } = await openApiClient.get<any>(url);

  return data.data;
};

export const getCompanyBilling = async (): Promise<any> => {
  const url = `${BASE_URL}/company-billing`;
  const { data } = await openApiClient.get<any>(url);

  return data.data;
};

export const getUserLicenses = async (): Promise<any> => {
  const url = `${BASE_URL}/user-licenses`;
  const { data } = await openApiClient.get<any>(url);

  return data.data;
};
