import {
  COLORS,
  marginMd,
  marginSm,
  paddingMd,
  paddingSm,
} from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${paddingMd};
  max-height: 859rem;
`;

export const Label = styled.div`
  max-width: 300rem;
`;

export const HorizontalLine = styled.div`
  width: 635rem;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
`;

export const ApplyWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5rem;
`;
export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledFormikSelect = styled(CustomSelect)`
  width: 300rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14rem;
`;

export const FilterTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30rem;
  margin-top: ${marginMd};
`;

export const FilterTypeLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const ColumnContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 300rem;
  margin-bottom: ${marginSm};
`;

export const TimePickerColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const CheckBoxWrapper = styled.div`
  margin-top: 30rem;
`;

export const RowSpaceBetweenAlignCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10rem;
  padding-bottom: ${paddingSm};
`;
