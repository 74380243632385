import { queryClient } from 'index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  editCompanySettings,
  getCompanyByCompanyId,
  getCompanyLogo,
  getCompanySettings,
  uploadCompanyLogo,
} from 'services/Admin/CompanyService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { IEditCompanySettingsDTO } from 'types/Admin.types';

export const useGetCompanySettings = (companyId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY_SETTINGS_ADMIN, companyId],
    queryFn: () => {
      return getCompanySettings(Number(companyId));
    },
    enabled: !!companyId,
  });

export const useGetSingleCompany = (companyId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_COMPANY_ADMIN, companyId],
    queryFn: () => {
      return getCompanyByCompanyId(Number(companyId));
    },
    enabled: !!companyId,
  });

export const useEditCompanySettings = (companyId: number) => {
  const { t } = useTranslation();

  return useMutation(
    (editCompanySettingsDTO: IEditCompanySettingsDTO) =>
      editCompanySettings(companyId, editCompanySettingsDTO),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_COMPANY_SETTINGS_ADMIN,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_COMPANY_SETTINGS,
        });
      },
      onSettled: (data) => {
        // On Settled
      },
      mutationKey: ReactMutationKeys.EDIT_COMPANY_SETTINGS,
    }
  );
};

export const useGetCompanyLogo = (companyId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY_LOGO, companyId],
    queryFn: () => {
      return getCompanyLogo(companyId);
    },
    enabled: !!companyId,
  });

export const useUploadCompanyLogo = (companyId: number) =>
  useMutation(
    (file: Blob) => {
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      return uploadCompanyLogo(companyId, fileFormData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKeys.GET_COMPANY_LOGO]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_COMPANY_LOGO,
        });
      },
      onSettled: () => {
        // On Settled
      },
      mutationKey: ReactMutationKeys.UPLOAD_COMPANY_LOGO,
    }
  );

export const useGetIsModalOpen = () => {
  const [
    isEditGeneralCompanySettingsModalOpen,
    setIsEditGeneralCompanySettingsModalOpen,
  ] = useState<boolean>(false);
  const [
    isEditPrefixesCompanySettingsModalOpen,
    setIsEditPrefixesCompanySettingsModalOpen,
  ] = useState<boolean>(false);
  const [
    isEditEmailCompanySettingsModalOpen,
    setIsEditEmailCompanySettingsModalOpen,
  ] = useState<boolean>(false);
  const [
    isEditPaymentReminderCompanySettingsModalOpen,
    setIsEditPaymentReminderCompanySettingsModalOpen,
  ] = useState<boolean>(false);
  const [
    isEditAppointmentReminderCompanySettingsModalOpen,
    setIsEditAppointmentReminderCompanySettingsModalOpen,
  ] = useState<boolean>(false);
  const [isEditCompanyDataModalOpen, setIsEditCompanyDataModalOpen] =
    useState<boolean>(false);

  return {
    isEditGeneralCompanySettingsModalOpen,
    isEditPrefixesCompanySettingsModalOpen,
    isEditEmailCompanySettingsModalOpen,
    isEditPaymentReminderCompanySettingsModalOpen,
    isEditAppointmentReminderCompanySettingsModalOpen,
    isEditCompanyDataModalOpen,
    setIsEditGeneralCompanySettingsModalOpen,
    setIsEditPrefixesCompanySettingsModalOpen,
    setIsEditEmailCompanySettingsModalOpen,
    setIsEditPaymentReminderCompanySettingsModalOpen,
    setIsEditAppointmentReminderCompanySettingsModalOpen,
    setIsEditCompanyDataModalOpen,
  };
};
