import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/twinfield-companies/';

export const addTwinfieldCompanyInfo = async (
  twinfieldCompanyInfoData: {
    companyCode: string;
    profitAndLossAccountCode: string;
    generalLedgerDebtors: string;
    downpaymentAccountSettable: string;
  },
  companyId: number
): Promise<any> => {
  const url = `${BASE_URL}${companyId}`;
  const { data } = await apiClient.post<any>(url, {
    company_code: twinfieldCompanyInfoData.companyCode,
    profit_and_loss_account_code:
      twinfieldCompanyInfoData.profitAndLossAccountCode,
    general_ledger_debtors: twinfieldCompanyInfoData.generalLedgerDebtors,
    down_payment_account_settable:
      twinfieldCompanyInfoData.downpaymentAccountSettable,
  });
  return data.data;
};

export const getTwinfieldCompanyInfo = async (
  companyId: number
): Promise<any> => {
  const url = `${BASE_URL}${companyId}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getOfficeCodes = async (companyId: number): Promise<any> => {
  const url = `${BASE_URL}${companyId}/offices`;
  const { data } = await apiClient.get<any>(url);
  return data.data.offices;
};

export const getTwinfieldOptions = async (
  companyDetails: {
    companyCode: string;
    selectSearch: string;
    accountType: string;
  },
  companyId: number
): Promise<any> => {
  const params = {
    company_code: companyDetails.companyCode,
    search_term: companyDetails.selectSearch,
    settable_account_type: companyDetails.accountType,
  };
  const url = `${BASE_URL}${companyId}/office-details`;
  const { data } = await apiClient.get<any>(url, { params });
  return data.data;
};

export const editTwinfieldCompanyInfo = async (
  editTwinfieldCompanyCodeData: {
    companyCode: string;
    profitAndLossAccountCode: string;
    generalLedgerDebtors: string;
    downpaymentAccountSettable: string;
    id: string;
  },
  companyId: number
): Promise<any> => {
  const url = `${BASE_URL}${companyId}`;
  const { data } = await apiClient.put<any>(url, {
    company_code: editTwinfieldCompanyCodeData.companyCode,
    profit_and_loss_account_code:
      editTwinfieldCompanyCodeData.profitAndLossAccountCode,
    general_ledger_debtors: editTwinfieldCompanyCodeData.generalLedgerDebtors,
    down_payment_account_settable:
      editTwinfieldCompanyCodeData.downpaymentAccountSettable,
    id: editTwinfieldCompanyCodeData.id,
  });
  return data.data;
};
