import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Card,
  CardTitle,
  Column,
  ExternalServiceLogo,
  Label,
  LabelValue,
  MarginTop30,
  RowSpaceBetween,
} from './LogyxIntegrationCard.styled';
import { useEffect, useState } from 'react';
import { Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { useEditCompanySettings } from '../hooks';
import { toast } from 'react-toastify';
import LogyxLogo from 'assets/images/ExternalServices/logyxLogoSmall.svg';
import { Formik } from 'formik';
import { editCompanySettingsSchema } from './validation';
import { transformEmptyValues } from 'utils/transformEmptyValues';

interface ILogyxIntegrationCardProps {
  companyId: number;
  initialAPIKey: string;
  initialLogyxCompanyId: string;
  initialLogyxAPIUrl: string;
}

const LogyxIntegrationCard = ({
  companyId,
  initialAPIKey,
  initialLogyxCompanyId,
  initialLogyxAPIUrl,
}: ILogyxIntegrationCardProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const {
    mutate: editCompanySettings,
    isLoading,
    isSuccess,
  } = useEditCompanySettings(Number(companyId!));

  const handleEditCompanySettings = (values: any) => {
    const editCompanySettingsDTO: any = {
      logyx_api_key: values.api_key,
      logyx_company_id: values.company_id,
      logyx_api_url: values.api_url,
    };
    editCompanySettings(editCompanySettingsDTO);
  };

  const getInitialValues = () => {
    return {
      api_key: initialAPIKey,
      company_id: initialLogyxCompanyId,
      api_url: initialLogyxAPIUrl,
    };
  };

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
      toast.success(t('Logyx preferences updated'));
    }
  }, [isSuccess]);

  return (
    <Card>
      <RowSpaceBetween>
        <CardTitle>Logyx</CardTitle>
        <ExternalServiceLogo src={LogyxLogo} />
      </RowSpaceBetween>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        initialValues={getInitialValues()}
        validationSchema={editCompanySettingsSchema}
      >
        {({
          isValid,
          values,
          setFieldValue,
          handleBlur,
          errors,
          resetForm,
        }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            handleBlur(e);
          };
          return (
            <div>
              {isEditing ? (
                <>
                  <Input
                    errorMessage={
                      errors['company_id'] ? errors['company_id'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'company_id');
                    }}
                    type="number"
                    disabled={isLoading}
                    placeholder={t('Logyx company ID')}
                    height={'43rem'}
                    name="company_id"
                    wrapperStyles={{ marginTop: '12rem', width: '50%' }}
                  />
                  <Input
                    errorMessage={errors['api_url'] ? errors['api_url'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'api_url');
                    }}
                    name="api_url"
                    disabled={isLoading}
                    placeholder={'API URL'}
                    height={'43rem'}
                    wrapperStyles={{ marginTop: '30rem', width: '50%' }}
                  />
                  <Input
                    errorMessage={errors['api_key'] ? errors['api_key'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'api_key');
                    }}
                    name="api_key"
                    disabled={isLoading}
                    placeholder={t('API Key')}
                    height={'43rem'}
                    wrapperStyles={{ marginTop: '30rem', width: '50%' }}
                  />
                </>
              ) : (
                <Column>
                  <Label>{t('Logyx company ID')}</Label>
                  <LabelValue isRed={!values.company_id}>
                    {values.company_id ? values.company_id : t('Undefined')}
                  </LabelValue>
                  <Label>API URL</Label>
                  <LabelValue isRed={!values.api_url}>
                    {values.api_url ? values.api_url : t('Undefined')}
                  </LabelValue>
                  <Label>{t('API Key')}</Label>
                  <LabelValue isRed={!values.api_key}>
                    {values.api_key ? values.api_key : t('Undefined')}
                  </LabelValue>
                </Column>
              )}
              <MarginTop30>
                <ButtonsWrapper>
                  {isEditing && (
                    <Button
                      disabled={isLoading}
                      label={t('Cancel')}
                      secondary
                      width="150rem"
                      onClick={() => {
                        setIsEditing(false);
                        resetForm();
                      }}
                    />
                  )}
                  <Button
                    disabled={!isValid || isLoading}
                    label={isEditing ? t('Save') : t('Change')}
                    primary
                    width="150rem"
                    onClick={() => {
                      if (isEditing) {
                        handleEditCompanySettings(transformEmptyValues(values));
                      } else {
                        setIsEditing(true);
                      }
                    }}
                  />
                </ButtonsWrapper>
              </MarginTop30>
            </div>
          );
        }}
      </Formik>
    </Card>
  );
};

export default LogyxIntegrationCard;
