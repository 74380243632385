import Button from 'components/Button/Button';
import Spinner from 'components/Spinner/Spinner';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import {
  Container,
  Subtitle,
  Tab,
  TabContainer,
  Text,
} from '../SignUpPage.styled';
import {
  IBilling,
  ILicense,
  ISignUpPlanDetails,
  IUserLicense,
  UserLicenseType,
} from '../types';
import { BlueprintPlanCard } from './BlueprintPlanCard/BlueprintPlanCard';
import {
  useGetCompanyBilling,
  useGetCompanyLicenses,
  useGetUserLicenses,
} from './hooks';
import {
  ButtonsWrapper,
  CardContainer,
  Column,
  Row,
  UserCounterWrapper,
} from './PaymentPlanTab.styled';
import { UserCounter } from './UserCounter/UserCounter';

interface IPaymentPlanTabProps {
  onForward: (planDetails: ISignUpPlanDetails) => void;
  onBack: () => void;
  isLoading: boolean;
}

export const PaymentPlanTab = ({
  onBack,
  onForward,
  isLoading,
}: IPaymentPlanTabProps) => {
  const { t } = useTranslation();
  const [selectedBillingType, setSelectedBillingType] = useState<string>('1');
  const [selectedLicenseType, setSelectedLicenseType] = useState<number>(1);
  const [officeUsers, setOfficeUsers] = useState<string[]>([]);
  const [fieldUsers, setFieldUsers] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(true);

  const { data: licensesData, isLoading: isLoadingCompanyLicenses } =
    useGetCompanyLicenses();
  const { data: billingData, isLoading: isLoadingCompanyBilling } =
    useGetCompanyBilling();
  const { data: userLicensesData, isLoading: isLoadingUserLicenses } =
    useGetUserLicenses();

  const isFieldWorkerEnabled = licensesData?.licenses?.some(
    (license: ILicense) =>
      license.id === selectedLicenseType && license.is_field_worker_enabled
  );
  const isLogyxEnabled = licensesData?.licenses?.some(
    (license: ILicense) =>
      license.id === selectedLicenseType && license.is_logyx_enabled
  );

  const isLoadingData =
    isLoadingCompanyLicenses ||
    isLoadingCompanyBilling ||
    isLoadingUserLicenses;

  return (
    <>
      <Container>
        <Subtitle>{t('Choose Plan')}</Subtitle>
        <Text>
          {`${t(
            'Choose your preferred Blueprint plan and add the e-mail of the users'
          )}.`}
        </Text>
        <TabContainer>
          {billingData?.billing?.map((billing: IBilling) => {
            return (
              <Tab
                key={billing.value}
                onClick={() => setSelectedBillingType(billing.value)}
                active={selectedBillingType === billing.value}
              >
                {t(capitalizeFirstLetter(billing.name))}
              </Tab>
            );
          })}
        </TabContainer>
      </Container>
      {isLoadingData ? (
        <Spinner />
      ) : (
        <Column>
          <CardContainer>
            {licensesData?.licenses?.map((license: ILicense, index: number) => {
              return (
                <BlueprintPlanCard
                  key={`plan-card-${index}`}
                  license={license}
                  selectedLicenseType={selectedLicenseType}
                  setSelectedLicenseType={setSelectedLicenseType}
                />
              );
            })}
          </CardContainer>
          <Row>
            <UserCounterWrapper>
              {isLogyxEnabled && (
                <UserCounter
                  title={t('More Office Users')}
                  price={
                    userLicensesData?.licenses?.find(
                      (license: IUserLicense) =>
                        license.name === UserLicenseType.OFFICE_WORKER
                    ).price
                  }
                  emailPlaceholder={t('Office User E-mail')}
                  inputs={officeUsers}
                  setInputs={setOfficeUsers}
                  setIsValid={setIsValid}
                  selectedLicenseType={selectedLicenseType}
                />
              )}
              {isFieldWorkerEnabled && (
                <UserCounter
                  title={t('More Field Users')}
                  price={
                    userLicensesData?.licenses?.find(
                      (license: IUserLicense) =>
                        license.name === UserLicenseType.FIELD_WORKER
                    ).price
                  }
                  emailPlaceholder={t('Field User E-mail')}
                  inputs={fieldUsers}
                  setInputs={setFieldUsers}
                  setIsValid={setIsValid}
                  selectedLicenseType={selectedLicenseType}
                />
              )}
            </UserCounterWrapper>
          </Row>
          <ButtonsWrapper>
            <Button
              width={'280rem'}
              onClick={() => {
                onBack();
              }}
              label={t('Back')}
              secondary
            />
            <Button
              disabled={!isValid || isLoading}
              onClick={() => {
                onForward({
                  billing_type: selectedBillingType,
                  license_type: selectedLicenseType,
                  office_users: officeUsers,
                  field_users: fieldUsers,
                });
              }}
              label={t('Proceed to payment')}
              primary
              width={'280rem'}
            />
          </ButtonsWrapper>
        </Column>
      )}
    </>
  );
};
