import { Option } from 'components/Select/type';
import { ICurrency } from 'store/Common/types';

export const useGetQuotationTypeOptions = () => {
  const quotationTypeOptions: Option[] = [
    {
      value: '1',
      label: 'Definitive',
      key: 'definitive',
    },
    {
      value: '2',
      label: 'Draft',
      key: 'draft',
    },
  ];
  return quotationTypeOptions;
};

export const useGetCurrencyOptions = (currencies: ICurrency[]) => {
  const currencyTypeOptions: Option[] = currencies?.map(
    (currency: ICurrency) => {
      return {
        value: currency.id.toString(),
        label: `${currency?.name} (${currency.code})`,
        key: currency.id.toString(),
      };
    }
  );

  return currencyTypeOptions;
};
