import { DiscountType } from 'types/Discount.types';
import { ILabel } from 'types/EntityLabel.types';

export interface ICreateQuotationOrderDetails {
  isDraft?: boolean | null;
  reference: string;
  downPayment: string;
  daysValid?: string;
  workingHours: string;
  workingHoursRate: number | string;
  workingHoursDiscount: string;
  workingHoursDiscountType: DiscountType;
  discount: string;
  discountType: DiscountType;
  labels: ILabel[];
  recurringTypeId?: string;
  recurringPeriodId?: string;
  paymentConditionText?: string;
  paymentConditionId?: string;
  downpaymentConditionText?: string;
}

export const PAYMENT_CONDITIONS_PER_PAGE = 10;
