import { marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 40rem;
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
     flex-direction: column;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 920rem;

  ${respondTo.bigPhone`
     max-width: 100%;
  `}
`;

export const Row = styled.div`
  display: flex;
`;

export const UserCounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40rem;
  margin-top: 40rem;
  margin-bottom: 40rem;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  justify-self: center;
  align-self: center;
  margin-bottom: 40rem;
  gap: 20rem;

  ${respondTo.bigPhone`
    flex-direction: column-reverse;
  `}
`;
