import { COLORS, marginMd, marginSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import CustomSelect from 'components/Select/FormikSelect';
import styled, { css } from 'styled-components';

interface StyledProps {
  active: boolean;
  finished: boolean;
  onClick: any;
}

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 15rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 580rem;

  ${respondTo.bigPhone`
     max-width: 100%;
  `}
`;

export const Logo = styled.img`
  width: 400rem;
  margin-top: ${marginSm};
  margin-bottom: 40rem;

  @media screen and (max-width: 1440px) {
    margin-bottom: 30rem;
  }
`;

export const Title = styled.h1`
  font-size: 50rem;
  line-height: 75rem;
  font-weight: 300;
  margin-bottom: ${marginSm};
  color: ${COLORS.BLACK};
`;

export const Subtitle = styled.h2`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.PRIMARY};
  margin-bottom: ${marginSm};
`;

export const Text = styled.span`
  font-size: 16rem;
  line-height: 25rem;
  font-weight: 300;
  color: ${COLORS.GREY_900};
  margin-bottom: 40rem;
  text-align: center;

  ${respondTo.bigPhone`
     width: 100%;
     padding-left: 20rem;
     padding-right: 20rem;
  `}
`;

export const TextButton = styled(Text)`
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
  cursor: pointer;
  margin-left: ${marginXs};
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1170rem;
  background: ${COLORS.WHITE};
  margin-bottom: 30rem;
  border-radius: 0rem 10rem 10rem 0rem;

  ${respondTo.bigPhone`
     max-width: 100%;
     font-size: 12rem;
  `}
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50rem;
  background-color: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.GREY_200};
  border-radius: 0rem 10rem 10rem 0rem;
  border-top: 0;
  border-bottom: 0;
  border-left: none;
  color: ${COLORS.BLACK};
  width: 300rem;
  ${(props: StyledProps) =>
    props.active &&
    css`
      border-radius: 0rem 10rem 10rem 0rem;
      color: ${COLORS.PRIMARY};
      border: 1rem solid ${COLORS.PRIMARY};
    `}
  ${(props: StyledProps) =>
    props.finished &&
    css`
      color: ${COLORS.WHITE};
      background-color: ${COLORS.PRIMARY};
      border: none;
      border-radius: 0;
    `}
    ${(props: StyledProps) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

    ${(props: StyledProps) =>
    props.onClick &&
    props.active &&
    css`
      background-color: ${COLORS.PRIMARY};
      color: ${COLORS.WHITE};
    `}
`;
export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;

  ${respondTo.bigPhone`
     width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  margin-top: 40rem;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  justify-self: center;
  align-self: center;
  margin-top: 40rem;
  gap: 20rem;

  ${respondTo.bigPhone`
     flex-direction: column-reverse;
  `}
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;

  ${respondTo.bigPhone`
    flex-direction: column;
  `}
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 280rem;

  ${respondTo.bigPhone`
     width: 100%;
  `}
`;

export const LanguageSwitcherWrapper = styled.div`
  margin-left: 20rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${marginMd};
  width: 100%;
  max-width: 900rem;
`;
