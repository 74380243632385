export const INITIAL_VERIFICATION_DETAILS = {
  email: '',
  vat: '',
};

export const INITIAL_COMPANY_DETAILS = {
  company_name: '',
  street: '',
  house_number: '',
  company_vat: '',
  company_coc: '',
  country_code: '',
  phone: '',
  website: '',
  zip_code: '',
  city: '',
};

export const INITIAL_BANK_DETAILS = {
  bank_name: '',
  bank_code: '',
  account_holder_name: '',
  iban: '',
};

export const INITIAL_PLAN_DETAILS = {
  license_type: 1,
  billing_type: '1',
  field_users: [],
  office_users: [],
};

export const labels = {
  1: ['Purchase Orders', 'Sales Orders', 'Invoices', 'Quotations', 'Planning'],
  2: ['Basic Package', 'Logyx', 'Verano models'],
  4: ['Plus Package', 'Field Worker View', 'Work Reports'],
};

export const boldLabels = [
  'Logyx',
  'Verano models',
  'Field Worker View',
  'Work Reports',
];

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
