import * as Yup from 'yup';

export const editEmailCompanySettingsSchema = () => {
  return Yup.object().shape({
    due_date_start_reminder: Yup.number()
      .typeError('Must be a number')
      .nullable(),
    due_date_second_reminder: Yup.number()
      .typeError('Must be a number')
      .nullable(),
    due_date_third_reminder: Yup.number()
      .typeError('Must be a number')
      .nullable(),
    due_date_reminder_sent_time: Yup.string().nullable(),
  });
};
