import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { Option } from 'components/Select/type';
import { queryClient } from 'index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  createEmailTemplate,
  createEmailTemplateGeneralTheme,
  deactivateEmailTemplate,
  getEmailTemplates,
  getEmailTemplateVariables,
  testEmailTemplate,
} from 'services/Settings/SettingsService';
import { defaultTemplate } from './constants';
import {
  EmailTemplateDetails,
  EmailTemplatesTab,
  ICreateEmailTemplateData,
  ICreateEmailTemplateGeneralThemeData,
  IUploadDefaultAttachmentTypeDTO,
} from './types';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import {
  getDefaultAttachmentMailFiles,
  uploadDefaultAttachmentMailFile,
} from 'services/DefaultAttachment/DefaultAttachmentService';

export const useGetFontSizeOptions = () => {
  const fontsizeOptions: Option[] = [
    { value: '32px', label: 'Huge', key: 'huge' },
    { value: '24px', label: 'Large', key: 'large' },
    { value: '16px', label: 'Normal', key: 'normal' },
    { value: '12px', label: 'Small', key: 'small' },
  ];
  return fontsizeOptions;
};

export const useGetEmailTemplates = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_EMAIL_TEMPLATES],
    queryFn: () => {
      return getEmailTemplates();
    },
  });

export const useGetEmailTemplateVariables = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_EMAIL_TEMPLATE_VARIABLES],
    queryFn: () => {
      return getEmailTemplateVariables();
    },
  });

export const useCreateEmailTemplate = () => {
  const { t } = useTranslation();
  return useMutation(
    (createEmailTemplateData: ICreateEmailTemplateData) =>
      createEmailTemplate(createEmailTemplateData),
    {
      onSuccess: () => {
        toast.success(t('Email template created'), {
          className: ReactMutationKeys.CREATE_EMAIL_TEMPLATE,
        });
        queryClient.invalidateQueries(ReactQueryKeys.GET_EMAIL_TEMPLATES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_EMAIL_TEMPLATE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CREATE_EMAIL_TEMPLATE,
    }
  );
};

export const useCreateEmailTemplateGeneralTheme = () => {
  const { t } = useTranslation();
  return useMutation(
    (
      createEmailTemplateGeneralThemeData: ICreateEmailTemplateGeneralThemeData
    ) => createEmailTemplateGeneralTheme(createEmailTemplateGeneralThemeData),
    {
      onSuccess: () => {
        toast.success(t('Email template general theme updated'), {
          className: ReactMutationKeys.CREATE_EMAIL_TEMPLATE_GENERAL_THEME,
        });
        queryClient.invalidateQueries(ReactQueryKeys.GET_EMAIL_TEMPLATES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_EMAIL_TEMPLATE_GENERAL_THEME,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CREATE_EMAIL_TEMPLATE_GENERAL_THEME,
    }
  );
};

export const useTestEmailTemplate = () => {
  const { t } = useTranslation();
  return useMutation((templateId: number) => testEmailTemplate(templateId), {
    onSuccess: () => {
      toast.success(t('Test email is being sent'), {
        className: ReactMutationKeys.TEST_EMAIL_TEMPLATE,
      });
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.TEST_EMAIL_TEMPLATE,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.TEST_EMAIL_TEMPLATE,
  });
};

export const useDeactivateEmailTemplate = () => {
  const { t } = useTranslation();
  return useMutation(
    (templateId: number) => deactivateEmailTemplate(templateId),
    {
      onSuccess: () => {
        toast.success(t('Default template applied'), {
          className: ReactMutationKeys.DEACTIVATE_EMAIL_TEMPLATE,
        });
        queryClient.invalidateQueries(ReactQueryKeys.GET_EMAIL_TEMPLATES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DEACTIVATE_EMAIL_TEMPLATE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.DEACTIVATE_EMAIL_TEMPLATE,
    }
  );
};

export const useUploadDefaultAttachmentMailFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (uploadDefaultAttachmentDTO: IUploadDefaultAttachmentTypeDTO) => {
      const fileFormData = new FormData();

      if (
        uploadDefaultAttachmentDTO.files &&
        uploadDefaultAttachmentDTO.files.length
      ) {
        uploadDefaultAttachmentDTO.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }

      return uploadDefaultAttachmentMailFile(
        uploadDefaultAttachmentDTO.defaultAttachmentTypeId,
        fileFormData
      );
    },
    {
      onSuccess: () => {
        toast.success(t('Successfully uploaded default attachment'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_DEFAULT_ATTACHMENT_MAIL_FILES
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_DEFAULT_ATTACHMENT_MAIL_FILE,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.UPLOAD_DEFAULT_ATTACHMENT_MAIL_FILE,
    }
  );
};

export const useGetDefaultAttachmentMailFiles = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_DEFAULT_ATTACHMENT_MAIL_FILES],
    queryFn: () => {
      return getDefaultAttachmentMailFiles();
    },
  });

export const useGetVariablesOptions = (variables: any[]) => {
  const variableOptions: Option[] = Object.keys(variables).map(
    (variable: string) => {
      return {
        value: `{{${variable}}}`,
        label: capitalizeFirstLetter(variable.replaceAll('_', ' ')),
        key: variable,
      };
    }
  );
  return variableOptions;
};

export const useGetFontOptions = () => {
  const fontOptions: Option[] = [
    {
      value: 'Helvetica',
      label: 'Helvetica',
    },
    {
      value: 'Arial',
      label: 'Arial',
    },
    {
      value: 'Roboto',
      label: 'Roboto',
    },
    {
      value: 'Verdana',
      label: 'Verdana',
    },
  ];
  return fontOptions;
};

export const useGetInitialTemplates = (
  templates: any[],
  EmailTemplateTypeId: { [key: string]: number }
) => {
  const draftQuotationTemplate = templates?.find(
    (template: any) =>
      template.type.id === EmailTemplateTypeId.DRAFT_QUOTATION &&
      template.is_active
  );
  const definitiveQuotationTemplate = templates?.find(
    (template: any) =>
      template.type.id === EmailTemplateTypeId.DEFINITIVE_QUOTATION &&
      template.is_active
  );
  const salesOrderTemplate = templates?.find(
    (template: any) =>
      template.type.id === EmailTemplateTypeId.ORDER_CONFIRMATION &&
      template.is_active
  );
  const salesInvoiceTemplate = templates?.find(
    (template: any) =>
      template.type.id === EmailTemplateTypeId.SALES_INVOICE &&
      template.is_active
  );
  const salesCreditInvoiceTemplate = templates?.find(
    (template: any) =>
      template.type.id === EmailTemplateTypeId.SALES_CREDIT_INVOICE &&
      template.is_active
  );
  const downPaymentInvoiceTemplate = templates?.find(
    (template: any) =>
      template.type.id === EmailTemplateTypeId.DOWN_PAYMENT_INVOICE &&
      template.is_active
  );
  const signedQuotationTemplate = templates?.find(
    (template: any) =>
      template.type.id === EmailTemplateTypeId.SIGNED_QUOTATION &&
      template.is_active
  );

  const dueDateFirstReminderTemplate = templates?.find(
    (template: any) =>
      template.type.id ===
        EmailTemplateTypeId.SALES_INVOICE_DUE_DATE_FIRST_REMINDER &&
      template.is_active
  );

  const dueDateSecondReminderTemplate = templates?.find(
    (template: any) =>
      template.type.id ===
        EmailTemplateTypeId.SALES_INVOICE_DUE_DATE_SECOND_REMINDER &&
      template.is_active
  );

  const dueDateThirdReminderTemplate = templates?.find(
    (template: any) =>
      template.type.id ===
        EmailTemplateTypeId.SALES_INVOICE_DUE_DATE_THIRD_REMINDER &&
      template.is_active
  );

  return {
    draftQuotationTemplate,
    definitiveQuotationTemplate,
    salesOrderTemplate,
    salesInvoiceTemplate,
    salesCreditInvoiceTemplate,
    downPaymentInvoiceTemplate,
    signedQuotationTemplate,
    dueDateFirstReminderTemplate,
    dueDateSecondReminderTemplate,
    dueDateThirdReminderTemplate,
  };
};

export const useGetTemplateDetails = () => {
  const { t } = useTranslation();
  const [draftQuotationEmailDetails, setDraftQuotationEmailDetails] =
    useState<EmailTemplateDetails>({
      headline: t(`${defaultTemplate.DRAFT_QUOTATION_HEADLINE}`),
      body: t(`${defaultTemplate.DRAFT_QUOTATION_BODY}`),
    });
  const [definitiveQuotationEmailDetails, setDefinitiveQuotationEmailDetails] =
    useState<EmailTemplateDetails>({
      headline: t(`${defaultTemplate.DEFINITIVE_QUOTATION_HEADLINE}`),
      body: t(`${defaultTemplate.DEFINITIVE_QUOTATION_BODY}`),
    });
  const [salesOrderEmailDetails, setSalesOrderEmailDetails] =
    useState<EmailTemplateDetails>({
      headline: t(`${defaultTemplate.SALES_ORDER_HEADLINE}`),
      body: t(`${defaultTemplate.SALES_ORDER_BODY}`),
    });
  const [salesInvoiceEmailDetails, setSalesInvoiceEmailDetails] =
    useState<EmailTemplateDetails>({
      headline: t(`${defaultTemplate.SALES_INVOICE_HEADLINE}`),
      body: t(`${defaultTemplate.SALES_INVOICE_BODY}`),
    });
  const [salesCreditInvoiceEmailDetails, setSalesCreditInvoiceEmailDetails] =
    useState<EmailTemplateDetails>({
      headline: t(`${defaultTemplate.SALES_CREDIT_INVOICE_HEADLINE}`),
      body: t(`${defaultTemplate.SALES_CREDIT_INVOICE_BODY}`),
    });
  const [downPaymentInvoiceEmailDetails, setDownPaymentInvoiceEmailDetails] =
    useState<EmailTemplateDetails>({
      headline: t(`${defaultTemplate.DOWN_PAYMENT_INVOICE_HEADLINE}`),
      body: t(`${defaultTemplate.DOWN_PAYMENT_INVOICE_BODY}`),
    });
  const [signedQuotationEmailDetails, setSignedQuotationEmailDetails] =
    useState<EmailTemplateDetails>({
      headline: t(`${defaultTemplate.SIGNED_QUOTATION_HEADLINE}`),
      body: t(`${defaultTemplate.SIGNED_QUOTATION_BODY}`),
    });
  const [
    dueDateFirstReminderEmailDetails,
    setDueDateFirstReminderEmailDetails,
  ] = useState<EmailTemplateDetails>({
    headline: t(
      `${defaultTemplate.SALES_INVOICE_DUE_DATE_FIRST_REMINDER_HEADLINE}`
    ),
    body: t(`${defaultTemplate.SALES_INVOICE_DUE_DATE_FIRST_REMINDER_BODY}`),
  });
  const [
    dueDateSecondReminderEmailDetails,
    setDueDateSecondReminderEmailDetails,
  ] = useState<EmailTemplateDetails>({
    headline: t(
      `${defaultTemplate.SALES_INVOICE_DUE_DATE_SECOND_REMINDER_HEADLINE}`
    ),
    body: t(`${defaultTemplate.SALES_INVOICE_DUE_DATE_SECOND_REMINDER_BODY}`),
  });
  const [
    dueDateThirdReminderEmailDetails,
    setDueDateThirdReminderEmailDetails,
  ] = useState<EmailTemplateDetails>({
    headline: t(
      `${defaultTemplate.SALES_INVOICE_DUE_DATE_THIRD_REMINDER_HEADLINE}`
    ),
    body: t(`${defaultTemplate.SALES_INVOICE_DUE_DATE_THIRD_REMINDER_BODY}`),
  });
  return {
    draftQuotationEmailDetails,
    definitiveQuotationEmailDetails,
    salesOrderEmailDetails,
    salesInvoiceEmailDetails,
    salesCreditInvoiceEmailDetails,
    downPaymentInvoiceEmailDetails,
    signedQuotationEmailDetails,
    dueDateFirstReminderEmailDetails,
    dueDateSecondReminderEmailDetails,
    dueDateThirdReminderEmailDetails,
    setDraftQuotationEmailDetails,
    setDefinitiveQuotationEmailDetails,
    setSalesOrderEmailDetails,
    setSalesInvoiceEmailDetails,
    setSalesCreditInvoiceEmailDetails,
    setDownPaymentInvoiceEmailDetails,
    setSignedQuotationEmailDetails,
    setDueDateFirstReminderEmailDetails,
    setDueDateSecondReminderEmailDetails,
    setDueDateThirdReminderEmailDetails,
  };
};

export const useGetSelectedFilterTypes = () => {
  const [
    selectedDraftQuotationFilterType,
    setSelectedDraftQuotationFilterType,
  ] = useState<EmailTemplatesTab>();
  const [
    selectedDefinitiveQuotationFilterType,
    setSelectedDefinitiveQuotationFilterType,
  ] = useState<EmailTemplatesTab>();
  const [selectedSalesOrderFilterType, setSelectedSalesOrderFilterType] =
    useState<EmailTemplatesTab>();
  const [selectedSalesInvoiceFilterType, setSelectedSalesInvoiceFilterType] =
    useState<EmailTemplatesTab>();
  const [
    selectedSalesCreditInvoiceFilterType,
    setSelectedSalesCreditInvoiceFilterType,
  ] = useState<EmailTemplatesTab>();
  const [
    selectedDownPaymentInvoiceFilterType,
    setSelectedDownPaymentInvoiceFilterType,
  ] = useState<EmailTemplatesTab>();

  const [
    selectedSignedQuotationFilterType,
    setSelectedSignedQuotationFilterType,
  ] = useState<EmailTemplatesTab>();

  const [
    selectedDueDateFirstReminderFilterType,
    setSelectedDueDateFirstReminderFilterType,
  ] = useState<EmailTemplatesTab>();

  const [
    selectedDueDateSecondReminderFilterType,
    setSelectedDueDateSecondReminderFilterType,
  ] = useState<EmailTemplatesTab>();

  const [
    selectedDueDateThirdReminderFilterType,
    setSelectedDueDateThirdReminderFilterType,
  ] = useState<EmailTemplatesTab>();

  return {
    selectedDraftQuotationFilterType,
    selectedDefinitiveQuotationFilterType,
    selectedSalesOrderFilterType,
    selectedSalesInvoiceFilterType,
    selectedSalesCreditInvoiceFilterType,
    selectedDownPaymentInvoiceFilterType,
    selectedSignedQuotationFilterType,
    selectedDueDateFirstReminderFilterType,
    selectedDueDateSecondReminderFilterType,
    selectedDueDateThirdReminderFilterType,
    setSelectedDraftQuotationFilterType,
    setSelectedDefinitiveQuotationFilterType,
    setSelectedSalesOrderFilterType,
    setSelectedSalesInvoiceFilterType,
    setSelectedSalesCreditInvoiceFilterType,
    setSelectedDownPaymentInvoiceFilterType,
    setSelectedSignedQuotationFilterType,
    setSelectedDueDateFirstReminderFilterType,
    setSelectedDueDateSecondReminderFilterType,
    setSelectedDueDateThirdReminderFilterType,
  };
};
