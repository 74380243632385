import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Card,
  CardTitle,
  Column,
  ExternalServiceLogo,
  Label,
  LabelValue,
  MarginTop30,
  RowSpaceBetween,
  SelectWrapper,
} from './TwinfieldIntegrationCard.styled';
import TwinfieldLogo from 'assets/images/ExternalServices/twinfieldLogo.png';
import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import {
  useAddTwinfieldCompanyInfo,
  useAuthTwinfield,
  useEditTwinfieldCompanyInfo,
  useGetOfficeCodes,
  useGetDownPaymentsAndCodes,
  useGetTwinfieldCompanyInfo,
  useGetGeneralLedgerDebtors,
} from '../hooks';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import Tooltip from 'components/Tooltip/Tooltip';

interface ITwinfieldIntegrationCardProps {
  companyId: number;
  initialAccountingConnectorTypeId: number;
}

const TwinfieldIntegrationCard = ({
  companyId,
  initialAccountingConnectorTypeId,
}: ITwinfieldIntegrationCardProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [companyCode, setCompanyCode] = useState<string>('');
  const [profitAndLossAccountCode, setProfitAndLossAccountCode] =
    useState<string>('');
  const [twinfieldCompanyId, setTwinfieldCompanyId] = useState<string>('');
  const [generalLedgerDebtors, setGeneralLedgerDebtors] = useState<string>('');
  const [downpaymentAccountSettable, setDownpaymentAccountSettable] =
    useState<string>('');
  const {
    data: companyCodeData,
    isLoading,
    isSuccess,
  } = useGetTwinfieldCompanyInfo(companyId);
  const { mutate: addCompanyCode } = useAddTwinfieldCompanyInfo(companyId);
  const { mutate: editCompanyCode } = useEditTwinfieldCompanyInfo(companyId);
  const { mutate: getDownPaymentsAndCodes, data: downPaymentsAndCodesData } =
    useGetDownPaymentsAndCodes(companyId);
  const { mutate: getGeneralLedgerDebtors, data: generalLedgerDebtorsData } =
    useGetGeneralLedgerDebtors(companyId);
  const { mutate: authTwinfield } = useAuthTwinfield();
  const { data: officeCodes } = useGetOfficeCodes(companyId);

  let downPaymentsAndCodesOptions = [];
  if (downPaymentsAndCodesData) {
    downPaymentsAndCodesOptions =
      downPaymentsAndCodesData.office_details.items.map((details: any) => ({
        value: details.Code,
        label: details.Code,
      }));
  }

  let generalLedgerDebtorsOptions = [];
  if (generalLedgerDebtorsData) {
    generalLedgerDebtorsOptions =
      generalLedgerDebtorsData.office_details.items.map((details: any) => ({
        value: details.Code,
        label: details.Code,
      }));
  }

  useEffect(() => {
    if (isSuccess) {
      setCompanyCode(companyCodeData?.twinfield_company?.company_code);
      setProfitAndLossAccountCode(
        companyCodeData?.twinfield_company?.profit_and_loss_account_code
      );
      setGeneralLedgerDebtors(
        companyCodeData?.twinfield_company?.general_ledger_debtors
      );
      setDownpaymentAccountSettable(
        companyCodeData?.twinfield_company?.down_payment_account_settable
      );
      setTwinfieldCompanyId(companyCodeData?.twinfield_company?.id);
    }
  }, [isSuccess]);

  return (
    <Card>
      <RowSpaceBetween>
        <CardTitle>Twinfield</CardTitle>
        <ExternalServiceLogo src={TwinfieldLogo} />
      </RowSpaceBetween>
      {isEditing ? (
        <>
          <SelectWrapper>
            <Select
              name="company_code"
              placeholder={t('Company code')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              defaultValue={{ value: companyCode, label: companyCode }}
              onChange={(e: Option) => {
                setCompanyCode(e.value);
              }}
              options={officeCodes}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Tooltip
              placement="right"
              content={t(
                'Please enter at least 4 characters first, then the options will become visible'
              )}
            >
              <Select
                name="profit_and_loss_code"
                placeholder={t('Profit and loss account code')}
                isMulti={false}
                isSearchable={true}
                defaultValue={{
                  value: profitAndLossAccountCode,
                  label: profitAndLossAccountCode,
                }}
                onChange={(e: Option) => {
                  setProfitAndLossAccountCode(e.value);
                }}
                options={downPaymentsAndCodesOptions}
                onInputChange={(e: string) => {
                  if (e.length > 3) {
                    getDownPaymentsAndCodes({
                      companyCode,
                      selectSearch: e,
                      accountType: 'PNL',
                    });
                  }
                }}
              />
            </Tooltip>
          </SelectWrapper>
          <SelectWrapper>
            <Tooltip
              placement="right"
              content={t(
                'Please enter at least 4 characters first, then the options will become visible'
              )}
            >
              <Select
                name="general_ledger_debtors"
                placeholder={t('General ledger debtors')}
                isMulti={false}
                isSearchable={true}
                onChange={(e: Option) => {
                  setGeneralLedgerDebtors(e.value);
                }}
                defaultValue={{
                  value: generalLedgerDebtors,
                  label: generalLedgerDebtors,
                }}
                options={generalLedgerDebtorsOptions}
                onInputChange={(e: string) => {
                  if (e.length > 3) {
                    getGeneralLedgerDebtors({
                      companyCode,
                      selectSearch: e,
                      accountType: 'BAS',
                    });
                  }
                }}
              />
            </Tooltip>
          </SelectWrapper>
          <SelectWrapper>
            <Tooltip
              placement="right"
              content={t(
                'Please enter at least 4 characters first, then the options will become visible'
              )}
            >
              <Select
                name="downpayment_account"
                placeholder={t('Downpayment account settable')}
                isMulti={false}
                isSearchable={true}
                onChange={(e: Option) => {
                  setDownpaymentAccountSettable(e.value);
                }}
                defaultValue={{
                  value: downpaymentAccountSettable,
                  label: downpaymentAccountSettable,
                }}
                options={downPaymentsAndCodesOptions}
                onInputChange={(e: string) => {
                  if (e.length > 3) {
                    getDownPaymentsAndCodes({
                      companyCode,
                      selectSearch: e,
                      accountType: 'PNL',
                    });
                  }
                }}
              />
            </Tooltip>
          </SelectWrapper>
        </>
      ) : (
        <Column>
          <Label>{t('Company code')}</Label>
          <LabelValue>
            {companyCodeData?.twinfield_company?.company_code}
          </LabelValue>
          <Label>{t('Profit and loss account code')}</Label>
          <LabelValue>
            {companyCodeData?.twinfield_company?.profit_and_loss_account_code}
          </LabelValue>
          <Label>{t('General ledger debtors')}</Label>
          <LabelValue>
            {companyCodeData?.twinfield_company?.general_ledger_debtors}
          </LabelValue>
          <Label>{t('Downpayment account settable')}</Label>
          <LabelValue>
            {companyCodeData?.twinfield_company?.down_payment_account_settable}
          </LabelValue>
        </Column>
      )}
      <MarginTop30>
        <ButtonsWrapper>
          {isEditing && (
            <Button
              disabled={isLoading}
              label={t('Cancel')}
              secondary
              width="150rem"
              onClick={() => {
                setIsEditing(false);
              }}
            />
          )}
          <Button
            disabled={
              isLoading ||
              (isEditing && (!companyCode || !downpaymentAccountSettable))
            }
            label={isEditing ? t('Save') : t('Change')}
            primary
            width="150rem"
            onClick={() => {
              if (isEditing) {
                if (companyCodeData?.twinfield_company?.company_code)
                  editCompanyCode({
                    companyCode: companyCode || '',
                    profitAndLossAccountCode: profitAndLossAccountCode || '',
                    generalLedgerDebtors: generalLedgerDebtors || '',
                    downpaymentAccountSettable:
                      downpaymentAccountSettable || '',
                    id: twinfieldCompanyId || '',
                  });
                else
                  addCompanyCode({
                    companyCode: companyCode || '',
                    profitAndLossAccountCode: profitAndLossAccountCode || '',
                    generalLedgerDebtors: generalLedgerDebtors || '',
                    downpaymentAccountSettable:
                      downpaymentAccountSettable || '',
                  });
                setIsEditing(false);
              } else {
                setIsEditing(true);
                setCompanyCode(
                  companyCodeData?.twinfield_company?.company_code
                );
                setProfitAndLossAccountCode(
                  companyCodeData?.twinfield_company
                    ?.profit_and_loss_account_code
                );
                setGeneralLedgerDebtors(
                  companyCodeData?.twinfield_company?.general_ledger_debtors
                );
                setDownpaymentAccountSettable(
                  companyCodeData?.twinfield_company
                    ?.down_payment_account_settable
                );
              }
            }}
          />
          {initialAccountingConnectorTypeId && !isEditing && (
            <Button
              label={t('Connect')}
              primary
              width="150rem"
              onClick={() => {
                authTwinfield(companyId);
              }}
            />
          )}
        </ButtonsWrapper>
      </MarginTop30>
    </Card>
  );
};

export default TwinfieldIntegrationCard;
