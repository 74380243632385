export enum EditCompanyDataTab {
  DETAILS = 'Details',
  ADDRESS = 'Address',
  BANK = 'Bank',
}

export interface IEditDetails {
  name: string;
  vat_number: string;
  coc_number: string;
  phone_number: string;
  website: string;
  email: string;
}

export interface IEditAddress {
  street: string;
  house_number: string;
  zip_code: string;
  city: string;
  state: string;
  cca2: string;
}

export interface IEditBank {
  bank_account_holder: string;
  bank_code_number: string;
  bank_iban: string;
  bank_name: string;
}

export interface IEditCompanyDataDTO {
  name: string;
  vat_number: string;
  kvk_number: string;
  website: string;
  email: string;
  phone_number: string;
  address?: IEditAddress;
  bank_info?: IEditBank;
}
