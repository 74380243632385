export const tooltipContent = {
  'Payment due invoice': 'Number of due days before an invoice can be paid',
  'Default new quotation type':
    'Whether a new quotation will be draft or definitive upon creation',
  'Due date first reminder':
    'Number of days after the due invoice before it is sent',
  'Due date second reminder':
    'Number of days after the due invoice before it is sent',
  'Due date third reminder':
    'Number of days after the due invoice before it is sent',
  'Appointment upper interval':
    'Specify the number of minutes after the appointment start time during which the field worker can still arrive',
  'Appointment lower interval':
    'Specify the number of minutes before the appointment start time during which the field worker can arrive',
  'Email copy': 'Copies of all outgoing emails are sent here',
  'Invoice copy': 'Copies of outgoing invoice emails are sent here',
  'Order definitive default note':
    'This is a note automatically included when sending an order confirmation',
  'Field worker notification email':
    'You will receive a notification at this email address when a field report becomes available',
  'Quotation notification email':
    'You will receive a notification at this email address when a quote has been signed',
  'Email content footer address':
    'This is the email address that appears at the bottom of outgoing emails',
};
