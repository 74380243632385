import { IReorderLinesRequestDTO } from 'components/DragAndDrop/DraggableLines/hooks';
import { SortDirection } from 'components/Table/constants';
import { Moment } from 'moment';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { store } from 'store/store';
import {
  ICreateFwAppointmentLineDTO,
  IEditFwAppointmentLineDTO,
} from 'types/Appointment.types';

const BASE_URL = '/v1/field-worker/appointments/';

export const getFwAppointments = async (
  dateFrom: Moment,
  dateTo: Moment,
  searchBy: string,
  sortBy?: string,
  sortDirection?: string,
  selectedType?: string
): Promise<any> => {
  const queryParamsObj = {
    date_from: `${dateFrom.format('YYYY-MM-DD')} 00:00:00`,
    date_to: `${dateTo.format('YYYY-MM-DD')} 00:00:00`,
    filter_types: selectedType ? [selectedType] : null,
    search: searchBy,
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection !== SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSingleFwAppointment = async (
  appointmentId: string
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getFwAppointmentFiles = async (
  appointmentId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${appointmentId}/files`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const uploadFwAppointmentFile = async (
  id: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${id}/files`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const getFwAppointmentIntervals = async (
  appointmentId: string
): Promise<any> => {
  const url = BASE_URL + `${appointmentId}/intervals`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const startFwAppointmentInterval = async (
  appointmentId: string
): Promise<any> => {
  const url = BASE_URL + `${appointmentId}/intervals/start-for-all`;
  const { data } = await apiClient.post<any>(url);
  return data.data;
};

export const stopFwAppointmentInterval = async (
  appointmentId: string
): Promise<any> => {
  const url = BASE_URL + `${appointmentId}/intervals/stop-for-all`;
  const { data } = await apiClient.post<any>(url);
  return data.data;
};

export const pauseFwAppointmentInterval = async (
  appointmentId: string
): Promise<any> => {
  const url = BASE_URL + `${appointmentId}/intervals/pause-for-all`;
  const { data } = await apiClient.post<any>(url);
  return data.data;
};

export const resumeFwAppointmentInterval = async (
  appointmentId: string
): Promise<any> => {
  const url = BASE_URL + `${appointmentId}/intervals/resume-for-all`;
  const { data } = await apiClient.post<any>(url);
  return data.data;
};

export const createFwAppointmentLine = async (
  appointmentId: string,
  createFwAppointmentLine: ICreateFwAppointmentLineDTO
) => {
  const url = BASE_URL + `${appointmentId}/staged-lines`;
  const { data } = await apiClient.post<any>(url, createFwAppointmentLine);
  return data.data;
};

export const editFwAppointmentLine = async (
  appointmentId: string,
  appointmentLineId: string,
  editFwAppointmentLineDTO: IEditFwAppointmentLineDTO
) => {
  const url = BASE_URL + `${appointmentId}/staged-lines/${appointmentLineId}`;
  const { data } = await apiClient.patch<any>(url, editFwAppointmentLineDTO);
  return data.data;
};

export const deleteFwAppointmentLine = async (
  appointmentId: string,
  appointmentLineId: string
) => {
  const url = BASE_URL + `${appointmentId}/staged-lines/${appointmentLineId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const getFwAppointmentComments = async (
  id: string,
  page: number,
  perPage: number
) => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };

  // const url = BASE_URL + `${id}/comments`;
  const url = `/v1/appointments/${id}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get(fullUrl);

  return data.data;
};

export const addNewFwAppointmentComment = async (
  headline: string,
  description: string,
  isInternal: boolean,
  id: string
): Promise<any> => {
  // const url = BASE_URL + `${id}/comments`;
  const url = `/v1/appointments/${id}/comments`;
  const { data } = await apiClient.post<any>(url, {
    headline,
    description,
    is_internal: isInternal,
  });

  return data.data;
};

export const submitReport = async (
  appointmentId: string,
  formData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${appointmentId}/report`;
  const { data } = await apiClient.post(url, formData, { headers });

  return data.data;
};

export const getSingleAppointmentReport = async (
  appointmentId: string
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}/report`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getFwAppointmentInfoById = async (
  appointmentId: string
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const editAppointmentFile = async (
  appointmentId: string,
  fileId: string,
  newFileName: string
) => {
  const url = BASE_URL + `${appointmentId}/files/${fileId}`;
  const payload = {
    new_file_name: newFileName,
  };
  const { data } = await apiClient.patch(url, payload);

  return data.data;
};

export const deleteAppointmentFile = async (
  appointmentId: string,
  fileId: string
) => {
  const url = BASE_URL + `${appointmentId}/files/${fileId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const getFwAppointmentsDaySummary = async (
  dateFrom: Moment
): Promise<any> => {
  const queryParamsObj = {
    date_from: `${dateFrom.format('YYYY-MM-DD')} 00:00:00`,
    is_for_field_worker: store
      .getState()
      .commonInfo?.isFieldWorkerView?.toString(),
  };

  const url = BASE_URL + 'summary';
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getBlockedFwAppointments = async (): Promise<any> => {
  const url = `${BASE_URL}blocked-appointments`;

  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const reorderFwAppointmentLines = async (
  appointmentId: number,
  dto: IReorderLinesRequestDTO
) => {
  const url = BASE_URL + `${appointmentId}/reorder-lines`;
  const { data } = await apiClient.patch(url, dto);
  return data.data;
};

export const finishAppointment = async (
  appointmentId: string
): Promise<any> => {
  const url = `${BASE_URL}${appointmentId}/finish`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};
