import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { IAddEditPaymentConditionData } from 'types/PaymentCondition.types';

const BASE_URL = '/v1/payment-conditions/';

export const addPaymentCondition = async (
  addPaymentConditionData: IAddEditPaymentConditionData
): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(url, addPaymentConditionData);

  return data.data;
};

export const getPaymentConditions = async (
  page?: number,
  perPage?: number,
  searchBy?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const deletePaymentCondition = async (
  paymentConditionId: string
): Promise<any> => {
  const url = `${BASE_URL}${paymentConditionId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const editPaymentCondition = async (
  paymentConditionId: string,
  editPaymentConditionData: IAddEditPaymentConditionData
): Promise<any> => {
  const url = `${BASE_URL}${paymentConditionId}`;
  const { data } = await apiClient.patch<any>(url, editPaymentConditionData);

  return data.data;
};

export const getDefaultPaymentCondition = async (): Promise<any> => {
  const url = `${BASE_URL}default`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getPaymentConditionById = async (
  paymentConditionId?: string
): Promise<any> => {
  const url = `${BASE_URL}${paymentConditionId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const makeDefaultPaymentCondition = async (
  paymentConditionId: string
): Promise<any> => {
  const url = `${BASE_URL}${paymentConditionId}/default`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};
