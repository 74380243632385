import { marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 850rem;
`;

export const NoContentLabel = styled.label`
  margin-bottom: ${marginMd};
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  width: 100%;
`;
