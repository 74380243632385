import { QuotationStatuses, subMenuItems } from './constants';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import SingleQuotationRouter from 'navigation/SingleQuotation/SingleQuotationRouter';
import {
  AddLabelDropdownWrapper,
  ButtonContainer,
  ButtonWrapper,
  Container,
  HeaderWrapper,
  LabelContainer,
  LabelWrapper,
  LoaderWrapper,
  RouterWrapper,
  SpinnerWrapper,
  StatusOptionLabel,
} from './SingleQuotationPage.styled';
import { useTranslation } from 'react-i18next';
import {
  TitleContainer,
  PenImg,
  P,
  H3,
  P2,
  Wrapper,
  LeftSide,
  IconContainer,
  StatusOptionsContainer,
  StatusContainer,
} from './SingleQuotationPage.styled';
import Button from 'components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as SignatureUploaded } from 'assets/icons/signature-uploaded.svg';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import { RoutesConfig } from 'navigation/routes';
import {
  useCreateSalesOrder,
  useGetSingleQuotation,
  useChangeQuotationStatus,
  useExportQuotation,
  useGetQuotationActions,
  useGetSingleQuotationInfo,
  useGetQuotationLatestEmailStatus,
  useCancelRecurringQuotation,
} from './hooks';
import { SingleQuotationRoutes } from 'navigation/SingleQuotation/SingleQuotation.routes';
import { useEffect, useRef, useState } from 'react';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { FollowUpStatusModal } from './FollowUpStatusModal/FollowUpStatusModal';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { ThreeButtonsModal } from 'components/Modal/ThreeButtonsModal/ThreeButtonsModal';
import { isQuotationClosed } from './helpers';
import { useGetQuotationStatuses } from 'utils/hooks/useGetQuotationStatuses';
import { Option } from 'components/Select/type';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import { EmailStatuses } from 'types/Email.types';
import Tooltip from 'components/Tooltip/Tooltip';
import { AppointmentType } from 'types/Appointment.types';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Spinner from 'components/Spinner/Spinner';
import { QuotationTypeIdsEnum } from 'types/Quotations.types';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { ICreateAppointmentNavigationState } from '../PlanningPage/NewAppointmentModal/hooks';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import DraftQuotationSwitch from './SingleQuotationGeneralPage/DraftQuotationSwitch/DraftQuotationSwitch';
import {
  CaretLeft,
  DownloadSimple,
  EnvelopeSimple,
  PencilSimple,
  Signature,
  WarningCircle,
} from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { AddLabelDropdown } from 'components/AddLabelDropdown/AddLabelDropdown';
import { ILabelDataDTO, LabelEntityTypeId } from 'types/EntityLabel.types';
import {
  useDeleteLabelFromEntity,
  useGetEntityLabels,
} from '../LabelsPage/hooks';
import { formatLabelsData } from '../LabelsPage/helpers';
import Loader from 'components/Loader/Loader';
import { useCalculateHeaderHeight } from 'utils/hooks/useCalculateHeaderHeight';

const SingleQuotationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [isStatusDropdownOpen, setIsStatusDropdownOpen] =
    useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const [isAcceptedModalOpen, setIsAcceptedModalOpen] =
    useState<boolean>(false);
  const [isInTreatmentModalOpen, setIsInTreatmentModalOpen] =
    useState<boolean>(false);
  const [isScheduleAppointmentModalOpen, setIsScheduleAppointmentModalOpen] =
    useState<boolean>(false);
  const [isFollowUpStatusModalOpen, setIsFollowUpStatusModalOpen] =
    useState<boolean>(false);
  const [isNotAcceptedStatusModalOpen, setIsNotAcceptedStatusModalOpen] =
    useState<boolean>(false);
  const [isCopiedToNewStatusModalOpen, setIsCopiedToNewStatusModalOpen] =
    useState<boolean>(false);
  const [isCancelRecurringModalOpen, setIsCancelRecurringModalOpen] =
    useState<boolean>(false);

  const [
    isCreateSalesOrderIfDraftModalOpen,
    setIsCreateSalesOrderIfDraftModalOpen,
  ] = useState<boolean>(false);

  const canEditQuotation = useCan(Actions.EDIT_QUOTATION);
  const canCreateSalesOrder = useCan(Actions.CREATE_SALES_ORDER);
  const canCreateAppointment = useCan(Actions.CREATE_APPOINTMENT);

  const {
    data,
    isFetching: isFetchingQuotation,
    isError: isErrorQuotation,
  } = useGetSingleQuotation(typeof id === 'string' ? id : '');

  // Quotation email is sent to general address
  const emailDetails = {
    email: data?.customer_snapshot
      ? data?.customer_snapshot?.email
      : data?.customer?.email,
    type: 'General',
  };

  const { data: emailStatus } = useGetQuotationLatestEmailStatus(id!);

  const { data: infoData } = useGetSingleQuotationInfo(id!);

  const statusOptions: Option[] = useGetQuotationStatuses();

  const {
    mutate: changeQuotationStatus,
    isSuccess: changeQuotationStatusSuccess,
  } = useChangeQuotationStatus();

  const { mutate: exportQuotation, isLoading: isLoadingExport } =
    useExportQuotation();

  const { data: actionQuotations } = useGetQuotationActions(id!);

  useEffect(() => {
    setIsStatusDropdownOpen(false);
  }, [changeQuotationStatusSuccess]);

  const {
    mutate: createSalesOrder,
    isLoading: createSalesOrderIsLoading,
    data: newSalesOrder,
    isSuccess,
  } = useCreateSalesOrder();

  const { mutate: cancelRecurring, isLoading: cancelRecurringIsLoading } =
    useCancelRecurringQuotation();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/sales/${newSalesOrder?.sales_order?.id}`, {
        state: {
          createdFromQuotation: true,
        },
      });
    }
  }, [isSuccess]);

  const { data: labels, isLoading: isLoadingLabels } = useGetEntityLabels(
    id!,
    LabelEntityTypeId.QUOTATION
  );

  let formattedLabels: ILabelDataDTO[] = [];
  if (data) {
    formattedLabels = formatLabelsData(labels);
  }

  const { mutate: deleteLabelFromEntity } = useDeleteLabelFromEntity(
    LabelEntityTypeId.QUOTATION,
    Number(id)
  );

  const statusContainerWrapperRef = useRef<HTMLDivElement>();

  useOutsideAlerter(
    statusContainerWrapperRef,
    () => canEditQuotation && setIsStatusDropdownOpen(false)
  );

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isTablet } = useBreakpointFlag();

  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  const isDraft = data?.type_id === QuotationTypeIdsEnum.DRAFT;

  const { steps, showIntroductionGuide } = useRunTour(
    GuidePages.SINGLE_QUOTATION,
    id,
    true,
    true
  );

  const { headerHeight, headerWrapperRef } = useCalculateHeaderHeight();

  return !isErrorQuotation ? (
    <Container className="single-quotation-step-1">
      <SubSideMenu
        subMenuItems={subMenuItems}
        subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.quotation_info)}
      />
      <Wrapper>
        <HeaderWrapper
          ref={headerWrapperRef}
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                svg={CaretLeft}
                marginRight="15rem"
                weight="regular"
                size={15}
                color={COLORS.BLACK}
                onClick={() => navigate(RoutesConfig.Quotations.path)}
              />
              <H3>
                {t('Quotation')} {data?.number}
              </H3>
              <StatusContainer
                ref={statusContainerWrapperRef}
                className="single-quotation-step-2"
              >
                <P
                  onClick={() =>
                    canEditQuotation &&
                    setIsStatusDropdownOpen(!isStatusDropdownOpen)
                  }
                >
                  {capitalizeFirstLetter(t(data?.status?.name))}
                </P>
                {canEditQuotation && (
                  <PenImg>
                    <Icon
                      onClick={() =>
                        setIsStatusDropdownOpen(!isStatusDropdownOpen)
                      }
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      size={15}
                    />
                  </PenImg>
                )}
                {isStatusDropdownOpen && (
                  <StatusOptionsContainer>
                    {statusOptions.map((option) => {
                      return (
                        <StatusOptionLabel
                          isSelected={option.name === data?.status?.name}
                          key={option.id}
                          onClick={() => {
                            changeQuotationStatus({
                              id: data?.id || '',
                              status_id: option.id,
                            });
                            switch (option.name) {
                              case QuotationStatuses.ACCEPTED:
                                setIsAcceptedModalOpen(true);
                                break;
                              case QuotationStatuses.IN_TREATMENT:
                                if (
                                  data?.status?.name === QuotationStatuses.NEW
                                ) {
                                  setIsInTreatmentModalOpen(true);
                                }
                                break;
                              case QuotationStatuses.FOLLOW_UP:
                                setIsFollowUpStatusModalOpen(true);
                                break;
                              case QuotationStatuses.NOT_ACCEPTED:
                                setIsNotAcceptedStatusModalOpen(true);
                                break;
                              case QuotationStatuses.COPIED_TO_NEW:
                                setIsCopiedToNewStatusModalOpen(true);
                                break;
                            }
                          }}
                        >
                          {capitalizeFirstLetter(t(option.label))}
                        </StatusOptionLabel>
                      );
                    })}
                  </StatusOptionsContainer>
                )}
              </StatusContainer>
              <DraftQuotationSwitch
                editable={canEditQuotation}
                quotation={data}
              />
            </LeftSide>
            <IconContainer className="single-quotation-step-3">
              {actionQuotations && actionQuotations[0] && (
                <>
                  <Icon svg={WarningCircle} size={22} color={COLORS.PRIMARY} />
                  <P2>{`${t('Required')}: ${t(actionQuotations[0]?.name)}`}</P2>
                </>
              )}
              {!isQuotationClosed(data) && (
                <Tooltip content={t('Signature')} key={'signatureKey'}>
                  <Icon
                    data-testid="add-signature"
                    svg={
                      data?.signature_uploaded ? SignatureUploaded : Signature
                    }
                    size={25}
                    marginRight={marginXs}
                    color={COLORS.PRIMARY}
                    marginLeft={marginXs}
                    onClick={() =>
                      navigate(
                        SingleQuotationRoutes.SingleQuotationSignature.path
                      )
                    }
                  />
                </Tooltip>
              )}
              <Tooltip content={t('Open PDF')} key={'exportKey'}>
                {isLoadingExport ? (
                  <LoaderWrapper>
                    <Spinner size={20} />
                  </LoaderWrapper>
                ) : (
                  <Icon
                    disabled={!data}
                    data-testid="download-pdf"
                    svg={DownloadSimple}
                    color={COLORS.PRIMARY}
                    size={25}
                    marginRight={marginXs}
                    marginLeft={marginXs}
                    onClick={() => exportQuotation(data?.id)}
                  />
                )}
              </Tooltip>
              <Tooltip content={t('Send mail')} key={'emailKey'}>
                <Icon
                  data-testid="send-email"
                  svg={
                    data?.sent_to_customer
                      ? emailStatus?.status === EmailStatuses.SENT
                        ? MailSent
                        : emailStatus?.status === EmailStatuses.PENDING
                        ? MailPending
                        : MailFailed
                      : EnvelopeSimple
                  }
                  size={25}
                  onClick={() => setIsAddEmailNoteModalOpen(true)}
                  color={COLORS.PRIMARY}
                  marginLeft={marginXs}
                  marginRight={marginXs}
                />
              </Tooltip>
            </IconContainer>
          </TitleContainer>

          <LabelContainer $noLabels={formattedLabels?.length === 0}>
            <LabelWrapper>
              {isLoadingLabels ? (
                <SpinnerWrapper>
                  <Spinner size={15} />
                </SpinnerWrapper>
              ) : (
                <>
                  {formattedLabels.map((labelObj: ILabelDataDTO) => {
                    return (
                      <EntityLabel
                        key={labelObj.label.id}
                        label={labelObj.label}
                        tooltipContent={
                          labelObj.label.name.length > 15
                            ? labelObj.label.name
                            : labelObj.label.description
                        }
                        onDelete={() => {
                          deleteLabelFromEntity(labelObj.label.id);
                        }}
                      />
                    );
                  })}
                </>
              )}
            </LabelWrapper>
            <AddLabelDropdownWrapper
              $withoutMargin={formattedLabels?.length === 0}
            >
              <AddLabelDropdown
                entityType={LabelEntityTypeId.QUOTATION}
                entityId={id!}
              />
            </AddLabelDropdownWrapper>
          </LabelContainer>

          <ButtonContainer>
            <ButtonWrapper className="single-quotation-step-4">
              {data?.recurring_active && (
                <Button
                  onClick={() => {
                    setIsCancelRecurringModalOpen(true);
                  }}
                  label={t('Cancel recurring')}
                  width="200rem"
                  redOutline
                />
              )}
              <Button
                disabled={
                  isFetchingQuotation ||
                  isQuotationClosed(data) ||
                  !canCreateAppointment
                }
                onClick={() => {
                  const navigationState: ICreateAppointmentNavigationState = {
                    openModal: true,
                    type: AppointmentType.MEASURING,
                    quotation_id: id,
                    quotation_number: data?.number,
                  };
                  navigate(RoutesConfig.Planning.fullPath, {
                    state: navigationState,
                  });
                }}
                label={t('New measurement')}
                width="200rem"
                secondary
              />
              <Button
                disabled={
                  isFetchingQuotation ||
                  isQuotationClosed(data) ||
                  !canEditQuotation
                }
                onClick={() =>
                  navigate(RoutesConfig.NewQuotation.fullPath, {
                    state: {
                      prepopulateQuotationData: {
                        ...data,
                        labels: labels.map((labelData: any) => labelData.label),
                      },
                    },
                  })
                }
                label={t('New quotation')}
                width="200rem"
                secondary
              />

              <Button
                disabled={
                  isFetchingQuotation ||
                  createSalesOrderIsLoading ||
                  isQuotationClosed(data) ||
                  !canCreateSalesOrder
                }
                onClick={() =>
                  isDraft
                    ? setIsCreateSalesOrderIfDraftModalOpen(true)
                    : createSalesOrder({ quotationId: id! })
                }
                label={t('Create sales order')}
                width="200rem"
                primary
              />
            </ButtonWrapper>
          </ButtonContainer>
        </HeaderWrapper>

        <RouterWrapper
          headerHeight={headerHeight}
          routerWithoutPaddingBottom={routerWithoutPaddingBottom}
        >
          <SingleQuotationRouter />
        </RouterWrapper>
      </Wrapper>
      <ThreeButtonsModal
        isOpen={isInTreatmentModalOpen}
        setIsOpen={setIsInTreatmentModalOpen}
        onCancel={() => {
          setIsInTreatmentModalOpen(false);
          setIsScheduleAppointmentModalOpen(true);
        }}
        firstButtonLabel={t('No')}
        onFirstButton={() => {
          setIsInTreatmentModalOpen(false);
          setIsScheduleAppointmentModalOpen(true);
        }}
        secondButtonLabel={t('Download pdf')}
        onSecondButton={() => {
          exportQuotation(data?.id);
          setIsInTreatmentModalOpen(false);
          setIsScheduleAppointmentModalOpen(true);
        }}
        thirdButtonlabel={t('Send email with pdf')}
        onThirdButton={() => {
          setIsAddEmailNoteModalOpen(true);
          setIsInTreatmentModalOpen(false);
          setIsScheduleAppointmentModalOpen(true);
        }}
        title={''}
        description={t('Do you want to send the quotation confirmation') + '?'}
      />
      <YesOrNoModal
        pwId="yes-or-no-create-sales-order-modal"
        isOpen={isAcceptedModalOpen}
        setIsOpen={setIsAcceptedModalOpen}
        onYes={() => createSalesOrder({ quotationId: id! })}
        onNo={() => setIsAcceptedModalOpen(false)}
        title={''}
        description={t('Do you want to create a sales order') + '?'}
      />
      <YesOrNoModal
        pwId="yes-or-no-schedule-measurement-modal"
        isOpen={isScheduleAppointmentModalOpen}
        setIsOpen={setIsScheduleAppointmentModalOpen}
        disabled={!data}
        onYes={() => {
          const navigationState: ICreateAppointmentNavigationState = {
            openModal: true,
            type: AppointmentType.MEASURING,
            quotation_id: id,
            quotation_number: data?.number,
          };
          navigate(RoutesConfig.Planning.fullPath, {
            state: navigationState,
          });
        }}
        onNo={() => setIsScheduleAppointmentModalOpen(false)}
        title={''}
        description={
          t('Do you want to schedule the measurement appointment') + '?'
        }
      />
      <FollowUpStatusModal
        isOpen={isFollowUpStatusModalOpen}
        setIsOpen={setIsFollowUpStatusModalOpen}
        onCancel={() => setIsFollowUpStatusModalOpen(false)}
        title={t('Actions')}
        description={t('What is the action required') + '?'}
      />
      <YesOrNoModal
        pwId="yes-or-no-reason-of-rejection-modal"
        isOpen={isNotAcceptedStatusModalOpen}
        setIsOpen={setIsNotAcceptedStatusModalOpen}
        onYes={() => {
          setIsNotAcceptedStatusModalOpen(false);
          navigate(SingleQuotationRoutes.SingleQuotationNewComment.path);
        }}
        onNo={() => setIsNotAcceptedStatusModalOpen(false)}
        title={''}
        description={
          t('Do you want to give a comment to give the reason of rejection') +
          '?'
        }
      />
      <YesOrNoModal
        pwId="yes-or-no-copy-quotation-modal"
        isOpen={isCopiedToNewStatusModalOpen}
        setIsOpen={setIsCopiedToNewStatusModalOpen}
        onYes={() => {
          setIsCopiedToNewStatusModalOpen(false);
          navigate(RoutesConfig.NewQuotation.fullPath, {
            state: { prepopulateQuotationData: data },
          });
        }}
        onNo={() => setIsCopiedToNewStatusModalOpen(false)}
        title={''}
        description={
          t(
            'Are you sure you want to copy the quotation, this will close the quotation'
          ) + '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={data?.id}
        type={AddEmailNoteModalType.QUOTATION}
        entityType={AddEmailNoteModalType.QUOTATION}
        emailDetails={emailDetails}
      />
      <ConfirmModal
        isOpen={isCreateSalesOrderIfDraftModalOpen}
        setIsOpen={setIsCreateSalesOrderIfDraftModalOpen}
        onConfirm={() => createSalesOrder({ quotationId: id! })}
        onCancel={() => setIsCreateSalesOrderIfDraftModalOpen(false)}
        title={t('Create sales order')}
        description={
          t(
            'This quotation is a draft quotation, not a definitive quotation. Are you sure you want to make a sales order'
          ) + '?'
        }
      />
      <ConfirmModal
        isOpen={isCancelRecurringModalOpen}
        setIsOpen={setIsCancelRecurringModalOpen}
        onConfirm={() => {
          cancelRecurring(id!);
          setIsCancelRecurringModalOpen(false);
        }}
        onCancel={() => setIsCancelRecurringModalOpen(false)}
        title={t('Recurring Cancellation')}
        description={
          t('Are you sure you want to cancel recurring for this quotation') +
          '?'
        }
      />
      {showIntroductionGuide && <IntroductionGuide steps={steps} />}
      {cancelRecurringIsLoading && <Loader />}
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleQuotationPage;
