import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Card,
  CardTitle,
  Column,
  IconWrapper,
  Label,
  LabelValue,
  MarginTop30,
  RowSpaceBetween,
  SelectWrapper,
} from './AccountingConnectorTypeIdCard.styled';
import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { useEditCompanySettings } from '../hooks';
import { toast } from 'react-toastify';
import { useGetAccountingConnectorTypes } from './hooks';
import { Option } from 'components/Select/type';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import Icon from 'components/Icon/Icon';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { Select } from 'components/Select/Select';

interface IAccountingConnectorTypeIdCardProps {
  companyId: number;
  initialAccountingConnectorTypeId: number;
}

const AccountingConnectorTypeIdCard = ({
  companyId,
  initialAccountingConnectorTypeId,
}: IAccountingConnectorTypeIdCardProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [removeAccountingModal, setRemoveAccountingModal] =
    useState<boolean>(false);
  const [accountingConnectorTypeId, setAccountingConnectorTypeId] = useState<
    number | string
  >(initialAccountingConnectorTypeId);
  const [selectedOption, setSelectedOption] = useState<Option>();

  const {
    mutate: editCompanySettings,
    isLoading,
    isSuccess,
  } = useEditCompanySettings(Number(companyId!));

  const handleEditCompanySettings = () => {
    editCompanySettings({
      accounting_connector_type_id: accountingConnectorTypeId
        ? accountingConnectorTypeId.toString()
        : undefined,
    });
  };

  const accountingConnectorTypeOptions: Option[] =
    useGetAccountingConnectorTypes();

  useEffect(() => {
    const initialOption = accountingConnectorTypeOptions.find(
      (option) => option.value === initialAccountingConnectorTypeId.toString()
    );
    setSelectedOption(initialOption);
  }, [initialAccountingConnectorTypeId]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
      toast.success(t('Accounting software connector updated'));
    }
  }, [isSuccess]);

  return (
    <Card>
      <RowSpaceBetween>
        <CardTitle>{t('Accounting software')}</CardTitle>
      </RowSpaceBetween>
      {isEditing ? (
        <SelectWrapper>
          <Select
            defaultValue={selectedOption}
            name="accountingConnectorType"
            placeholder={t('Accounting connector type')}
            isMulti={false}
            isDisabled={false}
            isSearchable={false}
            onChange={(e: Option) => {
              setAccountingConnectorTypeId(Number(e.value));
              setSelectedOption(e);
            }}
            options={accountingConnectorTypeOptions}
          />
          {selectedOption && (
            <IconWrapper>
              <Icon
                svg={Trash}
                color={COLORS.RED_200}
                onClick={() => {
                  setRemoveAccountingModal(true);
                }}
              />
            </IconWrapper>
          )}
        </SelectWrapper>
      ) : (
        <Column>
          <Label>{t('Accounting connector type')}</Label>
          <LabelValue isRed={!accountingConnectorTypeId}>
            {accountingConnectorTypeId
              ? accountingConnectorTypeOptions.find(
                  (accountingConnectorTypeOption: Option) =>
                    Number(accountingConnectorTypeOption?.value) ===
                    Number(accountingConnectorTypeId)
                )?.label
              : t('Undefined')}
          </LabelValue>
        </Column>
      )}
      <MarginTop30>
        <ButtonsWrapper>
          {isEditing && (
            <Button
              disabled={isLoading}
              label={t('Cancel')}
              secondary
              width="150rem"
              onClick={() => {
                setIsEditing(false);
                setAccountingConnectorTypeId(initialAccountingConnectorTypeId);
              }}
            />
          )}
          <Button
            disabled={isLoading}
            label={isEditing ? t('Save') : t('Change')}
            primary
            width="150rem"
            onClick={() => {
              if (isEditing) {
                handleEditCompanySettings();
              } else {
                setIsEditing(true);
              }
            }}
          />
        </ButtonsWrapper>
      </MarginTop30>

      <ConfirmModal
        isOpen={removeAccountingModal}
        setIsOpen={setRemoveAccountingModal}
        onConfirm={() => {
          setRemoveAccountingModal(false);
          setSelectedOption(undefined);
          setAccountingConnectorTypeId('');
          setIsEditing(false);
          editCompanySettings({
            accounting_connector_type_id: null,
          });
        }}
        onCancel={() => {
          setRemoveAccountingModal(false);
        }}
        title={t('Remove accounting software')}
        description={
          t('Are you sure you want to remove Accounting software') + '?'
        }
      />
    </Card>
  );
};

export default AccountingConnectorTypeIdCard;
