export const transformEmptyValues = (values: any) => {
  // Fields where empty is 0
  const zeroFields = [
    'working_hours_rate',
    'tax_rate',
    'days_valid_quotation',
    'days_valid_invoice',
  ];

  return Object.keys(values).reduce((acc, key) => {
    if (zeroFields.includes(key)) {
      acc[key] = values[key] === '' ? 0 : values[key];
    } else {
      acc[key] = values[key] === '' ? null : values[key];
    }
    return acc;
  }, {});
};
