import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  ButtonsWrapper,
  Container,
  FormikContainer,
  Subtitle,
  Text,
  TextButton,
} from '../SignUpPage.styled';
import { Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { verificationSchema } from './validation';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Dispatch, SetStateAction, useState } from 'react';
import { VerificationCodeInput } from 'components/VerificationCodeInput/VerificationCodeInput';
import { useSendSignUpCode, useVerifySignUpCode } from './hooks';
import Loader from 'components/Loader/Loader';
import { ISignUpVerificationDetails } from '../types';

interface IVerificationTabProps {
  onForward: () => void;
  verificationDetails: ISignUpVerificationDetails;
  setVerificationDetails: Dispatch<SetStateAction<ISignUpVerificationDetails>>;
}
export const VerificationTab = ({
  onForward,
  verificationDetails,
  setVerificationDetails,
}: IVerificationTabProps) => {
  const { t } = useTranslation();
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [showVerificationCodeForm, setShowVerificationCodeForm] =
    useState<boolean>(false);

  const { mutate: sendCode, isLoading: isLoadingSendCode } =
    useSendSignUpCode();
  const { mutate: verifyCode, isLoading: isLoadingVerifyCode } =
    useVerifySignUpCode();

  return (
    <Container>
      <Subtitle>{t('Verification')}</Subtitle>
      {showVerificationCodeForm ? (
        <>
          <Text>
            {`${t(
              "Please check your e-mail and enter a verification code. Didn't receive a code"
            )}?`}
            <TextButton
              onClick={() => {
                if (isLoadingSendCode) return;
                sendCode(
                  {
                    email: verificationDetails.email,
                    vat_number: verificationDetails.vat,
                  },
                  {
                    onSuccess: () => setShowVerificationCodeForm(true),
                  }
                );
              }}
            >
              {t('Resend')}
            </TextButton>
          </Text>
          <VerificationCodeInput
            onCodeChange={(code: string | null) => setVerificationCode(code)}
          />
          <ButtonsWrapper>
            <Button
              width={'280rem'}
              onClick={() => {
                setHasAgreedToTerms(false);
                setShowVerificationCodeForm(false);
              }}
              label={t('Back')}
              secondary
            />

            <Button
              disabled={
                !verificationCode || isLoadingSendCode || isLoadingVerifyCode
              }
              onClick={() => {
                if (verificationCode)
                  verifyCode(
                    {
                      email: verificationDetails.email,
                      verification_code: verificationCode,
                    },
                    {
                      onSuccess: () => onForward(),
                    }
                  );
              }}
              label={t('Submit')}
              primary
              width={'280rem'}
            />
          </ButtonsWrapper>
        </>
      ) : (
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={verificationDetails}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          validationSchema={verificationSchema}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleBlur,
            setFieldValue,
          }) => (
            <FormikContainer>
              <Text>
                {`${t(
                  'Please enter your company e-mail and VAT number so that we can send a 6-digit code to your e-mail and verify it'
                )}.`}
              </Text>
              <Input
                errorMessage={touched['email'] ? errors['email'] : ''}
                height={'41rem'}
                name="email"
                placeholder={t('Company E-mail')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('email', value);
                  handleBlur(e);
                }}
              />
              <Input
                errorMessage={touched['vat'] ? errors['vat'] : ''}
                height={'41rem'}
                name="vat"
                placeholder={t('Company VAT Number')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('vat', value);
                  handleBlur(e);
                }}
              />
              <Checkbox
                label={t(
                  'I agree to the Terms and Conditions and Privacy Policy'
                )}
                onChange={() => {
                  setHasAgreedToTerms(!hasAgreedToTerms);
                }}
                isChecked={hasAgreedToTerms}
                style={{
                  fontSize: '16rem',
                }}
                inputStyle={{ transform: 'scale(1.2)' }}
              />

              <ButtonContainer>
                <Button
                  label={t('Continue')}
                  primary
                  disabled={
                    !isValid ||
                    !values.email ||
                    !values.vat ||
                    !hasAgreedToTerms ||
                    isLoadingSendCode
                  }
                  width="100%"
                  height={'55rem'}
                  onClick={() => {
                    if (isValid) {
                      setVerificationDetails(values);
                      sendCode(
                        {
                          email: values.email,
                          vat_number: values.vat,
                        },
                        {
                          onSuccess: () => setShowVerificationCodeForm(true),
                        }
                      );
                    }
                  }}
                />
              </ButtonContainer>
            </FormikContainer>
          )}
        </Formik>
      )}
      {(isLoadingSendCode || isLoadingVerifyCode) && <Loader />}
    </Container>
  );
};
