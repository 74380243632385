import {
  IPaymentCondition,
  IPaymentConditionTableDTO,
} from 'types/PaymentCondition.types';
import { formatNumber } from 'utils/numberFormatter';

export const formatTableData = (
  paymentConditionsData: IPaymentCondition[],
  currencySymbol: string
) => {
  return paymentConditionsData?.map((paymentCondition: IPaymentCondition) => {
    const paymentConditionTableDTO: IPaymentConditionTableDTO = {
      id: paymentCondition.id,
      label: paymentCondition.name,
      text: paymentCondition.text,
      threshold: `${currencySymbol}${formatNumber(
        paymentCondition.downpayment_threshold
      )}`,
      percentage: `${paymentCondition.downpayment_percentage}%`,
      excludedIsDefault: paymentCondition.is_default,
    };
    return paymentConditionTableDTO;
  });
};
