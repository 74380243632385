import {
  CardsWrapper,
  Container,
  LoaderWrapper,
  Title,
} from './ExternalIntegrationsPage.styled';
import { useGetCompanySettings } from './hooks';
import MollieIntegrationCard from './MollieIntegrationCard/MollieIntegrationCard';
import TwinfieldIntegrationCard from './TwinfieldIntegrationCard/TwinfieldIntegrationCard';
import LogyxIntegrationCard from './LogyxIntegrationCard/LogyxIntegrationCard';
import AccountingConnectorTypeIdCard from './AccountingConnectorTypeIdCard/AccountingConnectorTypeIdCard';
import Spinner from 'components/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useTranslation } from 'react-i18next';

const ExternalIntegrationsPage = () => {
  const { t } = useTranslation();
  const id = useSelector((state: IRootReducerState) => state.companyInfo.id);
  const { data: settingsData, isLoading: isLoadingSettings } =
    useGetCompanySettings(id);

  return (
    <Container>
      <Title>{t('External integrations')}</Title>
      <CardsWrapper>
        {isLoadingSettings ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <>
            <MollieIntegrationCard
              companyId={Number(id!)}
              initialAPIKey={settingsData?.settings?.mollie_api_key || null}
            />
            <LogyxIntegrationCard
              companyId={Number(id!)}
              initialAPIKey={settingsData?.settings?.logyx_api_key || ''}
              initialLogyxCompanyId={
                settingsData?.settings?.logyx_company_id || ''
              }
              initialLogyxAPIUrl={settingsData?.settings?.logyx_api_url || ''}
            />
            <AccountingConnectorTypeIdCard
              companyId={Number(id!)}
              initialAccountingConnectorTypeId={
                settingsData?.settings?.accounting_connector_type_id || ''
              }
            />
            {settingsData?.settings?.accounting_connector_type_id === 1 && (
              <TwinfieldIntegrationCard
                companyId={Number(id!)}
                initialAccountingConnectorTypeId={
                  settingsData?.settings?.accounting_connector_type_id || ''
                }
              />
            )}
          </>
        )}
      </CardsWrapper>
    </Container>
  );
};

export default ExternalIntegrationsPage;
