import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { sendCode, verifyCode } from 'services/SignUp/SignUpService';
import { ISignUpSendCodeDTO, ISignUpVerifyCodeDTO } from './types';

export const useSendSignUpCode = () => {
  const { t } = useTranslation();
  return useMutation((data: ISignUpSendCodeDTO) => sendCode(data), {
    onSuccess: () => {
      toast.success(t('Please check your email'), {
        toastId: ReactMutationKeys.SIGN_UP_SEND_CODE,
      });
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.SIGN_UP_SEND_CODE,
      });
    },
    mutationKey: ReactMutationKeys.SIGN_UP_SEND_CODE,
  });
};

export const useVerifySignUpCode = () => {
  const { t } = useTranslation();
  return useMutation((data: ISignUpVerifyCodeDTO) => verifyCode(data), {
    onSuccess: () => {
      toast.success(t('Code verified'), {
        toastId: ReactMutationKeys.SIGN_UP_VERIFY_CODE,
      });
    },
    onError: (error: ERPError) => {
      if (error.response.status === 401)
        toast.error(t('Invalid verification code'), {
          toastId: ReactMutationKeys.SIGN_UP_VERIFY_CODE,
        });
      else
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.SIGN_UP_VERIFY_CODE,
        });
    },
    mutationKey: ReactMutationKeys.SIGN_UP_VERIFY_CODE,
  });
};
