export enum CreateQuotationTab {
  CUSTOMER_DETAILS = 'customer',
  PRODUCTS = 'products',
  ORDER_DETAILS = 'order_details',
  OVERVIEW = 'overview',
}

export interface IRecurringQuotationDetails {
  recurring_period: string;
  recurring_type: string;
}
