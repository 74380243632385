import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  flex-shrink: 0;
  text-align: center;

  ${respondTo.bigPhone`
    margin-right: 0;
    margin-bottom: 20rem;
  `}
`;

export const Title = styled.span`
  font-weight: 300;
  font-size: 30rem;
  line-height: 45rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    text-align: center;
  `}
`;

export const PriceWrapper = styled.div`
  font-weight: 300;
  font-size: 30rem;
  line-height: 45rem;
  color: ${COLORS.PRIMARY};

  ${respondTo.bigPhone`
    text-align: center;
  `}
`;

export const PriceSpan = styled.span`
  font-size: 40rem;
  color: ${COLORS.PRIMARY};
`;

export const CounterSpan = styled.span`
  font-weight: 300;
  font-size: 40rem;
  line-height: 45rem;
  color: ${COLORS.PRIMARY};
  min-width: 50rem;
  text-align: center;
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  flex-grow: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  min-width: 50%;
`;
