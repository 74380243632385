import { queryClient } from 'index';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { authTwinfield } from 'services/Twinfield/TwinfieldAuthService';
import {
  addTwinfieldCompanyInfo,
  editTwinfieldCompanyInfo,
  getOfficeCodes,
  getTwinfieldCompanyInfo,
  getTwinfieldOptions,
} from 'services/Twinfield/TwinfieldService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import {
  editCompanySettings,
  getCompanySettings,
} from 'services/Admin/CompanyService';

export const useGetCompanySettings = (companyId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY_SETTINGS_ADMIN, companyId],
    queryFn: () => {
      return getCompanySettings(Number(companyId));
    },
    enabled: !!companyId,
  });

export interface IEditCompanySettingsDTO {
  logyx_api_key?: string;
  logyx_company_id?: string;
  mollie_api_key?: string | null;
  twinfield_api_key?: string;
  currency_id?: string;
  tax_rate?: number;
  working_hours_rate?: number;
  days_valid_quotation?: number;
  days_valid_invoice?: number;
  sales_order_products_definitive?: boolean;
  bank_name?: string;
  bank_iban?: string;
  bank_code_number?: string;
  bank_account_holder?: string;
  quotation_prefix?: string;
  sales_order_prefix?: string;
  purchase_order_prefix?: string;
  invoice_prefix?: string;
  customer_prefix?: string;
  stock_items_prefix?: string;
  suppliers_prefix?: string;
  sender_email?: string;
  appointment_lower_interval?: string;
  appointment_upper_interval?: string;
  bcc_email_address?: string;
  invoice_bcc_email_address?: string;
  sales_order_products_definitive_email_note?: string;
  appointment_days_before_reminder?: string;
  appointment_reminder_hour?: string;
  accounting_connector_type_id?: string | null;
  field_work_notification_email?: string;
  default_new_quotation_type_id?: number;
  email_footer_email?: string;
  logyx_api_url?: string;
  digital_order_enabled?: boolean;
  recurring_quotations_enabled?: boolean;
  due_date_start_reminder?: string;
  due_date_second_reminder?: string;
  due_date_third_reminder?: string;
  due_date_reminder_sent_time?: string;
}

export const useEditCompanySettings = (companyId: number) =>
  useMutation(
    (editCompanySettingsDTO: IEditCompanySettingsDTO) =>
      editCompanySettings(companyId, editCompanySettingsDTO),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_COMPANY_SETTINGS_ADMIN,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_COMPANY_SETTINGS,
        });
      },
      onSettled: (data) => {
        // On Settled
      },
      mutationKey: ReactMutationKeys.EDIT_COMPANY_SETTINGS,
    }
  );

export const useAddTwinfieldCompanyInfo = (companyId: number) =>
  useMutation(
    (twinfieldCompanyInfoData: {
      companyCode: string;
      profitAndLossAccountCode: string;
      generalLedgerDebtors: string;
      downpaymentAccountSettable: string;
    }) => addTwinfieldCompanyInfo(twinfieldCompanyInfoData, companyId),
    {
      onSuccess: (data) => {
        toast.success('Company info added');
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_TWINFIELD_COMPANY_INFO,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_TWINFIELD_COMPANY_INFO,
        });
      },
      onSettled: (data) => {
        // On Settled
      },
      mutationKey: ReactMutationKeys.ADD_TWINFIELD_COMPANY_INFO,
    }
  );

export const useGetTwinfieldCompanyInfo = (companyId: number) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_TWINFIELD_COMPANY_INFO],
    queryFn: () => {
      return getTwinfieldCompanyInfo(companyId);
    },
  });

export const useGetOfficeCodes = (companyId: number) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_OFFICE_CODES],
    queryFn: async () => {
      const offices = await getOfficeCodes(companyId);
      return offices.map((office: any) => ({
        value: office.office_code,
        label: office.office_code,
      }));
    },
  });

export const useGetDownPaymentsAndCodes = (companyId: number) =>
  useMutation(
    (companyDetails: {
      companyCode: string;
      selectSearch: string;
      accountType: string;
    }) => getTwinfieldOptions(companyDetails, companyId),
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.GET_DOWNPAYMENT_AND_CODES,
        });
      },
      mutationKey: ReactMutationKeys.GET_DOWNPAYMENT_AND_CODES,
    }
  );

export const useGetGeneralLedgerDebtors = (companyId: number) =>
  useMutation(
    (companyDetails: {
      companyCode: string;
      selectSearch: string;
      accountType: string;
    }) => getTwinfieldOptions(companyDetails, companyId),
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.GET_GENERAL_DEBTORS,
        });
      },
      mutationKey: ReactMutationKeys.GET_GENERAL_DEBTORS,
    }
  );

export const useEditTwinfieldCompanyInfo = (companyId: number) =>
  useMutation(
    (editTwinfieldCompanyCodeData: {
      companyCode: string;
      profitAndLossAccountCode: string;
      generalLedgerDebtors: string;
      downpaymentAccountSettable: string;
      id: string;
    }) => editTwinfieldCompanyInfo(editTwinfieldCompanyCodeData, companyId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_TWINFIELD_COMPANY_INFO,
        ]);
        toast.success('Company info edited');
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_TWINFIELD_COMPANY_INFO,
        });
      },
      onSettled: (data) => {
        // On Settled
      },
      mutationKey: ReactMutationKeys.EDIT_TWINFIELD_COMPANY_INFO,
    }
  );

export const useAuthTwinfield = () =>
  useMutation((companyId: number) => authTwinfield(companyId), {
    onSuccess: (data) => {
      window.open(data?.auth_url, '_blank');
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.AUTH_TWINFIELD,
      });
    },
    onSettled: (data) => {
      // On Settled
    },
    mutationKey: ReactMutationKeys.AUTH_TWINFIELD,
  });
