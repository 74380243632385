import { marginSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageNotAuthorizedLabel = styled.label`
  font-size: 30rem;
  font-weight: 300;
  margin-bottom: ${marginSm};
`;
