import { H4 } from 'assets/styled';
import { Formik } from 'formik';
import {
  ColumnContainer,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
} from '../EditCompanyDataModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { IEditBank } from '../constants';
import { editBankTabSchema } from '../validation';

interface IBankTabProps {
  bank: IEditBank;
  setBank: (bank: IEditBank) => void;
  setIsValid: (isValid: boolean) => void;
}
export const BankTab = ({ bank, setBank, setIsValid }: IBankTabProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={(values) => {
          //
        }}
        initialValues={bank}
        validationSchema={editBankTabSchema}
      >
        {({ values, setFieldValue, handleBlur, isValid, errors }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            setBank(values);
            handleBlur(e);
            setIsValid(isValid);
          };
          return (
            <Container>
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Bank name').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['bank_name'] ? errors['bank_name'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'bank_name');
                    }}
                    height={'43rem'}
                    name="bank_name"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Bank IBAN').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['bank_iban'] ? errors['bank_iban'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'bank_iban');
                    }}
                    height={'43rem'}
                    name="bank_iban"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Bank code number').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['bank_code_number']
                        ? errors['bank_code_number']
                        : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'bank_code_number');
                    }}
                    height={'43rem'}
                    name="bank_code_number"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Bank account holder').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['bank_account_holder']
                        ? errors['bank_account_holder']
                        : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'bank_account_holder');
                    }}
                    height={'43rem'}
                    name="bank_account_holder"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
};
