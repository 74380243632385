import { TextField } from '@mui/material';
import useVerificationHook from './hooks';
import { Container } from './VerificationCodeInput.styled';

interface VerificationCodeInputProps {
  length?: number;
  onCodeChange: (code: string | null) => void;
}

export const VerificationCodeInput = ({
  length = 6,
  onCodeChange,
}: VerificationCodeInputProps) => {
  const { inputStates, handleChange, handleKeyDown } = useVerificationHook(
    length,
    onCodeChange
  );

  return (
    <Container>
      {inputStates.map((digit, index) => (
        <TextField
          key={index}
          id={`code-input-${index}`}
          value={digit}
          onChange={(e: any) => handleChange(e, index)}
          onKeyDown={(e: any) => handleKeyDown(e, index)}
          inputProps={{
            style: {
              textAlign: 'center',
              width: '40rem',
              height: '40rem',
              fontSize: '28rem',
            },
          }}
        />
      ))}
    </Container>
  );
};
