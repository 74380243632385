import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection, {
  ExpandableSectionTypeEnum,
} from './ExpandableSection/ExpandableSection';
import {
  ContentContainer,
  ExpandableSectionsWrapper,
  Heading,
  Line,
} from './PDFAdditionalTextCard.styled';

interface IPDFAdditionalProps {
  data: any;
  companyId: string;
}
export const PDFAdditionalTextCard = ({
  data,
  companyId: id,
}: IPDFAdditionalProps) => {
  const { t } = useTranslation();

  const [expandedSections, setExpandedSections] = useState<
    ExpandableSectionTypeEnum[]
  >([]);

  const handleToggleExpand = (
    expandableSectionType: ExpandableSectionTypeEnum
  ) => {
    if (expandedSections.includes(expandableSectionType)) {
      setExpandedSections(
        expandedSections.filter(
          (expandedSection: ExpandableSectionTypeEnum) =>
            expandedSection !== expandableSectionType
        )
      );
    } else {
      setExpandedSections([...expandedSections, expandableSectionType]);
    }
  };

  return (
    <ContentContainer>
      <ExpandableSectionsWrapper>
        <Heading>{t('PDF ADDITIONAL TEXT')}</Heading>

        <ExpandableSection
          companyId={Number(id)}
          type={ExpandableSectionTypeEnum.QUOTATION_PDF_FOOTER}
          name={'quotation_pdf_additional_text'}
          value={data?.quotation_pdf_additional_text}
          isExpanded={expandedSections.includes(
            ExpandableSectionTypeEnum.QUOTATION_PDF_FOOTER
          )}
          handleToggleExpand={handleToggleExpand}
        />

        <Line />

        <ExpandableSection
          companyId={Number(id)}
          type={ExpandableSectionTypeEnum.INVOICE_PDF_FOOTER}
          name={'invoice_pdf_additional_text'}
          value={data?.invoice_pdf_additional_text}
          isExpanded={expandedSections.includes(
            ExpandableSectionTypeEnum.INVOICE_PDF_FOOTER
          )}
          handleToggleExpand={handleToggleExpand}
        />

        <Line />

        <ExpandableSection
          companyId={Number(id)}
          type={ExpandableSectionTypeEnum.ORDER_CONFIRMATION}
          name={'order_confirmation_text'}
          value={data?.order_confirmation_text}
          isExpanded={expandedSections.includes(
            ExpandableSectionTypeEnum.ORDER_CONFIRMATION
          )}
          handleToggleExpand={handleToggleExpand}
        />
      </ExpandableSectionsWrapper>
    </ContentContainer>
  );
};
