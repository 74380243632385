import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IPlanningPageFilters, IPlanningTabFilter } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import {
  useGetAppointmentTypeOptions,
  useGetAppointmentViewOptions,
} from './PlanningTab/hooks';
import moment from 'moment';
import {
  addPlanningTab,
  initializePageFilters,
  removePlanningTab,
  selectPlanningTab,
  setWasPlanningPageJustUpdated,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { generateUniqueId } from './helpers';
import { useCallback, useEffect, useState } from 'react';
import { ISalesOrderLine } from 'types/SalesOrders.types';

export const useGetPlanningPageFilters = () => {
  const dispatch = useDispatch();
  const planningPageFilters: IPlanningPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.planningPage,
    shallowEqual
  );
  const appointmentTypeOptions = useGetAppointmentTypeOptions();
  const appointmentViewOptions = useGetAppointmentViewOptions();

  if (!planningPageFilters) {
    const newTabId = generateUniqueId();
    const initialPlanningPageFilters: IPlanningPageFilters = {
      activeTabId: newTabId,
      tabs: [
        {
          id: newTabId,
          name: 'Tab 1',
          startDate: moment(),
          endDate: moment().add(1, 'week'),
          filterDate: '',
          selectedWorkers: [],
          selectedResources: [],
          selectedUsers: [],
          selectedAppointmentTypeOptions: [
            appointmentTypeOptions[0],
            appointmentTypeOptions[1],
            appointmentTypeOptions[2],
            appointmentTypeOptions[3],
          ],
          selectedAppointmentViewOption: appointmentViewOptions[1],
        },
      ],
      deletedTabIds: [],
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.PLANNING,
        data: initialPlanningPageFilters,
      })
    );
    return {
      appointmentTypeOptions,
      appointmentViewOptions,
    };
  }

  return {
    planningPageFilters,
    appointmentTypeOptions,
    appointmentViewOptions,
  };
};

export const useManageTabFilters = (planningPageFilters: any) => {
  const dispatch = useDispatch();
  const { tabs = [], activeTabId = null } = planningPageFilters || {};
  const appointmentTypeOptions = useGetAppointmentTypeOptions();
  const appointmentViewOptions = useGetAppointmentViewOptions();

  const addTab = useCallback(() => {
    const newTabId = generateUniqueId();
    const newTab: IPlanningTabFilter = {
      id: newTabId,
      name: `Tab ${tabs.length + 1}`,
      startDate: moment(),
      endDate: moment().add(1, 'week'),
      filterDate: '',
      selectedWorkers: [],
      selectedResources: [],
      selectedUsers: [],
      selectedAppointmentTypeOptions: [
        appointmentTypeOptions[0],
        appointmentTypeOptions[1],
        appointmentTypeOptions[2],
        appointmentTypeOptions[3],
      ],
      selectedAppointmentViewOption: appointmentViewOptions[1],
    };
    dispatch(addPlanningTab(FiltersPageEnum.PLANNING, newTab));
  }, [dispatch, tabs.length, appointmentTypeOptions, appointmentViewOptions]);

  const removeTab = useCallback(
    (tabId: string) => {
      dispatch(removePlanningTab(FiltersPageEnum.PLANNING, tabId));
    },
    [dispatch]
  );

  const selectTab = useCallback(
    (tabId: string) => {
      dispatch(selectPlanningTab(FiltersPageEnum.PLANNING, tabId));
    },
    [dispatch]
  );

  // Trigger saving filters on be after PlanningPage dismount (handled in UploadPlanningPageFiltersProvider)
  useEffect(() => {
    return () => {
      dispatch(setWasPlanningPageJustUpdated(true));
    };
  }, []);

  return {
    addTab,
    removeTab,
    selectTab,
    activeTabId,
    tabs,
  };
};

export const SALES_ORDER_LINES_PER_PAGE = 4;
export const useSalesOrderLinesLocalPagination = () => {
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // Local pagination
  const getElementsForCurrentPage = (salesOrderLines: ISalesOrderLine[]) => {
    return salesOrderLines.filter((line: ISalesOrderLine, index: number) => {
      if (
        index < SALES_ORDER_LINES_PER_PAGE * page &&
        index >= SALES_ORDER_LINES_PER_PAGE * (page - 1) &&
        line.product?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
        return line;
    });
  };

  return { page, setPage, setSearchTerm, getElementsForCurrentPage };
};
