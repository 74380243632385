export enum CompanyBillingType {
  YEARLY = 1,
  QUARTERLY = 2,
}

export enum SignUpTab {
  VERIFICATION = 'verification',
  COMPANY_DETAILS = 'company_details',
  BANK_DETAILS = 'bank_details',
  PAYMENT_PLAN = 'payment_plan',
}

export interface ISignUpVerificationDetails {
  email: string;
  vat: string;
}
export interface ISignUpCompanyDetails {
  company_name: string;
  street: string;
  house_number: string;
  company_vat: string;
  company_coc: string;
  country_code: string;
  phone: string;
  website: string;
  zip_code: string;
  city: string;
}

export interface ISignUpBankDetails {
  bank_name: string;
  bank_code: string;
  account_holder_name: string;
  iban: string;
}

export interface ISignUpPlanDetails {
  license_type: number;
  billing_type: string;
  field_users: string[];
  office_users: string[];
}

export interface ISignUpDetailsDTO {
  company_data: ISignUpCompanyDetails;
  company_email: string;
  bank_data: ISignUpBankDetails;
  plan_data: ISignUpPlanDetails;
}

export interface ILicense {
  id: number;
  name: string;
  price: number;
  is_field_worker_enabled: boolean;
  is_logyx_enabled: boolean;
}

export interface IBilling {
  value: string;
  name: string;
}

export interface IUserLicense {
  id: number;
  name: string;
  price: number;
}

export enum UserLicenseType {
  OFFICE_WORKER = 'OFFICE_WORKER',
  FIELD_WORKER = 'FIELD_WORKER',
}
