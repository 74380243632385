import { IEditCompanyDataDTO } from 'pages/Manager/CompanyConfigurationPage/EditCompanyDataModal/constants';
import apiClient from 'services/api/apiService';
import { IEditCompanySettingsDTO } from 'types/Admin.types';

const BASE_URL = '/v1/admin/companies';

export const getCompanySettings = async (companyId: number): Promise<any> => {
  const url = `${BASE_URL}/${companyId}/settings`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getCompanyLogo = async (companyId: string): Promise<any> => {
  const url = `${BASE_URL}/${companyId}/logo`;
  const { data } = await apiClient.get<any>(url);

  return data;
};

export const editCompanySettings = async (
  companyId: number,
  editCompanySettingsDTO: IEditCompanySettingsDTO
): Promise<any> => {
  const url = `${BASE_URL}/${companyId}/settings`;
  const { data } = await apiClient.patch<any>(url, editCompanySettingsDTO);

  return data.data;
};

export const uploadCompanyLogo = async (
  companyId: number,
  logoFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + '/' + companyId + '/' + 'logo';

  const { data } = await apiClient.post(url, logoFormData, { headers });
  return data.data;
};

export const getCompanyByCompanyId = async (
  companyId: number
): Promise<any> => {
  const url = `${BASE_URL}/${companyId}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const editCompany = async (
  companyId: number,
  editCompanyDataDTO: IEditCompanyDataDTO
): Promise<any> => {
  const url = `${BASE_URL}/${companyId}`;
  const { data } = await apiClient.patch<any>(url, editCompanyDataDTO);
  return data.data;
};
