import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  HoverIconColorInvertButton,
  IconColorInvertButton,
  IntervalDurationLabel,
} from './FwTimer.styled';
import Button from 'components/Button/Button';
import {
  useCurrentInterval,
  useFinishFwAppointment,
  usePauseFwAppointmentInterval,
  useResumeFwAppointmentInterval,
  useStartFwAppointmentInterval,
  useStopFwAppointmentInterval,
} from './hooks';
import {
  formatSeconds,
  getAppointmentScheduledDifference,
  isAppointmentScheduledForToday,
} from './helpers';
import { useEffect, useState } from 'react';
import { RoutesConfig } from 'navigation/routes';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import {
  AppointmentStatus,
  AppointmentType,
  IAppointment,
} from 'types/Appointment.types';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { useGetSingleFwAppointment } from '../hooks';
import { COLORS } from 'assets/styled';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { Pause, Play, Stop } from '@phosphor-icons/react';

interface IFwTimerProps {
  appointment: undefined | IAppointment;
}

const FwTimer = ({ appointment }: IFwTimerProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isConfirmStopTimerModalOpen, setIsConfirmStopTimerModalOpen] =
    useState<boolean>(false);
  const [isConfirmStartTimerModalOpen, setIsConfirmStartTimerModalOpen] =
    useState<boolean>(false);
  const [isNavigationFailedModalOpen, setIsNavigationFailedModalOpen] =
    useState<boolean>(false);
  const [appointmentScheduledDifference, setAppointmentScheduledDifference] =
    useState<string>('');
  const canEditFW = useCan(Actions.EDIT_FIELD_WORKER);

  const {
    totalTime,
    isLastIntervalStopped,
    currentInterval,
    isIntervalPaused,
    isLoadingIntervals,
  } = useCurrentInterval();

  const [displayTime, setDisplayTime] = useState<number>(totalTime || 0);
  useEffect(() => {
    setDisplayTime(totalTime || 0);
  }, [totalTime]);

  useEffect(() => {
    let interval: number;
    if (!isIntervalPaused && currentInterval) {
      interval = window.setInterval(() => {
        setDisplayTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIntervalPaused, currentInterval]);

  const { mutate: startTimer, isLoading: isLoadingStartTimer } =
    useStartFwAppointmentInterval(id!);
  const { mutate: stopTimer, isLoading: isLoadingStopTimer } =
    useStopFwAppointmentInterval(id!);
  const { mutate: pauseTimer, isLoading: isLoadingPauseTimer } =
    usePauseFwAppointmentInterval(id!);
  const { mutate: resumeTimer, isLoading: isLoadingResumeTimer } =
    useResumeFwAppointmentInterval(id!);
  const { mutate: finishAppointment, isLoading: isLoadingFinish } =
    useFinishFwAppointment(id!);

  const handleStartTimer = () => {
    if (isAppointmentScheduledForToday(appointment)) {
      startTimer();
    } else {
      const differenceLargerThan1HourMessage =
        getAppointmentScheduledDifference(appointment, t);
      if (differenceLargerThan1HourMessage) {
        setAppointmentScheduledDifference(differenceLargerThan1HourMessage);
        setIsConfirmStartTimerModalOpen(true);
      } else {
        startTimer();
      }
    }
  };

  const handleSubmit = () => {
    if (isGeneralAppointment)
      finishAppointment(undefined, {
        onSuccess: () => {
          setIsConfirmStopTimerModalOpen(false);
        },
      });
    else navigateToReportPage();
  };

  const { data: appointmentData, isLoading: isLoadingAppointment } =
    useGetSingleFwAppointment(id!);
  const hasReport = appointmentData?.appointment?.has_report;

  const navigateToReportPage = () => {
    if (hasReport) {
      setIsConfirmStopTimerModalOpen(false);
      setIsNavigationFailedModalOpen(true);
    } else
      navigate(RoutesConfig.FwReportPage.fullPath, {
        state: {
          appointmentId: id,
        },
      });
  };

  const isLoading =
    isLoadingAppointment ||
    isLoadingStartTimer ||
    isLoadingPauseTimer ||
    isLoadingStopTimer ||
    isLoadingIntervals ||
    isLoadingResumeTimer ||
    isLoadingFinish;

  const isGeneralAppointment =
    appointment?.type?.name === AppointmentType.GENERAL;
  const isGeneralAppointmentFinished =
    isGeneralAppointment &&
    appointment?.status?.name === AppointmentStatus.FIELD_WORK_FINISHED;

  const { steps, showIntroductionGuide } = useRunTour(
    GuidePages.FIELD_WORKER_GENERAL,
    id,
    true,
    true
  );

  return (
    <Container className="fw-general-step-1">
      <IntervalDurationLabel>
        {totalTime ? formatSeconds(displayTime) : null}
      </IntervalDurationLabel>
      {!currentInterval && !isLastIntervalStopped ? (
        <IconColorInvertButton
          disabled={
            isLoading || hasReport || !canEditFW || isGeneralAppointmentFinished
          }
          onClick={() => handleStartTimer()}
          label={t('Start timer')}
          icon={Play}
          colorIcon={COLORS.STRICT_WHITE}
          sizeIcon={18}
          primary
          width={'200rem'}
        />
      ) : isLastIntervalStopped ? (
        <>
          <HoverIconColorInvertButton
            disabled={isLoading || hasReport || isGeneralAppointmentFinished}
            onClick={() => handleStartTimer()}
            label={t('Start timer')}
            icon={Play}
            colorIcon={COLORS.PRIMARY}
            sizeIcon={18}
            secondary
            width={'200rem'}
          />
          <Button
            disabled={isLoading || hasReport || isGeneralAppointmentFinished}
            onClick={() => setIsConfirmStopTimerModalOpen(true)}
            label={t('Finish')}
            primary={true}
            width={'200rem'}
          />
        </>
      ) : (
        <>
          <HoverIconColorInvertButton
            disabled={isLoading || hasReport || isGeneralAppointmentFinished}
            onClick={
              isIntervalPaused ? () => resumeTimer() : () => pauseTimer()
            }
            label={isIntervalPaused ? t('Resume timer') : t('Pause timer')}
            icon={isIntervalPaused ? Play : Pause}
            colorIcon={COLORS.PRIMARY}
            sizeIcon={20}
            secondary
            width={'200rem'}
          />
          <Button
            disabled={isLoading || hasReport || isGeneralAppointmentFinished}
            onClick={() => setIsConfirmStopTimerModalOpen(true)}
            label={isIntervalPaused ? t('Finish') : t('Stop timer')}
            primary={isIntervalPaused}
            red={!isIntervalPaused}
            icon={isIntervalPaused ? undefined : Stop}
            colorIcon={COLORS.STRICT_WHITE}
            sizeIcon={20}
            width={'200rem'}
          />
        </>
      )}

      <ConfirmModal
        isOpen={isConfirmStopTimerModalOpen}
        setIsOpen={setIsConfirmStopTimerModalOpen}
        onConfirm={() =>
          isIntervalPaused
            ? handleSubmit()
            : stopTimer(undefined, {
                onSuccess: () => {
                  setIsConfirmStopTimerModalOpen(false);
                  handleSubmit();
                },
              })
        }
        onCancel={() => setIsConfirmStopTimerModalOpen(false)}
        title={isGeneralAppointment ? '' : t('Fill in report')}
        description={
          isGeneralAppointment
            ? t('Are you sure you want to complete this appointment') + '?'
            : t(
                'Have you completed the appointment? If so, would you be able to proceed with completing the report'
              ) + '?'
        }
        disabled={isLoading}
      />
      <ConfirmModal
        isOpen={isConfirmStartTimerModalOpen}
        setIsOpen={setIsConfirmStartTimerModalOpen}
        onConfirm={() => {
          startTimer();
          setIsConfirmStartTimerModalOpen(false);
        }}
        onCancel={() => setIsConfirmStartTimerModalOpen(false)}
        title={t('Start timer')}
        description={appointmentScheduledDifference}
        disabled={isLoading}
      />
      <OkModal
        isOpen={isNavigationFailedModalOpen}
        setIsOpen={setIsNavigationFailedModalOpen}
        description={t('Report already exists') + '.'}
        buttonLabel={t('Close')}
        level="SECOND"
        onClick={() => setIsNavigationFailedModalOpen(false)}
      />
      {showIntroductionGuide && <IntroductionGuide steps={steps} />}
    </Container>
  );
};

export default FwTimer;
