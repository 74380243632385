import { useMutation } from 'react-query';
import { updateFrontendSettings } from 'services/Common/CommonService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export interface IUpdateFrontendSettingsDTO {
  planning_page_filters: any;
}

export const useUpdateFrontendSettings = () =>
  useMutation(
    (dto: IUpdateFrontendSettingsDTO) => updateFrontendSettings(dto),
    {
      onError: (error: ERPError) =>
        console.warn(
          `[UPDATE_FRONTEND_SETTINGS ERROR] ${getToastErrorMessage(error)}`
        ),
      mutationKey: ReactMutationKeys.UPDATE_FRONTEND_SETTINGS,
    }
  );
