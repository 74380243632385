import { ISignUpActivationDTO } from 'pages/Auth/SignUpActivationPage/types';
import { ISignUpDetailsDTO } from 'pages/Auth/SignUpPage/types';
import {
  ISignUpSendCodeDTO,
  ISignUpVerifyCodeDTO,
} from 'pages/Auth/SignUpPage/VerificationTab/types';
import openApiClient from 'services/api/openApiService';

const BASE_URL = '/v1/sign-up/';

export const sendCode = async (
  sendCodeDTO: ISignUpSendCodeDTO
): Promise<any> => {
  const url = `${BASE_URL}send-code`;
  const { data } = await openApiClient.post<any>(url, sendCodeDTO);

  return data.data;
};

export const verifyCode = async (
  verifyCodeDTO: ISignUpVerifyCodeDTO
): Promise<any> => {
  const url = `${BASE_URL}verify-code`;
  const { data } = await openApiClient.post<any>(url, verifyCodeDTO);

  return data.data;
};

export const signUp = async (signUpDTO: ISignUpDetailsDTO): Promise<any> => {
  const url = `${BASE_URL}`;
  const { data } = await openApiClient.post<any>(url, signUpDTO);

  return data.data;
};

export const activateUser = async (
  signUpActivationDTO: ISignUpActivationDTO
): Promise<any> => {
  const url = `${BASE_URL}activate-user`;
  const { data } = await openApiClient.post<any>(url, signUpActivationDTO);

  return data.data;
};
