import { COLORS, marginMd, marginSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

interface StyledProps {
  active: boolean;
  finished: boolean;
  onClick: any;
}

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 15rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 580rem;
`;

export const Logo = styled.img`
  width: 400rem;
  margin-top: ${marginSm};
  margin-bottom: 40rem;

  @media screen and (max-width: 1440px) {
    margin-bottom: 30rem;
  }
`;

export const Title = styled.h1`
  font-size: 50rem;
  line-height: 75rem;
  font-weight: 300;
  margin-bottom: ${marginSm};
  color: ${COLORS.BLACK};
`;

export const Subtitle = styled.h2`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.PRIMARY};
  margin-bottom: ${marginSm};
`;

export const Text = styled.span`
  font-size: 16rem;
  line-height: 25rem;
  font-weight: 300;
  color: ${COLORS.GREY_900};
  margin-bottom: 40rem;
  text-align: center;
`;

export const InputWrapper = styled.div`
  margin-bottom: 20rem;
  width: 580rem;

  ${respondTo.bigPhone`
    width: 450rem;
  `}
`;

export const LanguageSwitcherWrapper = styled.div`
  margin-left: 20rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${marginMd};
  width: 100%;
  max-width: 900rem;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  margin-bottom: ${marginXs};
`;

export const EyeWrapper = styled.div`
  position: absolute;
  top: 14rem;
  right: 15rem;
  width: 25rem;
  cursor: pointer;
  user-select: none;
`;

export const ButtonContainer = styled.div`
  margin-top: ${marginMd};
  width: 100%;
`;
