import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { createQuotation } from 'services/Quotation/QuotationService';
import { ICreateQuotationData } from 'types/Quotations.types';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useEffect, useState } from 'react';
import { useCallbackPrompt } from 'utils/hooks/useCallbackPrompt';
import { ICreateQuotationProductFormDTO } from './ProductTab/constants';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getDefaultPaymentCondition,
  getPaymentConditionById,
  getPaymentConditions,
} from 'services/PaymentCondition/PaymentConditionService';

export const useCreateQuotation = () =>
  useMutation(
    (createQuotationData: ICreateQuotationData) =>
      createQuotation(createQuotationData),
    {
      onSuccess: () => {
        // Success
        queryClient.invalidateQueries([ReactQueryKeys.GET_QUOTATION_INFO]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_QUOTATION,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CREATE_QUOTATION,
    }
  );

export const useGetPrepopulateQuotationData = () => {
  const { state } = useLocation();
  if (state?.prepopulateQuotationData) {
    return state.prepopulateQuotationData;
  } else {
    return null;
  }
};

export const useAddNavigationCheckAfterProductIsAdded = (
  productFormDtos: ICreateQuotationProductFormDTO[],
  status: string
) => {
  const [checkNavigation, setCheckNavigation] = useState<boolean>(false);
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(checkNavigation);

  useEffect(() => {
    if (productFormDtos?.length) {
      setCheckNavigation(
        status === 'loading' || status === 'success' ? false : true
      );
    }
  }, [productFormDtos, status, checkNavigation]);

  return {
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    setCheckNavigation,
  };
};

export const useGetPaymentConditionsInfinite = (
  perPage: number,
  searchBy?: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_PAYMENT_CONDITIONS_INFINITE,
      perPage,
      searchBy,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getPaymentConditions(pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useGetPaymentConditionById = (paymentConditionId?: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_PAYMENT_CONDITION, paymentConditionId],
    queryFn: () => {
      return getPaymentConditionById(paymentConditionId);
    },
    enabled: !!paymentConditionId,
  });

export const useGetDefaultPaymentCondition = (isEnabled = true) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_DEFAULT_PAYMENT_CONDITION],
    queryFn: () => {
      return getDefaultPaymentCondition();
    },
    enabled: isEnabled,
  });
