import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IUpdateFrontendSettingsDTO, useUpdateFrontendSettings } from './hooks';
import { setWasPlanningPageJustUpdated } from 'store/Filters/actions/filters';
import { setFrontendSettings } from 'store/Common/actions/common';

interface IUploadPlanningPageFiltersProviderProps {
  children: React.ReactNode;
}

export const UploadPlanningPageFiltersProvider = ({
  children,
}: IUploadPlanningPageFiltersProviderProps) => {
  const dispatch = useDispatch();
  const { wasPlanningPageJustUpdated, planningPage: planningPageFilters } =
    useSelector((state: IRootReducerState) => state.filtersInfo);

  const { frontendSettings } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const { mutate: updateFrontendSettings } = useUpdateFrontendSettings();

  useEffect(() => {
    if (wasPlanningPageJustUpdated) {
      // deletedTabIds are saved as an empty array on the backend as part of the filters data
      const planningPageFiltersPayload: string = JSON.stringify({
        ...planningPageFilters,
        deletedTabIds: [],
      });

      const areLocalPlanningPageFiltersMatchingTheFiltersOnBackend =
        planningPageFiltersPayload === frontendSettings?.planning_page_filters;

      if (
        planningPageFilters &&
        !areLocalPlanningPageFiltersMatchingTheFiltersOnBackend
      ) {
        const dto: IUpdateFrontendSettingsDTO = {
          planning_page_filters: planningPageFiltersPayload,
        };

        updateFrontendSettings(dto);
        dispatch(
          // Update local frontend settings so there are no redundant updateFrontendSettings being triggered
          // This is optimistic that updateFrontendSettings request wont fail
          setFrontendSettings({
            ...frontendSettings,
            planning_page_filters: planningPageFiltersPayload,
          })
        );
      }
      dispatch(setWasPlanningPageJustUpdated(false));
    }
  }, [wasPlanningPageJustUpdated]);

  return <>{children}</>;
};
