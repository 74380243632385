import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'utils/toast';
import LocalStorageService from 'services/LocalStorageService';
import { logout } from 'services/User/UserService';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from 'services/api/constants';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { logout as logoutAction } from 'store/User/actions/user';
import {
  setIsFieldWorkerView,
  setIsLoadingScreenShown,
  setIsSavingPlanningPageFiltersAndLoggingOut,
} from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';
import { IUpdateFrontendSettingsDTO } from 'providers/UploadPlanningPageFiltersProvider/hooks';
import { updateFrontendSettings } from 'services/Common/CommonService';
import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';

const _updatePlanningPageFiltersBeforeLogout = async (
  dispatch: Dispatch<AnyAction>,
  state: IRootReducerState
) => {
  // Check and update planning page filters before logout
  const isPlanningPage = window.location.pathname === '/planning';

  if (isPlanningPage && state.filtersInfo.planningPage) {
    const planningPageFiltersPayload = JSON.stringify({
      ...state.filtersInfo.planningPage,
      deletedTabIds: [],
    });

    const dto: IUpdateFrontendSettingsDTO = {
      planning_page_filters: planningPageFiltersPayload,
    };
    // Fire and forget - no need to await
    try {
      dispatch(setIsLoadingScreenShown(true));
      dispatch(setIsSavingPlanningPageFiltersAndLoggingOut(true));
      await updateFrontendSettings(dto);
    } catch (e) {
      console.warn('[useLogout] Failed to update frontend settings');
    }
  }
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootReducerState) => state);
  return useMutation(
    async () => {
      await _updatePlanningPageFiltersBeforeLogout(dispatch, state);
      const logoutPromise = await logout();
      return logoutPromise;
    },
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.LOGOUT,
        });
      },
      onSettled: () => {
        LocalStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
        LocalStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        dispatch(setIsFieldWorkerView(false));
        dispatch(logoutAction());
        dispatch(setIsLoadingScreenShown(false));
        dispatch(setIsSavingPlanningPageFiltersAndLoggingOut(false));
      },
      mutationKey: ReactMutationKeys.LOGOUT,
    }
  );
};
