import {
  Container,
  HeaderWrapper,
  LoaderWrapper,
} from './SingleSalesCreditInvoicePage.styled';
import { useTranslation } from 'react-i18next';
import {
  useChangeSalesCreditInvoiceStatus,
  useGetSingleSalesCreditInvoice,
} from './hooks';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import {
  useExportSalesInvoice,
  useGetSingleSalesInvoiceInfo,
} from '../SingleSalesInvoice/hooks';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import {
  TitleContainer,
  ButtonContainer,
  Wrapper,
  IconContainer,
  LeftSide,
  H3,
  StatusContainer,
  StatusOptionsContainer,
  PenImg,
  Option,
  P,
  RouterWrapper,
} from './SingleSalesCreditInvoicePage.styled';
import Button from 'components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import SingleSalesCreditInvoiceRouter from 'navigation/SingleInvoice/SalesCreditInvoice/SingleSalesCreditInvoiceRouter';
import { subMenuItems } from './constants';
import { AddPaymentModal } from 'components/Modal/AddPaymentModal/AddPaymentModal';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { ThreeButtonsModal } from 'components/Modal/ThreeButtonsModal/ThreeButtonsModal';
import { useGetSalesInvoiceStatuses } from 'utils/hooks/useGetSalesInvoiceStatuses';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import { RoutesConfig } from 'navigation/routes';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Spinner from 'components/Spinner/Spinner';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';
import {
  CaretLeft,
  DownloadSimple,
  EnvelopeSimple,
  PencilSimple,
} from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const SENT_STATUS = 'SENT';

const SingleSalesCreditInvoicePage = () => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isError: isErrorSalesCreditInvoice } =
    useGetSingleSalesCreditInvoice(id!);

  // Sales invoice email is sent to general address
  const emailDetails = {
    email: data?.sales_invoice?.customer_snapshot?.email,
    type: 'General',
  };

  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);
  const [isSentStatusModalOpen, setIsSentStatusModalOpen] =
    useState<boolean>(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [isPaymentReminder, setIsPaymentReminder] = useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);

  const canEditSalesInvoice = useCan(Actions.EDIT_SALES_INVOICE);

  const { data: infoData } = useGetSingleSalesInvoiceInfo(id!);
  const { mutate: exportSalesInvoice, isLoading: isLoadingExport } =
    useExportSalesInvoice();
  const { mutate: changeSalesInvoiceStatus } =
    useChangeSalesCreditInvoiceStatus();
  const statusOptions = useGetSalesInvoiceStatuses();
  const statusContainerWrapperRef = useRef<HTMLDivElement>();

  useOutsideAlerter(
    statusContainerWrapperRef,
    () => canEditSalesInvoice && setIsStatusModalOpen(false)
  );

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isTablet } = useBreakpointFlag();

  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  return !isErrorSalesCreditInvoice ? (
    <Container>
      <SubSideMenu
        subMenuItems={subMenuItems}
        subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.sales_invoice_info)}
      />
      <Wrapper>
        <HeaderWrapper
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                svg={CaretLeft}
                marginRight="15rem"
                weight="regular"
                size={15}
                color={COLORS.BLACK}
                onClick={() => navigate(RoutesConfig.Invoices.fullPath)}
              />
              <H3>
                {t('Credit invoice')}&nbsp;
                {data?.sales_invoice?.invoice_number}
              </H3>
              <StatusContainer ref={statusContainerWrapperRef}>
                <P
                  onClick={() =>
                    canEditSalesInvoice &&
                    setIsStatusModalOpen(!isStatusModalOpen)
                  }
                >
                  {capitalizeFirstLetter(
                    t(data?.sales_invoice?.payment_status?.name)
                  )}
                </P>
                {canEditSalesInvoice && (
                  <PenImg>
                    <Icon
                      onClick={() => setIsStatusModalOpen(!isStatusModalOpen)}
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      size={15}
                    />
                  </PenImg>
                )}
                {isStatusModalOpen && (
                  <StatusOptionsContainer>
                    {statusOptions.map((option) => {
                      return (
                        <Option
                          isSelected={
                            option.value ===
                            data?.sales_invoice.payment_status?.name
                          }
                          key={option.value}
                          onClick={() => {
                            changeSalesInvoiceStatus({
                              id: data?.sales_invoice?.id || '',
                              status_id: option.id,
                            });

                            if (
                              option.value === SENT_STATUS &&
                              data.sales_invoice?.payment_status?.name !==
                                SENT_STATUS
                            ) {
                              setIsSentStatusModalOpen(true);
                            }
                            setIsStatusModalOpen(false);
                          }}
                        >
                          {capitalizeFirstLetter(t(option.label))}
                        </Option>
                      );
                    })}
                  </StatusOptionsContainer>
                )}
              </StatusContainer>
            </LeftSide>
            <IconContainer>
              <Tooltip content={t('Open PDF')} key={'exportKey'}>
                {isLoadingExport ? (
                  <LoaderWrapper>
                    <Spinner size={20} />
                  </LoaderWrapper>
                ) : (
                  <Icon
                    disabled={!data}
                    data-testid="download-pdf"
                    svg={DownloadSimple}
                    color={COLORS.PRIMARY}
                    size={25}
                    marginRight={marginXs}
                    marginLeft={marginXs}
                    onClick={() => exportSalesInvoice(data?.sales_invoice?.id)}
                  />
                )}
              </Tooltip>
              <Tooltip content={t('Send mail')} key={'emailKey'}>
                <Icon
                  data-testid="send-email"
                  svg={
                    data?.sales_invoice.sent_to_customer
                      ? MailSent
                      : EnvelopeSimple
                  }
                  size={25}
                  onClick={() => setIsSendEmailModalOpen(true)}
                  color={COLORS.PRIMARY}
                  marginLeft={marginXs}
                  marginRight={marginXs}
                />
              </Tooltip>
            </IconContainer>
          </TitleContainer>
          <ButtonContainer>
            <Button
              label={t('Add Payment')}
              secondary
              disabled={
                !canEditSalesInvoice || !data?.sales_invoice.open_amount
              }
              width="200rem"
              onClick={() => setIsPaymentModalOpen(true)}
            />
          </ButtonContainer>
        </HeaderWrapper>

        <RouterWrapper routerWithoutPaddingBottom={routerWithoutPaddingBottom}>
          <SingleSalesCreditInvoiceRouter />
        </RouterWrapper>
      </Wrapper>
      <ThreeButtonsModal
        isOpen={isSentStatusModalOpen}
        setIsOpen={setIsSentStatusModalOpen}
        onCancel={() => setIsSentStatusModalOpen(false)}
        firstButtonLabel={t('No')}
        onFirstButton={() => setIsSentStatusModalOpen(false)}
        secondButtonLabel={t('Download pdf')}
        onSecondButton={() => {
          exportSalesInvoice(data?.sales_invoice?.id);
          setIsSentStatusModalOpen(false);
        }}
        thirdButtonlabel={t('Send email with pdf')}
        onThirdButton={() => {
          setIsPaymentReminder(false);
          setIsAddEmailNoteModalOpen(true);
          setIsSentStatusModalOpen(false);
        }}
        title={''}
        description={t('Do you want to send the credit invoice') + '?'}
      />
      <ConfirmModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onConfirm={() => {
          setIsPaymentReminder(false);
          setIsAddEmailNoteModalOpen(true);
          setIsSendEmailModalOpen(false);
        }}
        onCancel={() => setIsSendEmailModalOpen(false)}
        title={t('Send E-mail')}
        description={
          t(
            'Are you sure you want to send this Sales credit invoice via email to the customer'
          ) + '?'
        }
      />
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        invoiceId={data?.sales_invoice?.id}
        invoiceType={InvoiceType.CREDIT_SALES_INVOICE}
        initialPaymentAmount={data?.sales_invoice?.open_amount}
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={data?.sales_invoice?.id}
        type={AddEmailNoteModalType.SALES_INVOICE}
        isPaymentReminder={isPaymentReminder}
        emailDetails={emailDetails}
      />
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleSalesCreditInvoicePage;
