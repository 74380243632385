import { Dispatch, SetStateAction } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Close } from 'components/Close/Close';
import AddEditAltContactForm from 'pages/Manager/NewAltContactPage/AddEditAltContactForm/AddEditAltContactForm';
import {
  CloseContainer,
  HeaderWrapper,
} from './AddEditCustomerAlternativeContactModal.styled';
import { ICustomerAlternativeContact } from 'types/Customer.types';
import { H3 } from 'assets/styled';
import { useTranslation } from 'react-i18next';

export interface IAddEditCustomerAlternativeContactModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customerId: number;
  contact?: ICustomerAlternativeContact;
}

const AddEditCustomerAlternativeContactModal = ({
  isOpen,
  setIsOpen,
  customerId,
  contact,
}: IAddEditCustomerAlternativeContactModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>
      <HeaderWrapper>
        <H3>
          {contact
            ? t('Edit alternative contact')
            : t('Create alternative contact')}
        </H3>
      </HeaderWrapper>
      <AddEditAltContactForm
        customerId={customerId}
        contact={contact}
        onSuccess={() => setIsOpen(false)}
      />
    </Modal>
  );
};

export default AddEditCustomerAlternativeContactModal;
