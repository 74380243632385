import { marginLg, marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 850rem;
  max-width: 1075rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};
  gap: 30rem;
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-top: ${marginLg};
`;
