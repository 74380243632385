import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Container,
  ContentWrapper,
  EditLabel,
  FirstRow,
  EditModeWrapper,
  Label,
  ShowMoreLabel,
  ValueWrapperRelative,
  EditIcon,
  ButtonWrapper,
} from './ExpandableSection.styled';
import { useEffect, useState } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { PencilSimple } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useEditCompanySettings } from '../../hooks';
import { toast } from 'react-toastify';
import { IEditCompanySettingsDTO } from 'types/Admin.types';

interface IExpandableSectionProps {
  companyId: number;
  type: ExpandableSectionTypeEnum;
  name: string;
  value: string;
  isExpanded: boolean;
  handleToggleExpand: (
    expandableSectionType: ExpandableSectionTypeEnum
  ) => void;
}

export enum ExpandableSectionTypeEnum {
  QUOTATION_PDF_FOOTER = 'Quotation PDF footer text',
  INVOICE_PDF_FOOTER = 'Invoice PDF footer text',
  ORDER_CONFIRMATION = 'Order Confirmation PDF footer text',
}

const ExpandableSection = ({
  companyId,
  type,
  name,
  value,
  isExpanded,
  handleToggleExpand,
}: IExpandableSectionProps) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const { mutate: editCompanySettings, isLoading } = useEditCompanySettings(
    Number(companyId!)
  );

  const handleEditCompanySettings = () => {
    const editCompanySettingsDTO: IEditCompanySettingsDTO = {
      [name]: inputValue === '' ? null : inputValue,
    };
    editCompanySettings(editCompanySettingsDTO, {
      onSuccess: () => {
        toast.success(t('Company settings updated'));
        setIsEdit(false);
      },
    });
  };

  return (
    <Container>
      <FirstRow>
        <Label>{t(type)}</Label>
        <ButtonWrapper>
          {isExpanded ? (
            <ValueWrapperRelative>
              <EditLabel onClick={() => setIsEdit(true)}>{t('Edit')}</EditLabel>
              <EditIcon>
                <Icon
                  onClick={() => setIsEdit(true)}
                  svg={PencilSimple}
                  color={COLORS.PRIMARY}
                  size={15}
                />
              </EditIcon>
            </ValueWrapperRelative>
          ) : (
            <div />
          )}
          <ShowMoreLabel onClick={() => handleToggleExpand(type)}>
            {isExpanded ? t('Show less') : t('Show more')}
          </ShowMoreLabel>
        </ButtonWrapper>
      </FirstRow>
      <ContentWrapper isExpanded={isExpanded}>
        {isEdit ? (
          <EditModeWrapper>
            <Input
              isTextArea
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <ButtonsWrapper>
              <Button
                disabled={isLoading}
                label={t('Cancel')}
                secondary
                width="150rem"
                onClick={() => {
                  setIsEdit(false);
                  setInputValue(value);
                }}
              />
              <Button
                disabled={isLoading}
                label={t('Save')}
                primary
                width="150rem"
                onClick={() => handleEditCompanySettings()}
              />
            </ButtonsWrapper>
          </EditModeWrapper>
        ) : (
          value
        )}
      </ContentWrapper>
    </Container>
  );
};

export default ExpandableSection;
