import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Label_PAYMENT_CONDITION', flexValue: 1, isBlue: false },
  { header: 'Payment condition text', flexValue: 1.2, isBlue: false },
  { header: 'Threshold', flexValue: 0.7, isBlue: false },
  { header: 'Percentage', flexValue: 0.7, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};

export const PAYMENT_CONDITIONS_PER_PAGE = 5;
