import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Field, Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  ColumnContainerSelect,
  Container,
  Label,
  RowSpaceBetween,
  Title,
  TitleContainer,
} from './EditGeneralCompanySettingsModal.styled';
import { Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { editGeneralCompanySettingsSchema } from './validation';
import { toast } from 'react-toastify';
import CustomSelect from 'components/Select/FormikSelect';
import { useGetCurrencyOptions, useGetQuotationTypeOptions } from './hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { transformEmptyValues } from 'utils/transformEmptyValues';
import { useEditCompanySettings } from '../hooks';
import { IEditCompanySettingsDTO } from 'types/Admin.types';

interface IEditGeneralCompanySettingsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  settingsData: any;
  companyId: string;
}
export const EditGeneralCompanySettingsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  settingsData,
  companyId,
}: IEditGeneralCompanySettingsModalProps) => {
  const { t } = useTranslation();

  const {
    mutate: editCompanySettings,
    isSuccess,
    isLoading,
  } = useEditCompanySettings(Number(companyId));

  const currencies = useSelector(
    (state: IRootReducerState) => state.commonInfo.currencies
  );
  const quotationTypeOptions = useGetQuotationTypeOptions();
  const currencyTypeOptions = useGetCurrencyOptions(currencies);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Company settings updated'));
      onCancel();
    }
  }, [isSuccess]);

  const handleEditCompanySettings = (values: any) => {
    const editCompanySettingsDTO: IEditCompanySettingsDTO = {
      currency_id: values.currency_id,
      tax_rate: values.tax_rate,
      working_hours_rate: values.working_hours_rate,
      days_valid_quotation: values.days_valid_quotation,
      days_valid_invoice: values.days_valid_invoice,
      default_new_quotation_type_id: Number(
        values.default_new_quotation_type_id
      ),
    };
    editCompanySettings(editCompanySettingsDTO);
  };

  const getInitialValues = () => {
    return {
      currency_id: settingsData?.currency.id.toString(),
      tax_rate: settingsData?.tax_rate as string,
      working_hours_rate: settingsData?.working_hours_rate as string,
      days_valid_quotation: settingsData?.days_valid_quotation as string,
      days_valid_invoice: settingsData?.days_valid_invoice as string,
      default_new_quotation_type_id:
        settingsData?.default_new_quotation_type_id?.toString() as string,
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        overflow: 'visible',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Company general settings')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={getInitialValues()}
          validationSchema={editGeneralCompanySettingsSchema}
          validateOnMount={true}
        >
          {({ values, setFieldValue, handleBlur, errors, isValid }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };
            return (
              <Container>
                <RowSpaceBetween>
                  <ColumnContainerSelect>
                    <Label>
                      <H4>{t('Currency').toUpperCase()}</H4>
                    </Label>
                    <Field
                      component={CustomSelect}
                      name="currency_id"
                      isMulti={false}
                      isDisabled={false}
                      isSearchable
                      options={currencyTypeOptions || []}
                      errorMessage={
                        errors['currency_id'] ? errors['currency_id'] : ''
                      }
                    />
                  </ColumnContainerSelect>

                  <ColumnContainer>
                    <Label>
                      <H4>{t('Days valid quotation').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['days_valid_quotation']
                          ? errors['days_valid_quotation']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'days_valid_quotation');
                      }}
                      height={'41rem'}
                      name="days_valid_quotation"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Tax rate').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['tax_rate'] ? errors['tax_rate'] : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'tax_rate');
                      }}
                      height={'41rem'}
                      name="tax_rate"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Days valid invoice').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['days_valid_invoice']
                          ? errors['days_valid_invoice']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'days_valid_invoice');
                      }}
                      height={'41rem'}
                      name="days_valid_invoice"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Working hours rate').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['working_hours_rate']
                          ? errors['working_hours_rate']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'working_hours_rate');
                      }}
                      height={'41rem'}
                      name="working_hours_rate"
                      wrapperStyles={{
                        width: '300rem',
                      }}
                    />
                  </ColumnContainer>

                  <ColumnContainerSelect>
                    <Label>
                      <H4>{t('Default new quotation type').toUpperCase()}</H4>
                    </Label>
                    <Field
                      component={CustomSelect}
                      name="default_new_quotation_type_id"
                      isMulti={false}
                      isDisabled={false}
                      isSearchable={false}
                      options={quotationTypeOptions}
                      errorMessage={
                        errors['default_new_quotation_type_id']
                          ? errors['default_new_quotation_type_id']
                          : ''
                      }
                    />
                  </ColumnContainerSelect>
                </RowSpaceBetween>

                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!isValid || isLoading}
                    onClick={() => {
                      if (isValid) {
                        handleEditCompanySettings(transformEmptyValues(values));
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
