import { RoutesConfig } from 'navigation/routes';
import { Roles } from 'types/Permissions.types';
import {
  House,
  Cardholder,
  ShoppingCart,
  CalendarBlank,
  UsersThree,
  Cube,
  ChartLineUp,
  FileText,
  Cards,
  Handshake,
  ListChecks,
} from '@phosphor-icons/react';

export const menuItems = [
  {
    icon: House,
    text: 'Home',
    route: RoutesConfig.Home.path,
    permission: Roles.PUBLIC,
  },
  {
    icon: Cards,
    text: 'Quotations',
    route: RoutesConfig.Quotations.path,
    permission: Roles.READ_SALES,
  },
  {
    icon: Cardholder,
    text: 'Sales Orders',
    route: RoutesConfig.SalesOrders.path,
    permission: Roles.READ_SALES,
  },
  {
    icon: ShoppingCart,
    text: 'Purchase Orders',
    route: RoutesConfig.PurchaseOrders.path,
    permission: Roles.READ_PURCHASE,
  },
  {
    icon: FileText,
    text: 'Invoices',
    route: RoutesConfig.Invoices.path,
    permission: Roles.READ_INVOICE,
  },
  {
    icon: CalendarBlank,
    text: 'Planning',
    route: RoutesConfig.Planning.path,
    permission: Roles.READ_PLANNING,
  },
  {
    icon: UsersThree,
    text: 'Customers',
    route: RoutesConfig.Customers.path,
    permission: Roles.READ_SALES,
  },
  {
    icon: Handshake,
    text: 'Suppliers',
    route: RoutesConfig.Suppliers.path,
    permission: Roles.READ_PURCHASE,
  },
  {
    icon: Cube,
    text: 'Stock Items',
    route: RoutesConfig.StockItems.path,
    permission: Roles.READ_STOCK,
  },
  {
    icon: ChartLineUp,
    text: 'Analytics',
    route: RoutesConfig.Analytics.path,
    permission: Roles.READ_COMPANY_ADMIN,
  },
  {
    icon: ListChecks,
    text: 'To Do',
    route: RoutesConfig.ToDo.path,
    permission: [
      Roles.READ_SALES,
      Roles.READ_INVOICE,
      Roles.READ_PURCHASE,
      Roles.READ_STOCK,
      Roles.READ_PLANNING,
      Roles.READ_COMPANY_ADMIN,
    ],
  },
];

export const BOTTOM_FADE =
  'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))';
export const TOP_FADE =
  'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1))';
export const TOP_AND_BOTTOM_FADE =
  'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))';
