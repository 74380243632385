import { COLORS, H3 } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  SubmitRow,
  Margin,
  FirstRow,
  RelativeDiscountFieldAndDiscountOptionsWrapper,
  AbsoluteDiscountOptionsWrapper,
  DiscountOptionLabel,
  Separator,
  SwitchWrapper,
  IsDraftLabel,
  TotalPurchasePriceWrapper,
  EyeWrapper,
  FieldWrapper,
  LabelsRow,
  LabelWrapper,
  AddLabelDropdownWrapper,
  SelectWrapper,
} from './OrderTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik, getIn } from 'formik';
import { Input } from 'components/Input/InputFormik';
import {
  ICreateQuotationOrderDetails,
  PAYMENT_CONDITIONS_PER_PAGE,
} from './constants';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  createQuotationOrderTabSchema,
  createSalesOrderOrderTabSchema,
} from './validation';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Hour } from 'assets/icons/hour.svg';
import { ReactComponent as Day } from 'assets/icons/day.svg';
import {
  calculateAndSetDownpayment,
  calculateSalesPriceExcludingDiscount,
  calculateSalesPriceWithDiscount,
  calculateTotalPurchasePrice,
  calculateTotalWorkingHoursPrice,
  generatePaymentConditionOptionOptionFromPaymentCondition,
  validateDownpayment,
} from './helpers';
import { ICreateQuotationProductFormDTO } from '../ProductTab/constants';
import { DiscountType } from 'types/Discount.types';
import FakeInput from 'components/FakeInput/FakeInput';
import { getDiscountErrorMessage } from '../ProductTab/ProductList/validation';
import { ICompanySettings } from 'types/Company.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { formatNumber } from 'utils/numberFormatter';
import { Switch } from '@mui/material';
import Tooltip from 'components/Tooltip/Tooltip';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Eye, EyeSlash, Percent, Pen } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { ILabel, LabelEntityTypeId } from 'types/EntityLabel.types';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { AddLabelDropdown } from 'components/AddLabelDropdown/AddLabelDropdown';
import { RecurringQuotationModal } from '../RecurringQuotationModal/RecurringQuotationModal';
import { IRecurringQuotationDetails } from '../constants';
import { Select } from 'components/Select/Select';
import CustomMenuList from 'components/Select/SelectComponents/CustomMenuList/CustomMenuList';
import { useGetPaymentConditionsInfinite } from '../hooks';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/Spinner/Spinner';
import { IPaymentCondition } from 'types/PaymentCondition.types';

interface IOrderTab {
  onForward: () => void;
  onBack: () => void;
  setOrderDetails: Dispatch<SetStateAction<ICreateQuotationOrderDetails>>;
  orderDetails: ICreateQuotationOrderDetails;
  productFormDtos: ICreateQuotationProductFormDTO[];
  entityType?: 'quotation' | 'salesOrder';
  prepopulatedPaymentCondition?: IPaymentCondition;
}

const OrderTab = ({
  onForward,
  onBack,
  setOrderDetails,
  orderDetails,
  productFormDtos,
  entityType,
  prepopulatedPaymentCondition,
}: IOrderTab) => {
  const [isPurchasePriceHidden, setIsPurchasePriceHidden] =
    useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();
  const companySettings: ICompanySettings = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  const {
    data: paymentConditionsData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useGetPaymentConditionsInfinite(PAYMENT_CONDITIONS_PER_PAGE, '');

  const paymentConditionOptions = useMemo(() => {
    if (paymentConditionsData?.pages?.length) {
      return paymentConditionsData.pages
        .map((page) => page.payment_conditions)
        .flat()
        .map((paymentCondition: IPaymentCondition) => {
          return generatePaymentConditionOptionOptionFromPaymentCondition(
            paymentCondition
          );
        });
    }
    return [];
  }, [paymentConditionsData]);

  const [isRecurringQuotationModalOpen, setIsRecurringQuotationModalOpen] =
    useState<boolean>(false);

  const handlePaymentConditionChange = (
    selectedCondition: any,
    setFieldValue: (field: string, value: any) => void,
    values: any,
    productFormDtos: ICreateQuotationProductFormDTO[]
  ) => {
    const {
      value,
      text,
      altText,
      downpaymentThreshold,
      downpaymentPercentage,
    } = selectedCondition;

    setFieldValue('paymentConditionId', value);
    setFieldValue('paymentConditionText', text);
    setFieldValue('downpaymentConditionText', altText || text);
    setFieldValue('downpaymentThreshold', downpaymentThreshold);
    setFieldValue('downpaymentPercentage', downpaymentPercentage);

    calculateAndSetDownpayment(
      values,
      productFormDtos,
      downpaymentThreshold,
      downpaymentPercentage,
      setFieldValue
    );
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          ...orderDetails,
          downpaymentThreshold:
            prepopulatedPaymentCondition?.downpayment_threshold.toString() ||
            '',
          downpaymentPercentage:
            prepopulatedPaymentCondition?.downpayment_percentage.toString() ||
            '',
        }}
        validationSchema={
          entityType === 'salesOrder'
            ? createSalesOrderOrderTabSchema()
            : createQuotationOrderTabSchema()
        }
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={() => {
          //onSubmit
        }}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          setTouched,
          isValid,
          values,
          errors,
          touched,
          setFieldError,
        }) => {
          const workingHoursDiscountErrorMsg = getDiscountErrorMessage(
            values.workingHoursDiscount,
            'Working hours discount',
            (
              Number(values.workingHoursRate) * Number(values.workingHours)
            ).toString(),
            values.workingHoursDiscountType,
            'total working hours price'
          );
          const salesPriceDiscountErrorMsg = getDiscountErrorMessage(
            values.discount,
            'Discount',
            calculateSalesPriceExcludingDiscount(values, productFormDtos),
            values.discountType,
            'total sales price'
          );
          const salesPriceWithDiscount = calculateSalesPriceWithDiscount(
            values,
            productFormDtos
          );

          return (
            <FormikContainer>
              <FirstRow>
                <H3>{t('Order details')}</H3>
                {entityType !== 'salesOrder' && (
                  <SwitchWrapper>
                    <IsDraftLabel isDraft={values.isDraft}>
                      {values.isDraft
                        ? t('Draft quotation')
                        : t('Definitive quotation')}
                    </IsDraftLabel>
                    <Tooltip
                      content={
                        values.isDraft
                          ? t('This quotation is a draft')
                          : t('This quotation is a definitive quotation')
                      }
                    >
                      <Field
                        data-testid={'draft-switch'}
                        name="isDraft"
                        component={Switch}
                        checked={!values.isDraft} // Switched for presentation purpose
                        onChange={() =>
                          setFieldValue('isDraft', !values.isDraft)
                        }
                      />
                    </Tooltip>
                  </SwitchWrapper>
                )}
              </FirstRow>
              <LabelsRow>
                <LabelWrapper>
                  {values.labels?.map((label: ILabel) => {
                    return (
                      <EntityLabel
                        key={label.id}
                        label={label}
                        tooltipContent={
                          label.name.length > 15
                            ? label.name
                            : label.description
                        }
                        onDelete={() =>
                          setFieldValue(
                            'labels',
                            values.labels?.filter(
                              (currentLabel: ILabel) =>
                                currentLabel.id !== label.id
                            )
                          )
                        }
                      />
                    );
                  })}
                </LabelWrapper>
                <AddLabelDropdownWrapper
                  $withoutMargin={values?.labels.length === 0}
                >
                  <AddLabelDropdown
                    entityType={LabelEntityTypeId.QUOTATION}
                    onClick={(newLabel: ILabel) => {
                      const labelExists = values.labels?.some(
                        (label: ILabel) => label.id === newLabel.id
                      );
                      if (!labelExists)
                        setFieldValue('labels', [...values.labels, newLabel]);
                    }}
                  />
                </AddLabelDropdownWrapper>
              </LabelsRow>
              <Row>
                <SelectWrapper>
                  {!isLoading ? (
                    <Select
                      name="paymentCondition"
                      placeholder={t('Payment condition')}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(selectedCondition: any) => {
                        handlePaymentConditionChange(
                          selectedCondition,
                          setFieldValue,
                          values,
                          productFormDtos
                        );
                      }}
                      options={paymentConditionOptions}
                      defaultValue={
                        prepopulatedPaymentCondition
                          ? generatePaymentConditionOptionOptionFromPaymentCondition(
                              prepopulatedPaymentCondition as IPaymentCondition
                            )
                          : paymentConditionOptions.filter(
                              (option) =>
                                option.value === values.paymentConditionId
                            )
                      }
                      translate={false}
                      components={{
                        MenuList: (props) => (
                          <CustomMenuList
                            label={t('Manage Payment Conditions')}
                            onClick={() =>
                              navigate(RoutesConfig.PaymentConditions.fullPath)
                            }
                            onMenuScrollToBottom={() =>
                              hasNextPage && fetchNextPage()
                            }
                            icon={Pen}
                            {...props}
                          />
                        ),
                      }}
                    />
                  ) : (
                    <Spinner />
                  )}
                </SelectWrapper>
              </Row>
              <Row>
                <Input
                  errorMessage={
                    touched['paymentConditionText']
                      ? errors['paymentConditionText']
                      : ''
                  }
                  height={'41rem'}
                  name="paymentConditionText"
                  placeholder={t('Payment condition text')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('paymentConditionText', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
                <Input
                  errorMessage={
                    touched['downpaymentConditionText']
                      ? errors['downpaymentConditionText']
                      : ''
                  }
                  height={'41rem'}
                  name="downpaymentConditionText"
                  placeholder={t('Downpayment condition text')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('downpaymentConditionText', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="reference-field"
                  errorMessage={touched['reference'] ? errors['reference'] : ''}
                  height={'41rem'}
                  name="reference"
                  placeholder={t('Reference')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('reference', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                    paddingRight: entityType !== 'salesOrder' ? '0' : '15rem',
                  }}
                />
                {entityType !== 'salesOrder' && (
                  <Input
                    pwId="days-valid-field"
                    icon={Day}
                    type="number"
                    errorMessage={
                      touched['daysValid'] ? errors['daysValid'] : ''
                    }
                    height={'41rem'}
                    name="daysValid"
                    placeholder={t('Days valid')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('daysValid', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                      marginBottom: '14rem',
                    }}
                  />
                )}
              </Row>
              <Row>
                <Input
                  icon={Hour}
                  type="number"
                  errorMessage={
                    touched['workingHours'] ? errors['workingHours'] : ''
                  }
                  height={'41rem'}
                  name="workingHours"
                  placeholder={t('Working hours')}
                  handleOnChange={(value) => {
                    setFieldValue('workingHours', value);
                    calculateAndSetDownpayment(
                      { ...values, workingHours: value },
                      productFormDtos,
                      values.downpaymentThreshold,
                      values.downpaymentPercentage,
                      setFieldValue
                    );
                  }}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('workingHours', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
                <Input
                  symbol={currencySymbol}
                  type="number"
                  errorMessage={
                    touched['workingHoursRate']
                      ? errors['workingHoursRate']
                      : ''
                  }
                  height={'41rem'}
                  name="workingHoursRate"
                  placeholder={t('Working hour rate')}
                  handleOnChange={(value) => {
                    setFieldValue('workingHoursRate', value);
                    calculateAndSetDownpayment(
                      { ...values, workingHoursRate: value },
                      productFormDtos,
                      values.downpaymentThreshold,
                      values.downpaymentPercentage,
                      setFieldValue
                    );
                  }}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('workingHoursRate', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
              </Row>
              <Separator />
              <Row>
                <FakeInput
                  label={formatNumber(calculateTotalWorkingHoursPrice(values))}
                  placeholder={t('Total working hours price')}
                  isDropdown={false}
                  width="50%"
                  marginBottom="30rem"
                />
                <RelativeDiscountFieldAndDiscountOptionsWrapper>
                  <AbsoluteDiscountOptionsWrapper>
                    <DiscountOptionLabel
                      isSelected={
                        getIn(values, 'workingHoursDiscountType') ===
                        DiscountType.PERCENTAGE
                      }
                      onClick={() => {
                        setFieldValue(
                          `workingHoursDiscountType`,
                          DiscountType.PERCENTAGE
                        );
                        calculateAndSetDownpayment(
                          {
                            ...values,
                            workingHoursDiscountType: DiscountType.PERCENTAGE,
                          },
                          productFormDtos,
                          values.downpaymentThreshold,
                          values.downpaymentPercentage,
                          setFieldValue
                        );
                      }}
                    >
                      {t('Percentage')}
                    </DiscountOptionLabel>
                    <DiscountOptionLabel
                      isSelected={
                        getIn(values, 'workingHoursDiscountType') ===
                        DiscountType.AMOUNT
                      }
                      onClick={() => {
                        setFieldValue(
                          `workingHoursDiscountType`,
                          DiscountType.AMOUNT
                        );
                        calculateAndSetDownpayment(
                          {
                            ...values,
                            workingHoursDiscountType: DiscountType.AMOUNT,
                          },
                          productFormDtos,
                          values.downpaymentThreshold,
                          values.downpaymentPercentage,
                          setFieldValue
                        );
                      }}
                    >
                      {t('Amount')}
                    </DiscountOptionLabel>
                  </AbsoluteDiscountOptionsWrapper>
                  <Input
                    errorMessage={
                      values.workingHours &&
                      values.workingHours !== '0' &&
                      values.workingHoursRate
                        ? workingHoursDiscountErrorMsg
                        : ''
                    }
                    type="number"
                    icon={
                      getIn(values, 'workingHoursDiscountType') ===
                      DiscountType.PERCENTAGE
                        ? Percent
                        : undefined
                    }
                    symbol={
                      getIn(values, 'workingHoursDiscountType') ===
                      DiscountType.AMOUNT
                        ? currencySymbol
                        : undefined
                    }
                    name={`workingHoursDiscount`}
                    placeholder={t('Working hours discount')}
                    handleOnChange={(value) => {
                      calculateAndSetDownpayment(
                        {
                          ...values,
                          workingHoursDiscount: value,
                        },
                        productFormDtos,
                        values.downpaymentThreshold,
                        values.downpaymentPercentage,
                        setFieldValue
                      );
                    }}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue(e.target.name, value);
                      handleBlur(e);
                    }}
                    height={'41rem'}
                    disabled={
                      !values.workingHours.length || values.workingHours === '0'
                    }
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </RelativeDiscountFieldAndDiscountOptionsWrapper>
              </Row>
              <Row>
                <FakeInput
                  label={formatNumber(salesPriceWithDiscount)}
                  placeholder={t('Total sales price')}
                  width="50%"
                  marginBottom="30rem"
                />
                <TotalPurchasePriceWrapper>
                  <FakeInput
                    secure={isPurchasePriceHidden}
                    label={formatNumber(
                      calculateTotalPurchasePrice(productFormDtos)
                    )}
                    placeholder={t('Expected total purchase price exc. VAT')}
                    width="100%"
                    height="41rem"
                  />
                  <EyeWrapper
                    onClick={() =>
                      setIsPurchasePriceHidden(!isPurchasePriceHidden)
                    }
                    data-testid={'eye-icon'}
                  >
                    <Icon
                      svg={isPurchasePriceHidden ? Eye : EyeSlash}
                      color={COLORS.BLACK}
                      size={20}
                    />
                  </EyeWrapper>
                </TotalPurchasePriceWrapper>
              </Row>
              <Row>
                <FieldWrapper>
                  <Input
                    pwId="down-payment-field"
                    symbol={currencySymbol}
                    type="number"
                    errorMessage={
                      touched['downPayment'] ? errors['downPayment'] : ''
                    }
                    height={'41rem'}
                    name="downPayment"
                    placeholder={t('Down payment')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('downPayment', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: '14rem',
                    }}
                  />
                </FieldWrapper>
              </Row>
              {entityType !== 'salesOrder' &&
                companySettings?.recurring_quotations_enabled && (
                  <Row>
                    <Button
                      width={'200rem'}
                      onClick={() => setIsRecurringQuotationModalOpen(true)}
                      label={t('Recurring')}
                      primary
                    />
                  </Row>
                )}
              <SubmitRow>
                <Button
                  width={'200rem'}
                  onClick={() => onBack()}
                  label={t('Back')}
                  secondary
                />
                <Margin>
                  <Button
                    width={'200rem'}
                    onClick={async () => {
                      const touched = {};
                      Object.keys(values).forEach((key) => {
                        touched[key] = true;
                      });
                      setTouched(touched); // Trigger validation for all fields
                      const downpaymentValid = validateDownpayment(
                        getIn(values, 'downPayment'),
                        Number(salesPriceWithDiscount),
                        setFieldError
                      );
                      if (
                        isValid &&
                        downpaymentValid &&
                        !workingHoursDiscountErrorMsg &&
                        !salesPriceDiscountErrorMsg
                      ) {
                        const {
                          downpaymentThreshold,
                          downpaymentPercentage,
                          ...filteredValues
                        } = values;

                        setOrderDetails(filteredValues);
                        onForward();
                      }
                    }}
                    label={t('Next')}
                    primary
                  />
                </Margin>
              </SubmitRow>
              <RecurringQuotationModal
                isOpen={isRecurringQuotationModalOpen}
                setIsOpen={setIsRecurringQuotationModalOpen}
                onCancel={() => setIsRecurringQuotationModalOpen(false)}
                onApply={(values: IRecurringQuotationDetails) => {
                  setFieldValue('recurringPeriodId', values.recurring_period);
                  setFieldValue('recurringTypeId', values.recurring_type);
                }}
                recurringDetails={{
                  recurring_period: values.recurringPeriodId || '',
                  recurring_type: values.recurringTypeId || '',
                }}
              />
            </FormikContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default OrderTab;
