import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getCompanyBilling,
  getCompanyLicenses,
  getUserLicenses,
} from 'services/Public/PublicService';

export const useGetCompanyLicenses = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY_LICENSES],
    queryFn: () => {
      return getCompanyLicenses();
    },
  });

export const useGetCompanyBilling = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY_BILLING],
    queryFn: () => {
      return getCompanyBilling();
    },
  });

export const useGetUserLicenses = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_USER_LICENSES],
    queryFn: () => {
      return getUserLicenses();
    },
  });
