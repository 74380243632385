import { borderRadiusSm, COLORS, paddingXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  disabled: boolean;
}

export const Container = styled.div`
  width: 95%;
  display: flex;
  justify-content: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10rem;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ItemWrapper = styled.div`
  border: 1px solid ${COLORS.PRIMARY};
  justify-content: center;
  width: 100%;
  height: 130rem;
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.WHITE};
  padding: ${paddingXs};
`;

export const SlideChevronWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  z-index: 5;
  user-select: none;
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0;
    `};
`;

export const PreviousSlideChevronWrapper = styled(SlideChevronWrapper)`
  left: -40rem;
`;

export const NextSlideChevronWrapper = styled(SlideChevronWrapper)`
  right: -40rem;
`;

export const ContentColumnContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  gap: 5rem;
`;

export const Label = styled.label`
  width: 100%;
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
  pointer-events: none;
`;

export const UserTimeLabel = styled(Label)`
  color: ${COLORS.GREY_900};
  font-size: 10rem;
`;

export const MessageLabel = styled(Label)`
  font-size: 12rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HorizontalLine = styled.div`
  width: 10rem;
  height: 1rem;
  background-color: ${COLORS.PRIMARY};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const LeftHorizontalLine = styled(HorizontalLine)`
  left: 0;
`;

export const RightHorizontalLine = styled(HorizontalLine)`
  right: 0;
`;
