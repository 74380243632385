import { useTranslation } from 'react-i18next';
import { Container, Title } from './CompanyConfigurationPage.styled';
import {
  useGetCompanySettings,
  useGetIsModalOpen,
  useGetSingleCompany,
} from './hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { CompanyConfigurationCard } from './CompanyConfigurationCard/CompanyConfigurationCard';
import { useGetCompanyCardData, useGetSettingsCardData } from './helpers';
import { PDFAdditionalTextCard } from './PDFAdditionalTextCard/PDFAdditionalTextCard';
import { EditGeneralCompanySettingsModal } from './EditGeneralCompanySettingsModal/EditGeneralCompanySettingsModal';
import { EditPrefixesCompanySettingsModal } from './EditPrefixesCompanySettingsModal/EditPrefixesCompanySettingsModal';
import { EditEmailCompanySettingsModal } from './EditEmailCompanySettingsModal/EditEmailCompanySettingsModal';
import { EditPaymentReminderSettingsModal } from './EditPaymentReminderSettingsModal/EditPaymentReminderSettingsModal';
import LogoCard from './LogoCard/LogoCard';
import { EditAppointmentReminderSettingsModal } from './EditAppointmentReminderSettingsModal/EditAppointmentReminderSettingsModal';
import { EditCommpanyDataModal } from './EditCompanyDataModal/EditCompanyDataModal';
import Spinner from 'components/Spinner/Spinner';

export const CompanyConfigurationPage = () => {
  const { t } = useTranslation();
  const id = useSelector((state: IRootReducerState) => state.companyInfo.id);

  const { data, isLoading: isLoadingGetCompany } = useGetSingleCompany(id);
  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    isSuccess: isSuccessSettings,
  } = useGetCompanySettings(id);

  const {
    companyConfigurationLeftData,
    companyConfigurationRightData,
    companyConfigurationExtraLeftData,
  } = useGetCompanyCardData(data);

  const {
    generalSettingsLeftData,
    generalSettingsRightData,
    emaillSettingsLeftData,
    emaillSettingsRightData,
    paymentReminderSettingsLeftData,
    paymentReminderSettingsRightData,
    appointmentSettingsLeftData,
    appointmentSettingsRightData,
    prefixesLeftData,
    prefixesRightData,
  } = useGetSettingsCardData(settingsData);

  const {
    isEditGeneralCompanySettingsModalOpen,
    isEditPrefixesCompanySettingsModalOpen,
    isEditEmailCompanySettingsModalOpen,
    isEditPaymentReminderCompanySettingsModalOpen,
    isEditAppointmentReminderCompanySettingsModalOpen,
    isEditCompanyDataModalOpen,
    setIsEditGeneralCompanySettingsModalOpen,
    setIsEditPrefixesCompanySettingsModalOpen,
    setIsEditEmailCompanySettingsModalOpen,
    setIsEditPaymentReminderCompanySettingsModalOpen,
    setIsEditAppointmentReminderCompanySettingsModalOpen,
    setIsEditCompanyDataModalOpen,
  } = useGetIsModalOpen();

  return (
    <Container>
      <Title>{t('Company configuration')}</Title>
      {isLoadingSettings || isLoadingGetCompany ? (
        <Spinner />
      ) : (
        <>
          <CompanyConfigurationCard
            cardTitle={t('Company data')}
            leftData={companyConfigurationLeftData}
            rightData={companyConfigurationRightData}
            leftSubtitle={t('Details')}
            rightSubtitle={t('Address')}
            extraLeftData={companyConfigurationExtraLeftData}
            extraLeftSubtitle={t('Bank')}
            onEditClick={() => setIsEditCompanyDataModalOpen(true)}
          />
          <LogoCard companyId={id} />
          <CompanyConfigurationCard
            cardTitle={t('General settings')}
            leftData={generalSettingsLeftData}
            rightData={generalSettingsRightData}
            onEditClick={() => setIsEditGeneralCompanySettingsModalOpen(true)}
          />
          <CompanyConfigurationCard
            cardTitle={t('Email settings')}
            leftData={emaillSettingsLeftData}
            rightData={emaillSettingsRightData}
            onEditClick={() => setIsEditEmailCompanySettingsModalOpen(true)}
          />
          {id == 1 && (
            <CompanyConfigurationCard
              cardTitle={t('Payment reminder settings')}
              leftData={paymentReminderSettingsLeftData}
              rightData={paymentReminderSettingsRightData}
              onEditClick={() =>
                setIsEditPaymentReminderCompanySettingsModalOpen(true)
              }
            />
          )}
          <CompanyConfigurationCard
            cardTitle={t('Appointment settings')}
            leftData={appointmentSettingsLeftData}
            rightData={appointmentSettingsRightData}
            onEditClick={() =>
              setIsEditAppointmentReminderCompanySettingsModalOpen(true)
            }
          />
          <CompanyConfigurationCard
            cardTitle={t('Prefixes')}
            leftData={prefixesLeftData}
            rightData={prefixesRightData}
            onEditClick={() => setIsEditPrefixesCompanySettingsModalOpen(true)}
          />
          <PDFAdditionalTextCard data={settingsData?.settings} companyId={id} />
        </>
      )}
      {isEditCompanyDataModalOpen && (
        <EditCommpanyDataModal
          isOpen={isEditCompanyDataModalOpen}
          setIsOpen={setIsEditCompanyDataModalOpen}
          onCancel={() => setIsEditCompanyDataModalOpen(false)}
          companyId={id}
          data={data}
        />
      )}
      <EditGeneralCompanySettingsModal
        settingsData={settingsData?.settings}
        isOpen={isEditGeneralCompanySettingsModalOpen}
        setIsOpen={setIsEditGeneralCompanySettingsModalOpen}
        onCancel={() => setIsEditGeneralCompanySettingsModalOpen(false)}
        companyId={id}
      />
      <EditEmailCompanySettingsModal
        settingsData={settingsData?.settings}
        isOpen={isEditEmailCompanySettingsModalOpen}
        setIsOpen={setIsEditEmailCompanySettingsModalOpen}
        onCancel={() => setIsEditEmailCompanySettingsModalOpen(false)}
        companyId={id}
      />
      <EditPrefixesCompanySettingsModal
        settingsData={settingsData?.settings}
        isOpen={isEditPrefixesCompanySettingsModalOpen}
        setIsOpen={setIsEditPrefixesCompanySettingsModalOpen}
        onCancel={() => setIsEditPrefixesCompanySettingsModalOpen(false)}
        companyId={id}
      />
      {id == 1 && (
        <EditPaymentReminderSettingsModal
          settingsData={settingsData?.settings}
          isOpen={isEditPaymentReminderCompanySettingsModalOpen}
          setIsOpen={setIsEditPaymentReminderCompanySettingsModalOpen}
          onCancel={() =>
            setIsEditPaymentReminderCompanySettingsModalOpen(false)
          }
          companyId={id}
        />
      )}
      <EditAppointmentReminderSettingsModal
        settingsData={settingsData?.settings}
        isOpen={isEditAppointmentReminderCompanySettingsModalOpen}
        setIsOpen={setIsEditAppointmentReminderCompanySettingsModalOpen}
        onCancel={() =>
          setIsEditAppointmentReminderCompanySettingsModalOpen(false)
        }
        companyId={id}
      />
    </Container>
  );
};
