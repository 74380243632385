import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { boldLabels, labels } from '../../constants';
import { getLicenseName } from '../../helpers';
import { ILicense } from '../../types';
import {
  Container,
  Dot,
  ThesisItem,
  Title,
  Content,
  Column,
  ThesisColumn,
  PriceSpan,
  PriceWrapper,
} from './BlueprintPlanCard.styled';

interface IBlueprintPlanCard {
  license: ILicense;
  selectedLicenseType: number;
  setSelectedLicenseType: Dispatch<SetStateAction<number>>;
}
export const BlueprintPlanCard = ({
  license,
  selectedLicenseType,
  setSelectedLicenseType,
}: IBlueprintPlanCard) => {
  const { t } = useTranslation();
  const isActive = license.id === selectedLicenseType;

  return (
    <Container
      active={isActive}
      onClick={() => setSelectedLicenseType(license.id)}
    >
      <Column>
        <Title active={isActive}>{t(getLicenseName(license.name))}</Title>
        <ThesisColumn>
          {labels[license.id]?.map((label: string, index: number) => {
            return (
              <ThesisItem key={`item-${index}`} active={isActive}>
                <Dot active={isActive} />
                <Content $bold={boldLabels.includes(label)}>{t(label)}</Content>
              </ThesisItem>
            );
          })}
        </ThesisColumn>
      </Column>
      <PriceWrapper active={isActive}>
        <PriceSpan>€{license.price}</PriceSpan>/ {t('month')}
      </PriceWrapper>
    </Container>
  );
};
