import { initializePageFilters } from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { IPlanningPageFilters } from 'store/Filters/types';
import { validateParsedPlanningPageFilters } from './validation';
import { IFetchCommonDataResponse } from 'types/Common.types';
import { setFrontendSettings } from 'store/Common/actions/common';
import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';

export const handleUpdatePlanningPageFilters = (
  dispatch: Dispatch<AnyAction>,
  commonData: IFetchCommonDataResponse
) => {
  try {
    const planningPageFilters: IPlanningPageFilters = JSON.parse(
      commonData.frontend_settings.planning_page_filters
    );
    if (!validateParsedPlanningPageFilters(planningPageFilters)) {
      return;
    }
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.PLANNING,
        data: planningPageFilters,
      })
    );
    dispatch(setFrontendSettings(commonData.frontend_settings));
  } catch (e) {
    console.warn(
      '[CommonDataProvider] Error while parsing planning_page_filters into JSON'
    );
  }
};
