import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IAddEditAltContactDTO } from './types';
import {
  createCustomerAlternativeContact,
  editCustomerAlternativeContact,
} from 'services/Customer/CustomerService';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';

export const useCreateCustomerAlternativeContact = (customerId: number) => {
  const { t } = useTranslation();
  return useMutation(
    (createAltContactDTO: IAddEditAltContactDTO) =>
      createCustomerAlternativeContact(customerId, createAltContactDTO),
    {
      onSuccess: () => {
        toast.success(t('Alternative contact added'), {
          className: ReactMutationKeys.CREATE_CUSTOMER_ALTERNATIVE_CONTACT,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_CUSTOMER_ALTERNATIVE_CONTACTS,
          customerId,
        ]);
      },
      onError: (error: ERPError) => toast.error(getToastErrorMessage(error)),
      mutationKey: ReactMutationKeys.CREATE_CUSTOMER_ALTERNATIVE_CONTACT,
    }
  );
};

export const useEditCustomerAlternativeContact = (
  customerId: number,
  contactId: number
) => {
  const { t } = useTranslation();
  return useMutation(
    (editAltContactDTO: IAddEditAltContactDTO) =>
      editCustomerAlternativeContact(customerId, contactId, editAltContactDTO),
    {
      onSuccess: () => {
        toast.success(t('Alternative contact edited'), {
          className: ReactMutationKeys.EDIT_CUSTOMER_ALTERNATIVE_CONTACT,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_CUSTOMER_ALTERNATIVE_CONTACTS,
          customerId,
        ]);
      },
      onError: (error: ERPError) => toast.error(getToastErrorMessage(error)),
      mutationKey: ReactMutationKeys.EDIT_CUSTOMER_ALTERNATIVE_CONTACT,
    }
  );
};
