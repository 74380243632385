import React, { useState } from 'react';
import {
  ActionButtons,
  Container,
  HeadlineContainer,
  Headline,
  ActionButton,
  ActionIcon,
  EditLabel,
  DeleteLabel,
  BodyContainer,
  ShowMoreLabel,
  Content,
  Row,
  NameLabel,
  ValueLabel,
  DoubleRow,
  LinkValue,
} from './ContactCard.styled';
import Icon from 'components/Icon/Icon';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS, H4 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { ICustomerAlternativeContact } from 'types/Customer.types';

interface IProps {
  contact: ICustomerAlternativeContact;
  index: number;
  onEdit: (contact: ICustomerAlternativeContact) => void;
  onDelete: (id: number) => void;
}

const ContactCard = ({ contact, index, onEdit, onDelete }: IProps) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <Container>
      <HeadlineContainer>
        <Headline>
          {contact.title} {contact.first_name} {contact.last_name}
        </Headline>
        <ActionButtons>
          <ActionButton onClick={() => onEdit(contact)}>
            <EditLabel>{t('Edit')}</EditLabel>
            <ActionIcon>
              <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
            </ActionIcon>
          </ActionButton>
          <ActionButton onClick={() => onDelete(contact.id)}>
            <DeleteLabel>{t('Delete')}</DeleteLabel>
            <ActionIcon>
              <Icon
                svg={Trash}
                size={18}
                color={COLORS.RED_200}
                weight="regular"
              />
            </ActionIcon>
          </ActionButton>
        </ActionButtons>
      </HeadlineContainer>
      {showMore && (
        <BodyContainer>
          <H4>{t('GENERAL')}</H4>
          <Content>
            <Row>
              <NameLabel>{t('Name')}</NameLabel>
              <ValueLabel>{contact.first_name}</ValueLabel>
            </Row>
            <Row>
              <NameLabel>{t('Country')}</NameLabel>
              <ValueLabel>{contact.address.country.name}</ValueLabel>
            </Row>
            <Row>
              <NameLabel>{t('Address')}</NameLabel>
              <ValueLabel>{contact.address.full_address}</ValueLabel>
            </Row>
            <Row>
              <NameLabel>{t('Zip code')}</NameLabel>
              <ValueLabel>{contact.address.zip_code}</ValueLabel>
            </Row>
            <DoubleRow>
              <Row>
                <NameLabel>{t('E-mail')}</NameLabel>
                <LinkValue href={'mailto:test@test.com'}>
                  <ValueLabel>{contact.email}</ValueLabel>
                </LinkValue>
              </Row>
              <Row>
                <NameLabel>{t('Alternative invoice E-mail')}</NameLabel>
                <LinkValue href={'mailto:test@test.com'}>
                  <ValueLabel>{contact.alternative_invoice_email}</ValueLabel>
                </LinkValue>
              </Row>
            </DoubleRow>
            <Row>
              <NameLabel>{t('Phone number')}</NameLabel>
              <LinkValue href={'tel:123123'}>
                <ValueLabel>{contact.phone}</ValueLabel>
              </LinkValue>
            </Row>
          </Content>
        </BodyContainer>
      )}
      <ShowMoreLabel onClick={() => setShowMore(!showMore)}>
        {showMore ? t('Show less') : t('Show more')}
      </ShowMoreLabel>
    </Container>
  );
};

export default ContactCard;
