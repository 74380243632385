import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { IRootReducerState } from 'store/store';
import { Roles } from 'types/Permissions.types';
import { IRoleObj } from 'utils/hooks/useHasRole';

interface IMenuItem {
  icon?: any;
  text?: string;
  route: string;
  permission?: number | boolean | number[];
}

export const useGetActiveCurrentRoute = (menuItems: IMenuItem[]) => {
  const location = useLocation();
  let currentRoute: IMenuItem | null = null;

  // Take first word before '/' in URL for checking with route
  const inputParts = location.pathname.split('/').filter(Boolean);
  const inputPrefix = inputParts[0];

  for (const menuItem of Object.values(menuItems)) {
    const routeParts = menuItem.route.split('/').filter(Boolean);
    const routePrefix = routeParts[0];

    // When some of invoices submenu's is active to set main menu Invoices active
    if (
      [
        'sale-invoices',
        'sale-credit-invoices',
        'purchase-invoices',
        'purchase-credit-invoices',
      ].includes(inputPrefix)
    ) {
      currentRoute = { route: 'invoices' };
      break;
    }

    // When URL prefix and route prefix are the same we return the object with route which is active
    if (inputPrefix === routePrefix) {
      currentRoute = menuItem;
      break;
    }
  }

  return currentRoute;
};

export const useCheckPermissions = (menuItems: IMenuItem[]) => {
  const [filteredMenuItems, setFilteredMenuItems] = useState<IMenuItem[]>([]);
  const roles = useSelector(
    (state: IRootReducerState) => state.userInfo.permission_groups
  );

  useEffect(() => {
    if (roles) {
      const filteredItems = menuItems.filter((item) => {
        if (item.text === 'To Do') {
          const hasAllowedRoles = roles.some((role: IRoleObj) =>
            [
              Roles.READ_SALES,
              Roles.READ_INVOICE,
              Roles.READ_PURCHASE,
              Roles.READ_STOCK,
              Roles.READ_PLANNING,
              Roles.READ_COMPANY_ADMIN,
            ].includes(role.id)
          );

          const hasFieldWorkerRole = roles.some(
            (role: IRoleObj) => role.id === Roles.READ_FIELD_WORKER
          );

          return hasAllowedRoles || hasFieldWorkerRole;
        }

        if (Array.isArray(item.permission)) {
          return item.permission.some((perm) =>
            roles.some((role: IRoleObj) => role.id === perm)
          );
        } else {
          return (
            item.permission === Roles.PUBLIC ||
            roles.some((role: IRoleObj) => role.id === item.permission)
          );
        }
      });

      setFilteredMenuItems(filteredItems);
    }
  }, [menuItems, roles]);

  return filteredMenuItems;
};
