import {
  CompanyLogoImg,
  Container,
  EditIcon,
  EditLabel,
  LoaderAndImageWrapper,
  RowSpaceBetween,
  Title,
  ValueWrapperRelative,
} from './LogoCard.styled';
import { useTranslation } from 'react-i18next';
import { FileUploadModal } from 'components/Modal/FileUploadModal/FileUploadModal';
import { useEffect, useState } from 'react';
import Spinner from 'components/Spinner/Spinner';
import { useGetCompanyLogo, useUploadCompanyLogo } from '../hooks';
import NoImage from 'assets/images/noImage.png';
import { PencilSimple } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';

interface ILogoCardProps {
  companyId: string;
}

const LogoCard = ({ companyId }: ILogoCardProps) => {
  const { t } = useTranslation();
  const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] =
    useState<boolean>(false);

  const { data: companyLogo, isLoading: isLoadingCompanyLogo } =
    useGetCompanyLogo(companyId);

  const { mutate: uploadCompanyLogo, isSuccess: isSuccessUploadCompanyLogo } =
    useUploadCompanyLogo(Number(companyId));

  const handleUploadLogo = async (file: any) => {
    const blob = await fetch(file).then((r) => r.blob());
    uploadCompanyLogo(blob);
  };

  useEffect(() => {
    if (isSuccessUploadCompanyLogo) {
      setIsUploadLogoModalOpen(false);
    }
  }, [isSuccessUploadCompanyLogo]);

  return (
    <Container>
      <RowSpaceBetween>
        <Title>{t('Logo')}</Title>
        <ValueWrapperRelative onClick={() => setIsUploadLogoModalOpen(true)}>
          <EditLabel>{t('Edit')}</EditLabel>
          <EditIcon>
            <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
          </EditIcon>
        </ValueWrapperRelative>
      </RowSpaceBetween>
      <LoaderAndImageWrapper>
        {isLoadingCompanyLogo ? (
          <Spinner />
        ) : (
          <CompanyLogoImg src={companyLogo ? companyLogo : NoImage} />
        )}
      </LoaderAndImageWrapper>
      <FileUploadModal
        isUploadProfilePicture={true}
        isOpen={isUploadLogoModalOpen}
        setIsOpen={setIsUploadLogoModalOpen}
        onSubmit={(file: any) => handleUploadLogo(file)}
        customAllowedTypes={{
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
        }}
      />
    </Container>
  );
};
export default LogoCard;
