import styled, { css } from 'styled-components';
import { borderRadiusSm, COLORS, marginMd, marginSm } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';

interface StyledProps {
  isSelected: boolean;
  marginRight: string;
}

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 770rem;
  min-height: 385rem;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 45rem;
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  align-items: center;
  gap: 30rem;
  margin-top: 14rem; //30-16
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
`;

export const StyledCustomSelectSmall = styled(CustomSelect)`
  width: 100%;
`;

export const AlignRow = styled(Row)`
  justify-content: center;
`;

export const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${marginSm};
`;

export const SelectsWrapper = styled.div`
  display: flex;
  gap: 30rem;
  margin-bottom: ${marginSm};
  margin-top: 14rem;
`;

export const ExistingOptionLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-underline-position: under;
    `}
  ${(props: StyledProps) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
`;

export const SelectWrapper = styled.div`
  width: 50%;
`;

export const CheckboxContainer = styled.div`
  margin-bottom: ${marginSm};
`;

export const CustomSelectWrapper = styled.div`
  width: 50%;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: calc(50% - 15rem);
  width: 100%;
  margin-bottom: 14rem;
`;
