import { ILogyxConfigurationIntent } from 'store/Logyx/types';
import { DiscountType } from 'types/Discount.types';
import { IProductAttribute } from 'types/Product.types';
import { ISupplier } from 'types/Supplier.types';

export enum CreateSalesOrderTab {
  CUSTOMER_DETAILS = 'customer',
  PRODUCTS = 'products',
  ORDER_DETAILS = 'order_details',
  OVERVIEW = 'overview',
}

export interface ICreateSalesOrderProductFormDTO {
  uid?: number;
  product: any;
  supplier: ISupplier;
  quantity: string;
  salesPrice: string;
  purchasePrice: string;
  placement: string;
  description: string;
  discount: string;
  discountType: DiscountType;
  attributes: IProductAttribute[];
  logyxConfigurationIntent: ILogyxConfigurationIntent | null;
  isStockItem: boolean;
  stockItemId?: number;
  stockItemType?: string;
}
