import * as Yup from 'yup';

export const editEmailCompanySettingsSchema = () => {
  return Yup.object().shape({
    appointment_lower_interval: Yup.number()
      .typeError('Must be a number')
      .nullable(),
    appointment_upper_interval: Yup.number()
      .typeError('Must be a number')
      .nullable(),
    appointment_days_before_reminder: Yup.number()
      .typeError('Must be a number')
      .nullable(),
    appointment_reminder_hour: Yup.string().nullable(),
  });
};
