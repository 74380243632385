import * as Yup from 'yup';

export const companyDetailsSchema = () => {
  return Yup.object().shape({
    company_name: Yup.string().required('Name is required'),
    street: Yup.string().required('Street is required'),
    house_number: Yup.string().required('House number is required'),
    company_vat: Yup.string().required('VAT is required'),
    company_coc: Yup.string().required('COC number is required'),
    country_code: Yup.string().required('Country is required'),
    phone: Yup.string().required('Phone number is required'),
    website: Yup.string().required('Website is required'),
    zip_code: Yup.string().required('Zip code is required'),
    city: Yup.string().required('City is required'),
  });
};
