import { marginSm } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

import {
  ButtonsWrapper,
  Container,
  FormikContainer,
  Row,
  Subtitle,
  Text,
} from '../SignUpPage.styled';
import { ISignUpBankDetails } from '../types';
import { bankDetailsSchema } from './validation';
interface IBankDetailsTabProps {
  onForward: () => void;
  onBack: () => void;
  bankDetails: ISignUpBankDetails;
  setBankDetails: Dispatch<SetStateAction<ISignUpBankDetails>>;
}
export const BankDetailsTab = ({
  onForward,
  onBack,
  bankDetails,
  setBankDetails,
}: IBankDetailsTabProps) => {
  const { t } = useTranslation();
  const { isPhone } = useBreakpointFlag();
  const customWidth = isPhone ? '100%' : '280rem';

  return (
    <Container>
      <Subtitle>{t('Bank details')}</Subtitle>
      <Text>{`${t('Fill out the Bank Details for your company')}.`}</Text>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        initialValues={bankDetails}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        validationSchema={bankDetailsSchema}
      >
        {({ values, errors, touched, isValid, handleBlur, setFieldValue }) => (
          <FormikContainer>
            <Row>
              <Input
                errorMessage={touched['bank_name'] ? errors['bank_name'] : ''}
                height={'41rem'}
                name="bank_name"
                placeholder={t('Bank Name')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('bank_name', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                }}
              />
              <Input
                errorMessage={touched['bank_code'] ? errors['bank_code'] : ''}
                height={'41rem'}
                name="bank_code"
                placeholder={t('Bank Code')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('bank_code', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                  marginLeft: isPhone ? 0 : marginSm,
                }}
              />
            </Row>
            <Input
              errorMessage={
                touched['account_holder_name']
                  ? errors['account_holder_name']
                  : ''
              }
              height={'41rem'}
              name="account_holder_name"
              placeholder={t('Bank Holder Name')}
              onBlur={(e) => {
                const value = e.target.value.trim();
                setFieldValue('account_holder_name', value);
                handleBlur(e);
              }}
            />

            <Input
              errorMessage={touched['iban'] ? errors['iban'] : ''}
              height={'41rem'}
              name="iban"
              placeholder={t('Bank IBAN')}
              onBlur={(e) => {
                const value = e.target.value.trim();
                setFieldValue('iban', value);
                handleBlur(e);
              }}
            />

            <ButtonsWrapper>
              <Button
                width={customWidth}
                onClick={() => {
                  onBack();
                }}
                label={t('Back')}
                secondary
              />

              <Button
                disabled={!isValid}
                onClick={() => {
                  if (isValid) {
                    setBankDetails(values);
                    onForward();
                  }
                }}
                label={t('Next')}
                primary
                width={customWidth}
              />
            </ButtonsWrapper>
          </FormikContainer>
        )}
      </Formik>
    </Container>
  );
};
