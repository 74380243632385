import { marginSm } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import { Field, Formik } from 'formik';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICountry } from 'store/Common/types';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { getInitialCompanyDetails, sortCountries } from '../helpers';
import { useGetCountries } from '../hooks';

import {
  ButtonsWrapper,
  Container,
  FormikContainer,
  Row,
  StyledCustomSelect,
  Subtitle,
  Text,
} from '../SignUpPage.styled';
import { ISignUpCompanyDetails, ISignUpVerificationDetails } from '../types';
import { companyDetailsSchema } from './validation';
interface ICompanyDetailsTabProps {
  onForward: () => void;
  onBack: () => void;
  companyDetails: ISignUpCompanyDetails;
  verificationDetails: ISignUpVerificationDetails;
  setCompanyDetails: Dispatch<SetStateAction<ISignUpCompanyDetails>>;
}
export const CompanyDetailsTab = ({
  onForward,
  onBack,
  companyDetails,
  setCompanyDetails,
  verificationDetails,
}: ICompanyDetailsTabProps) => {
  const { t } = useTranslation();
  const { data: countriesData } = useGetCountries();

  const countryOptions = useMemo(() => {
    if (countriesData?.countries?.length) {
      return sortCountries(countriesData?.countries).map(
        (country: ICountry) => {
          return {
            value: country.cca2,
            label: `${country.cca2} - ${t(country.name)}`,
            key: country.name,
          };
        }
      );
    }
    return [];
  }, [countriesData]);

  const { isPhone } = useBreakpointFlag();
  const customWidth = isPhone ? '100%' : '280rem';

  return (
    <Container>
      <Subtitle>{t('Company Details')}</Subtitle>
      <Text>{t('Fill in or check the accuracy of your Company Details')}</Text>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        initialValues={getInitialCompanyDetails(
          companyDetails,
          verificationDetails
        )}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        validationSchema={companyDetailsSchema}
      >
        {({ values, errors, touched, isValid, handleBlur, setFieldValue }) => (
          <FormikContainer>
            <Input
              errorMessage={
                touched['company_name'] ? errors['company_name'] : ''
              }
              height={'41rem'}
              name="company_name"
              placeholder={t('Company Name')}
              onBlur={(e) => {
                const value = e.target.value.trim();
                setFieldValue('company_name', value);
                handleBlur(e);
              }}
            />

            <Row>
              <Input
                disabled
                errorMessage={
                  touched['company_vat'] ? errors['company_vat'] : ''
                }
                height={'41rem'}
                name="company_vat"
                placeholder={t('Company VAT Number')}
                wrapperStyles={{
                  width: customWidth,
                }}
              />
              <Input
                errorMessage={
                  touched['company_coc'] ? errors['company_coc'] : ''
                }
                height={'41rem'}
                name="company_coc"
                placeholder={t('Company COC Number')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('company_coc', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                  marginLeft: isPhone ? 0 : marginSm,
                }}
              />
            </Row>
            <Row>
              <Input
                errorMessage={touched['street'] ? errors['street'] : ''}
                height={'41rem'}
                name="street"
                placeholder={t('Street')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('street', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                }}
              />
              <Input
                errorMessage={
                  touched['house_number'] ? errors['house_number'] : ''
                }
                height={'41rem'}
                name="house_number"
                placeholder={t('House Number')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('house_number', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                  marginLeft: isPhone ? 0 : marginSm,
                }}
              />
            </Row>

            <Row>
              <Input
                errorMessage={touched['city'] ? errors['city'] : ''}
                height={'41rem'}
                name="city"
                placeholder={t('City')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('city', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                }}
              />

              <Input
                errorMessage={touched['zip_code'] ? errors['zip_code'] : ''}
                height={'41rem'}
                name="zip_code"
                placeholder={t('Zip code')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('zip_code', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                  marginLeft: isPhone ? 0 : marginSm,
                }}
              />
            </Row>

            <Row>
              <Field
                errorMessage={
                  touched['country_code'] ? errors['country_code'] : ''
                }
                height={'41rem'}
                name="country_code"
                width={customWidth}
                options={countryOptions}
                component={StyledCustomSelect}
                placeholder={t('Country Code')}
                isMulti={false}
                onSelect={(value: string) =>
                  setFieldValue('country_code', value)
                }
              />
              <Input
                errorMessage={touched['phone'] ? errors['phone'] : ''}
                height={'41rem'}
                name="phone"
                placeholder={t('Phone Number')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('phone', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: customWidth,
                  marginLeft: isPhone ? 0 : marginSm,
                }}
              />
            </Row>

            <Input
              errorMessage={touched['website'] ? errors['website'] : ''}
              height={'41rem'}
              name="website"
              placeholder={t('Company Website')}
              onBlur={(e) => {
                const value = e.target.value.trim();
                setFieldValue('website', value);
                handleBlur(e);
              }}
            />

            <ButtonsWrapper>
              <Button
                width={customWidth}
                onClick={() => {
                  onBack();
                }}
                label={t('Back')}
                secondary
              />

              <Button
                disabled={!isValid}
                onClick={() => {
                  if (isValid) {
                    setCompanyDetails(values);
                    onForward();
                  }
                }}
                label={t('Next')}
                primary
                width={customWidth}
              />
            </ButtonsWrapper>
          </FormikContainer>
        )}
      </Formik>
    </Container>
  );
};
