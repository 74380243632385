import { marginMd, marginXs } from 'assets/styled';
import Button from 'components/Button/Button';
import CustomSelect from 'components/Select/FormikSelect';
import { Field } from 'formik';
import styled from 'styled-components';

export const Row = styled.div`
  margin-top: ${marginXs};
  display: flex;
  justify-content: space-between;
  gap: 30rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: calc(50% - 15rem);
  width: 100%;
  margin-bottom: 14rem;
`;

export const StyledCustomSelect = styled(CustomSelect)`
  height: 100%; // Prevent error message from overflowing
  width: 100%;
`;

export const StyledSelect = styled(Field)`
  width: 100%;
  padding-right: 15rem;
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonCreate = styled(Button)`
  margin-left: ${marginMd};
`;

export const SelectWrapper = styled.div`
  width: 50%;
`;
