import { AnyAction } from '@reduxjs/toolkit';
import {
  IPurchaseInvoiceStatus,
  ISalesInvoiceStatus,
} from 'types/Invoice.types';
import { INotificationType } from 'types/Notification.types';
import { IPurchaseOrderStatuses } from 'types/PurchaseOrders.types';
import { IQuotationAction, IQuotationStatus } from 'types/Quotations.types';
import { ISalesOrderStatuses } from 'types/SalesOrders.types';
import {
  SET_IS_DARK_MODE,
  SET_IS_FIELD_WORKER_VIEW,
  SET_IS_MOBILE_APP,
  SET_IS_SESSION_EXPIRED,
  SET_IS_UPDATE_AVAILABLE,
  TOGGLE_SIDE_MENU,
  SET_IS_SOCKET_CONNECTED,
  SET_RUN_TOUR,
  SET_COMMON_DATA,
  SET_IS_LOADING_SCREEN_SHOWN,
  SET_MARK_AS_CONNECTED_INVOICE_ID,
  SET_FRONTEND_SETTINGS,
  SET_IS_SAVING_PLANNING_PAGE_FILTERS_AND_LOGGING_OUT,
} from '../constants';
import { ICountry } from '../types';
import { IFrontendSettings } from 'types/Common.types';
import { LOGOUT } from 'store/User/constants';

export interface ICommonReducerState {
  isMobileApp: boolean;
  isFieldWorkerView: boolean;
  darkMode: boolean;
  countries: ICountry[] | null;
  notificationTypes: INotificationType[] | null;
  quotationStatuses: IQuotationStatus[] | null;
  purchaseOrderStatuses: IPurchaseOrderStatuses | null;
  salesOrderStatuses: ISalesOrderStatuses | null;
  salesInvoiceStatuses: ISalesInvoiceStatus[] | null;
  purchaseInvoiceStatuses: IPurchaseInvoiceStatus[] | null;
  quotationActions: IQuotationAction[] | null;
  customerTitles: string[] | null;
  isSessionExpired: boolean;
  isUpdateAvailable: boolean;
  isSideMenuCollapsed: boolean;
  isSocketConnected: boolean;
  runTour: boolean;
  isLoadingScreenShown: boolean;
  isSavingPlanningFiltersAndLoggingOut: boolean;
  markAsConnectedInvoiceId: number | string | null;
  frontendSettings: IFrontendSettings | null;
}

export const INIT_STATE: ICommonReducerState = {
  isMobileApp: false,
  isFieldWorkerView: false,
  darkMode: false,
  countries: [],
  notificationTypes: [],
  quotationStatuses: [],
  purchaseOrderStatuses: {
    purchase_order_statuses: [],
    purchase_order_payment_statuses: [],
    line_payment_statuses: [],
    line_delivery_statuses: [],
  },
  salesOrderStatuses: {
    statuses: [],
    sub_statuses: [],
  },
  salesInvoiceStatuses: [],
  purchaseInvoiceStatuses: [],
  quotationActions: [],
  customerTitles: [],
  isSessionExpired: false,
  isUpdateAvailable: false,
  isSideMenuCollapsed: false,
  isSocketConnected: false,
  runTour: false,
  isLoadingScreenShown: false,
  markAsConnectedInvoiceId: null,
  frontendSettings: null,
  isSavingPlanningFiltersAndLoggingOut: false,
};

const commonDataReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_IS_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };
    case SET_IS_UPDATE_AVAILABLE:
      return {
        ...state,
        isUpdateAvailable: action.payload,
      };
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        isSideMenuCollapsed:
          action.payload !== undefined
            ? action.payload
            : !state.isSideMenuCollapsed,
      };
    case SET_IS_FIELD_WORKER_VIEW:
      return {
        ...state,
        isFieldWorkerView: action.payload,
      };
    case SET_IS_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    case SET_IS_MOBILE_APP:
      return {
        ...state,
        isMobileApp: action.payload,
      };
    case SET_IS_SOCKET_CONNECTED:
      return {
        ...state,
        isSocketConnected: action.payload,
      };
    case SET_RUN_TOUR:
      return {
        ...state,
        runTour: action.payload,
      };
    case SET_COMMON_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_IS_LOADING_SCREEN_SHOWN:
      return {
        ...state,
        isLoadingScreenShown: action.payload,
      };
    case SET_MARK_AS_CONNECTED_INVOICE_ID:
      return {
        ...state,
        markAsConnectedInvoiceId: action.payload,
      };
    case SET_FRONTEND_SETTINGS:
      return {
        ...state,
        frontendSettings: action.payload,
      };
    case SET_IS_SAVING_PLANNING_PAGE_FILTERS_AND_LOGGING_OUT: {
      return {
        ...state,
        isSavingPlanningFiltersAndLoggingOut: action.payload,
      };
    }
    case LOGOUT:
      return {
        ...state,
        isSavingPlanningFiltersAndLoggingOut: false,
      };
    default:
      return state;
  }
};

export default commonDataReducer;
