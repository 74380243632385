import { PHONE_NUMBER_REGEX } from 'utils/validation';
import * as Yup from 'yup';

export const createQuotationCustomerTabSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Name is required'),
    last_name: Yup.string().required('Last name is required'),
    title: Yup.string().required('Title is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
      .required('Phone is required'),
    email: Yup.string().email('Invalid E-mail').required('E-mail is required'),
    alternative_invoice_email: Yup.string()
      .email('Invalid alternative E-mail')
      .optional(),
    street: Yup.string()
      .min(1, 'Street should be between 1 and 80 characters')
      .max(80, 'Street should be between 1 and 80 characters')
      .required('Street is required'),
    code: Yup.string()
      .min(3, 'Zip code should be between 3 and 10 characters')
      .max(10, 'Zip code should be between 3 and 10 characters')
      .required('Zip code is required'),
    house_number: Yup.string().required('House number is required'),
    city: Yup.string()
      .min(1, 'City should be between 1 and 80 characters')
      .max(80, 'City should be between 1 and 80 characters')
      .required('City is required'),
    company_name: Yup.string().optional().nullable(),
    vat_number: Yup.string()
      .min(3, 'VAT number should be between 3 and 50 characters')
      .max(50, 'VAT number should be between 3 and 50 characters')
      .optional(),
    shipping_data: Yup.object().shape({
      city: Yup.string()
        .min(1, 'City should be between 1 and 80 characters')
        .max(80, 'City should be between 1 and 80 characters')
        .required('City is required'),
      company_name: Yup.string().optional().nullable(),
      country: Yup.string().required('Country is required'),
      phone: Yup.string()
        .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
        .required('Phone is required'),
      first_name: Yup.string().required('First name is required'),
      house_number: Yup.string().required('House number is required'),
      last_name: Yup.string().required('Last name is required'),
      street: Yup.string()
        .min(1, 'Street should be between 1 and 80 characters')
        .max(80, 'Street should be between 1 and 80 characters')
        .required('Street is required'),
      zip_code: Yup.string()
        .min(3, 'Zip code should be between 3 and 10 characters')
        .max(10, 'Zip code should be between 3 and 10 characters')
        .required('Zip code is required'),
      email: Yup.string()
        .email('Invalid E-mail')
        .required('E-mail is required'),
      title: Yup.string().required('Title is required'),
    }),
  });
};

export const createQuotationNoShipToAddressCustomerTabSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Name is required'),
    last_name: Yup.string().required('Last name is required'),
    title: Yup.string().required('Title is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
      .required('Phone is required'),
    email: Yup.string().email('Invalid E-mail').required('E-mail is required'),
    alternative_invoice_email: Yup.string()
      .email('Invalid alternative E-mail')
      .optional(),
    street: Yup.string()
      .min(1, 'Street should be between 1 and 80 characters')
      .max(80, 'Street should be between 1 and 80 characters')
      .required('Street is required'),
    code: Yup.string()
      .min(3, 'Zip code should be between 3 and 10 characters')
      .max(10, 'Zip code should be between 3 and 10 characters')
      .required('Zip code is required'),
    house_number: Yup.string().required('House number is required'),
    city: Yup.string()
      .min(1, 'City should be between 1 and 80 characters')
      .max(80, 'City should be between 1 and 80 characters')
      .required('City is required'),
    company_name: Yup.string().optional().nullable(),
    vat_number: Yup.string()
      .min(3, 'VAT number should be between 3 and 50 characters')
      .max(50, 'VAT number should be between 3 and 50 characters')
      .optional(),
  });
};
