import { IPlanningPageFilters, IPlanningTabFilter } from 'store/Filters/types';

const canParseAsNumbers = (arr: string[]): boolean => {
  return arr.every((item) => !isNaN(Number(item)));
};

const isValidOption = (option: any): boolean => {
  return (
    option !== null &&
    typeof option === 'object' &&
    'value' in option &&
    'label' in option &&
    'key' in option
  );
};

export const areTabsValid = (json: any[]): boolean => {
  // Get all required fields from the interface
  const requiredFields: (keyof IPlanningTabFilter)[] = [
    'id',
    'name',
    'startDate',
    'endDate',
    'filterDate',
    'selectedWorkers',
    'selectedResources',
    'selectedUsers',
    'selectedAppointmentTypeOptions',
    'selectedAppointmentViewOption',
  ];

  for (const tab of json) {
    // Check if any field from interface is missing
    for (const field of requiredFields) {
      if (!(field in tab)) {
        return false;
      }
    }

    // Check if any unexpected fields exist in the JSON
    for (const field in tab) {
      if (!requiredFields.includes(field as keyof IPlanningTabFilter)) {
        return false;
      }
    }

    // Check arrays for number parsing
    if (
      !Array.isArray(tab.selectedWorkers) ||
      !canParseAsNumbers(tab.selectedWorkers) ||
      !Array.isArray(tab.selectedResources) ||
      !canParseAsNumbers(tab.selectedResources) ||
      !Array.isArray(tab.selectedUsers) ||
      !canParseAsNumbers(tab.selectedUsers)
    ) {
      return false;
    }

    // Check appointment type options array
    if (
      !Array.isArray(tab.selectedAppointmentTypeOptions) ||
      !tab.selectedAppointmentTypeOptions.every(isValidOption)
    ) {
      return false;
    }

    // Check appointment view option
    if (
      tab.selectedAppointmentViewOption !== null &&
      !isValidOption(tab.selectedAppointmentViewOption)
    ) {
      return false;
    }
  }

  return true;
};

export const validateParsedPlanningPageFilters = (
  planningPageFilters: IPlanningPageFilters
) => {
  const isValid =
    planningPageFilters.activeTabId &&
    planningPageFilters.deletedTabIds &&
    planningPageFilters.tabs &&
    areTabsValid(planningPageFilters.tabs);
  if (!isValid) {
    console.warn(
      '[CommonDataProvider] Error while initializing planningPageFilters'
    );
  }
  return isValid;
};
