import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Input } from 'components/Input/InputFormik';
import { Formik } from 'formik';
import { useCreateAppointment } from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { GeneralTabOptions } from 'pages/Manager/PlanningPage/NewAppointmentModal/GeneralTab/constants';
import { ICreateAppointmentDTO } from 'pages/Manager/PlanningPage/types';
import {
  useConfirmAppointment,
  useSendAppointmentEmail,
} from 'pages/Manager/PlanningPage/ViewAppointmentModal/hooks';
import { useSendSalesInvoiceEmail } from 'pages/Manager/SingleInvoice/SingleSalesInvoice/hooks';
import { useSendQuotationEmail } from 'pages/Manager/SingleQuotation/hooks';
import { useSendSalesOrderEmail } from 'pages/Manager/SingleSalesOrder/hooks';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentEmailType } from 'types/Appointment.types';
import { Modal } from '../Modal';
import {
  AlignRow,
  BlueValue,
  CloseContainer,
  EmailCell,
  EmailRow,
  FormikContainer,
  Label,
  Margin,
  Row,
  SubmitRow,
  Title,
  TitleContainer,
  Value,
} from './AddEmailNoteModal.styled';
import { AddEmailNoteModalType } from './constants';
import FileUploader from 'components/FileUploader/FileUploader';
import { useGetAttachmentsPreview } from './hooks';
import Loader from 'components/Loader/Loader';
import { ERPError } from 'services/api/errors';
import { useDispatch } from 'react-redux';
import { setMarkAsConnectedInvoiceId } from 'store/Common/actions/common';

export interface IEmailDetails {
  email?: string;
  type?: string;
}

interface IAddEmailNoteModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
  type: AddEmailNoteModalType;
  isPaymentReminder?: boolean;
  appointmentEmailType?: AppointmentEmailType;
  newAppointment?: ICreateAppointmentDTO;
  isConfirmedAppointment?: boolean;
  selectedOptionGeneral?: any;
  note?: string;
  onCreateAppointmentSuccess?: () => void;
  onConfirmAppointmentSuccess?: () => void;
  entityType?: AddEmailNoteModalType;
  emailDetails?: IEmailDetails;
}

export const AddEmailNoteModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  id,
  type,
  isPaymentReminder,
  appointmentEmailType,
  newAppointment,
  isConfirmedAppointment,
  selectedOptionGeneral,
  note = '',
  onCreateAppointmentSuccess,
  onConfirmAppointmentSuccess,
  entityType,
  emailDetails,
}: IAddEmailNoteModalProps) => {
  const { t } = useTranslation();

  const { mutate: sendQuotationEmail } = useSendQuotationEmail(id);
  const { mutate: sendSalesOrderEmail } = useSendSalesOrderEmail(id);
  const { mutate: sendSalesInvoiceEmail } = useSendSalesInvoiceEmail(id);
  const { mutate: sendAppointmentEmail } = useSendAppointmentEmail(id);
  const { mutate: createAppointment, isSuccess: createAppointmentIsSuccess } =
    useCreateAppointment();
  const { mutate: confirmAppointment, isSuccess: confirmAppointmentIsSuccess } =
    useConfirmAppointment(id!);

  const { attachmentsData, isLoadingAttachments } = useGetAttachmentsPreview({
    id,
    type,
    entityType,
  });

  useEffect(() => {
    if (createAppointmentIsSuccess) {
      onCreateAppointmentSuccess && onCreateAppointmentSuccess();
    }
  }, [createAppointmentIsSuccess]);

  useEffect(() => {
    if (confirmAppointmentIsSuccess) {
      onConfirmAppointmentSuccess && onConfirmAppointmentSuccess();
    }
  }, [confirmAppointmentIsSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level="SECOND"
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{`${t('Would you like to add a note to email')}?`}</Title>
      </TitleContainer>
      {isLoadingAttachments ? (
        <Loader positionType="relative" />
      ) : (
        <div>
          <Formik
            initialValues={{
              note: note,
              files: attachmentsData?.attachments
                ? attachmentsData.attachments
                : [],
            }}
            onSubmit={() => {
              //
            }}
          >
            {({ handleBlur, setFieldValue, values }) => {
              return (
                <FormikContainer>
                  {emailDetails && (
                    <EmailRow>
                      <Label>{t('SENDING TO')}</Label>
                      <EmailCell>
                        <BlueValue>{emailDetails.email}</BlueValue>
                        <Value>{t(emailDetails.type)}</Value>
                      </EmailCell>
                    </EmailRow>
                  )}
                  <Row>
                    <Input
                      pwId="add-note-field"
                      isTextArea
                      height={'140rem'}
                      name="note"
                      placeholder={t('Add an optional note')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('note', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '600rem',
                      }}
                    />
                  </Row>
                  <FileUploader
                    name="files"
                    values={values}
                    setFieldValue={setFieldValue}
                    localDelete
                  />
                  <AlignRow>
                    <SubmitRow>
                      <Margin>
                        <Button
                          width={'200rem'}
                          onClick={onCancel}
                          label={t('Cancel')}
                          secondary
                        />
                      </Margin>
                      <Button
                        width={'200rem'}
                        onClick={() => {
                          switch (type) {
                            case AddEmailNoteModalType.QUOTATION:
                              sendQuotationEmail({
                                note: values.note,
                                files: values.files,
                              });
                              break;
                            case AddEmailNoteModalType.SALES_ORDER:
                              sendSalesOrderEmail({
                                note: values.note,
                                files: values.files,
                              });
                              break;
                            case AddEmailNoteModalType.SALES_INVOICE:
                              sendSalesInvoiceEmail({
                                isPaymentReminder: isPaymentReminder!,
                                note: values.note,
                                files: values.files,
                              });
                              break;
                            case AddEmailNoteModalType.APPOINTMENT:
                              sendAppointmentEmail({
                                appointmentId: id,
                                appointmentEmailType: appointmentEmailType!,
                                note: values.note,
                                files: values.files,
                              });
                              break;
                            case AddEmailNoteModalType.MEASUREMENT_CREATION:
                              {
                                const createAppointmentData: ICreateAppointmentDTO =
                                  {
                                    ...(newAppointment?.quotation_id && {
                                      quotation_id:
                                        newAppointment?.quotation_id,
                                    }),
                                    ...(newAppointment?.sales_order_id && {
                                      sales_order_id:
                                        newAppointment?.sales_order_id,
                                    }),
                                    ...(newAppointment?.arrival_from && {
                                      arrival_from: `${newAppointment?.startDate} ${newAppointment?.arrival_from}`,
                                    }),
                                    ...(newAppointment?.arrival_to && {
                                      arrival_to: `${newAppointment?.startDate} ${newAppointment?.arrival_to}`,
                                    }),
                                    date_from: `${newAppointment?.startDate} ${newAppointment?.time_from}`,
                                    date_to: `${newAppointment?.endDate} ${newAppointment?.time_to}`,
                                    appointment_type_id:
                                      newAppointment!.appointment_type_id,
                                    users_ids: newAppointment?.users_ids,
                                    resources_ids:
                                      newAppointment?.resources_ids,
                                    workers_ids: newAppointment?.workers_ids,
                                    confirmed: isConfirmedAppointment,
                                  };
                                createAppointment({
                                  createAppointmentData,
                                  shouldSendEmail: true,
                                  note: values.note,
                                  files: values.files,
                                });
                              }
                              break;
                            case AddEmailNoteModalType.INSTALLATION_CREATION:
                              {
                                const createAppointmentData: ICreateAppointmentDTO =
                                  {
                                    ...(newAppointment?.arrival_from && {
                                      arrival_from: `${newAppointment?.startDate} ${newAppointment?.arrival_from}`,
                                    }),
                                    ...(newAppointment?.arrival_to && {
                                      arrival_to: `${newAppointment?.startDate} ${newAppointment?.arrival_to}`,
                                    }),
                                    sales_order_id:
                                      newAppointment?.sales_order_id,
                                    date_from: `${newAppointment?.startDate} ${newAppointment?.time_from}`,
                                    date_to: `${newAppointment?.endDate} ${newAppointment?.time_to}`,
                                    appointment_type_id:
                                      newAppointment!.appointment_type_id,
                                    users_ids: newAppointment?.users_ids,
                                    resources_ids:
                                      newAppointment?.resources_ids,
                                    workers_ids: newAppointment?.workers_ids,
                                    confirmed: isConfirmedAppointment,
                                    sales_order_lines_ids:
                                      newAppointment!.sales_order_lines_ids,
                                  };
                                createAppointment({
                                  createAppointmentData,
                                  shouldSendEmail: true,
                                  note: values.note,
                                  files: values.files,
                                });
                              }
                              break;
                            case AddEmailNoteModalType.GENERAL_CREATION:
                              {
                                const createAppointmentData: ICreateAppointmentDTO =
                                  {
                                    ...(newAppointment?.arrival_from && {
                                      arrival_from: `${newAppointment?.startDate} ${newAppointment?.arrival_from}`,
                                    }),
                                    ...(newAppointment?.arrival_to && {
                                      arrival_to: `${newAppointment?.startDate} ${newAppointment?.arrival_to}`,
                                    }),
                                    purpose: newAppointment?.purpose,
                                    ...(newAppointment?.customer_id &&
                                      selectedOptionGeneral ===
                                        GeneralTabOptions.CUSTOMER && {
                                        customer_id:
                                          newAppointment?.customer_id,
                                      }),
                                    ...(newAppointment?.quotation_id &&
                                      selectedOptionGeneral ===
                                        GeneralTabOptions.QUOTATION && {
                                        quotation_id:
                                          newAppointment?.quotation_id,
                                      }),
                                    ...(newAppointment?.sales_order_id &&
                                      selectedOptionGeneral ===
                                        GeneralTabOptions.SALES_ORDER && {
                                        sales_order_id:
                                          newAppointment?.sales_order_id,
                                      }),
                                    date_from: `${newAppointment?.startDate} ${newAppointment?.time_from}`,
                                    date_to: `${newAppointment?.endDate} ${newAppointment?.time_to}`,
                                    appointment_type_id:
                                      newAppointment!.appointment_type_id,
                                    description: newAppointment?.description,
                                    users_ids: newAppointment?.users_ids,
                                    resources_ids:
                                      newAppointment?.resources_ids,
                                    workers_ids: newAppointment?.workers_ids,
                                    confirmed: isConfirmedAppointment,
                                  };
                                createAppointment({
                                  createAppointmentData,
                                  shouldSendEmail: true,
                                  note: values.note,
                                  files: values.files,
                                });
                              }
                              break;
                            case AddEmailNoteModalType.SERVICE_CREATION:
                              {
                                const createAppointmentData: ICreateAppointmentDTO =
                                  {
                                    ...(newAppointment?.arrival_from && {
                                      arrival_from: `${newAppointment?.startDate} ${newAppointment?.arrival_from}`,
                                    }),
                                    ...(newAppointment?.arrival_to && {
                                      arrival_to: `${newAppointment?.startDate} ${newAppointment?.arrival_to}`,
                                    }),
                                    ...(newAppointment?.sales_order_id && {
                                      sales_order_id:
                                        newAppointment?.sales_order_id,
                                    }),
                                    date_from: `${newAppointment?.startDate} ${newAppointment?.time_from}`,
                                    date_to: `${newAppointment?.endDate} ${newAppointment?.time_to}`,
                                    appointment_type_id:
                                      newAppointment!.appointment_type_id,
                                    description: newAppointment?.description,
                                    users_ids: newAppointment?.users_ids,
                                    resources_ids:
                                      newAppointment?.resources_ids,
                                    workers_ids: newAppointment?.workers_ids,
                                    confirmed: isConfirmedAppointment,
                                  };
                                createAppointment({
                                  createAppointmentData,
                                  shouldSendEmail: true,
                                  note: values.note,
                                  files: values.files,
                                });
                              }
                              break;
                            case AddEmailNoteModalType.APPOINTMENT_CONFIRM:
                              confirmAppointment({
                                appointmentId: id,
                                send_confirm_email: true,
                                note: values.note,
                                files: values.files,
                              });
                              break;
                            default:
                              break;
                          }

                          setIsOpen(false);
                        }}
                        label={t('Send e-mail')}
                        primary
                      />
                    </SubmitRow>
                  </AlignRow>
                </FormikContainer>
              );
            }}
          </Formik>
        </div>
      )}
    </Modal>
  );
};
