import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetSingleFwAppointment } from '../hooks';
import {
  Container,
  RightAlign,
} from './FwSingleAppointmentProductsPage.styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FwSingleAppointmentRoutes } from 'navigation/FwSingleAppointment/FwSingleAppointment.routes';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { useDeleteFwAppointmentLine } from './hooks';
import { canAddOnlyStockItems } from './helpers';
import { IFwAppointmentLine } from 'types/Appointment.types';
import Button from 'components/Button/Button';
import DraggableLines, {
  DraggableLineType,
  IDraggableLine,
} from 'components/DragAndDrop/DraggableLines/DraggableLines';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const FwSingleAppointmentProductsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isUnableToEditModalOpen, setIsUnableToEditModalOpen] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [lineForDeletion, setLineForDeletion] =
    useState<IFwAppointmentLine | null>(null);

  const { data: appointmentData } = useGetSingleFwAppointment(id!);
  const areStagedLinesEditable =
    appointmentData?.are_lines_staged_lines &&
    appointmentData?.are_staged_lines_editable;
  const hasReport = appointmentData?.appointment?.has_report;
  const {
    mutate: deleteAppointmentLine,
    isLoading: deleteSalesOrderLineIsLoading,
  } = useDeleteFwAppointmentLine(id!);

  const handleDeleteClick = (line: IFwAppointmentLine) => {
    setLineForDeletion(line);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = (line: IFwAppointmentLine) => {
    navigate(
      location.pathname.replace(
        FwSingleAppointmentRoutes.FwSingleAppointmentProducts.path,
        FwSingleAppointmentRoutes.FwSingleAppointmentLine.path.replace(
          ':id',
          id!
        )
      ),
      {
        state: {
          line: line,
        },
      }
    );
  };

  const canEditFwAppointment = useCan(Actions.EDIT_FIELD_WORKER);

  const canManipulateLines = !hasReport && areStagedLinesEditable;

  return (
    <Container>
      <RightAlign>
        {canManipulateLines ? (
          <Button
            label={t('Add new product')}
            icon={Plus}
            sizeIcon={15}
            colorIcon={COLORS.PRIMARY}
            weightIcon="bold"
            link
            onClick={() => {
              navigate(
                location.pathname.replace(
                  FwSingleAppointmentRoutes.FwSingleAppointmentProducts.path,
                  FwSingleAppointmentRoutes.FwSingleAppointmentLine.path.replace(
                    ':id',
                    id!
                  )
                ),
                {
                  state: {
                    canAddOnlyStockItems: canAddOnlyStockItems(
                      appointmentData?.appointment
                    ),
                    salesOrderId: appointmentData?.appointment?.sales_order_id,
                  },
                }
              );
            }}
            fontSize="18rem"
          />
        ) : null}
      </RightAlign>
      <DraggableLines
        isDragDisabled={
          true // erp-fe 1307
          // !appointmentData?.are_staged_lines_editable || !canEditFwAppointment
        }
        lines={appointmentData?.appointment_lines}
        lineType={DraggableLineType.APPOINTMENT}
        onDeleteClick={
          canManipulateLines
            ? (appointmentLine: IDraggableLine) =>
                handleDeleteClick(appointmentLine as IFwAppointmentLine)
            : undefined
        }
        onEditClick={
          canManipulateLines
            ? (appointmentLine: IDraggableLine) =>
                handleEditClick(appointmentLine as IFwAppointmentLine)
            : undefined
        }
        appointment={appointmentData?.appointment}
        withCssTransition={!appointmentData?.are_lines_staged_lines} // This prevents transition animation that occurrs after swapping appointment lines with newly fetched staged lines
      />

      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (lineForDeletion) {
                deleteAppointmentLine(lineForDeletion.id.toString());
                setLineForDeletion(null);
                setIsDeleteModalOpen(false);
              }
            },
            text: t('Delete'),
            disabled: deleteSalesOrderLineIsLoading,
          }}
          cancel={{
            onClick: () => setIsDeleteModalOpen(false),
            text: t('Back'),
          }}
          title={t('Delete line')}
          description={t(
            'Are you sure you want to delete this line? This will also delete the line from the sales order.'
          )}
        />
      </Modal>
      <OkModal
        level="FIRST"
        onClick={() => setIsUnableToEditModalOpen(false)}
        description={t('You cannot edit a closed quotation')}
        buttonLabel="OK"
        setIsOpen={setIsUnableToEditModalOpen}
        isOpen={isUnableToEditModalOpen}
      />
    </Container>
  );
};

export default FwSingleAppointmentProductsPage;
